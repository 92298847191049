import React, { useState, useEffect } from 'react';
import CreateCoach from './create'
import EditCoath from './edit'
import { Link, useParams, useNavigate, NavLink } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Modal from 'react-bootstrap/Modal';
import { ApiGetAuth } from '../../../helpers/apiCall';
import optionico from '../../../assets/images/options.png';
import edit from '../../../assets/images/edit.png';
import deletes from '../../../assets/images/delete.png';
import DeletePopup from '../../../components/DeletePopup';
import { generateImage } from "../../../helpers/helperfunctions";
import Membersloader from '../../Mainloader.js/Membersloader';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';
import PlayModal from '../../../components/PlayVideoModal/playVideoModal';

const Referee = () => {
	const [isFilter, setisFilter] = useState(false);
	const showFilter = () => {
		setisFilter(isFilter === false ? true : false);
	};
	const navigate = useNavigate();
	const { sportId } = useParams();
	const [refreeList, setRefreeList] = useState(null);
	const [loading, setLoading] = useState(false);
	const [showModal, setShowModal] = useState(false);
	const [filter, setFilter] = useState('All');
	const [user_id, setUserid] = useState('')
	const [delTitle, setDelTitle] = useState('')
	const [refresh, setRefresh] = useState(false)
	const [value, setValue] = useState([])
	const [search, setSearch] = useState('')
	const closeModal = () => {
		setShowModal(false);
	};
	const [contentToRender, setContentToRender] = useState(<></>)
	// table
	const [showDeleteModal, setShowDeleteModal] = useState(false);
	function CustomModal({ showModal, onClose, componentToRender }) {
		return (
			<Modal show={showModal} onHide={onClose} className="create_tournament_modal" >
				<Modal.Body >
					<button type="button" className="btn-close" onClick={onClose}></button>
					{componentToRender}
				</Modal.Body>

			</Modal>
		);
	}
	const closeDeleteModal = () => {
		setUserid(0)
		setShowDeleteModal(false);
		setDelTitle('')
	};
	const openDeleteModal = (id, title) => {
		setUserid(id)
		setDelTitle(title)
		setShowDeleteModal(true);
	};
	function calculate_age(dob) {
		// Parse the date of birth string into a Date object
		var dobDate = new Date(dob);

		// Check if the parsed date is valid
		if (isNaN(dobDate.getTime())) {
			// Invalid date format
			return "Invalid date of birth";
		}

		// Calculate the difference in milliseconds
		var diff_ms = Date.now() - dobDate.getTime();

		// Create a Date object from the time difference
		var age_dt = new Date(diff_ms);

		// Get the year and subtract 1970 to get the age
		return Math.abs(age_dt.getUTCFullYear() - 1970);
	}

	useEffect(() => {
		getVideoTutorial()
		setLoading(true);
		// setRefreeList([]);
		ApiGetAuth(`users/user-list/all-users`).then((data) => {
			let temp = [];
			data?.data?.filter((fil) => {
				if (fil.sport_id != null && fil.sport_id.search(sportId.toString()) >= 0) {
					return fil
				}
			}).map(item => {
				temp.push(item);
			});
			setRefreeList(temp);
			setLoading(false);
			return;
		}, (err) => {
			console.log(err);
			setLoading(false);
			return;
		})
	}, [sportId, refresh, filter]);
	const openModal = (value) => {
		if (!value) {
			setContentToRender(<CreateCoach closeModal={closeModal} setRefresh={setRefresh} />)
			setShowModal(true);
		}
		else {
			setContentToRender(<EditCoath closeModal={closeModal} value={value.value} refreshList={setRefresh} />)
		}
		setShowModal(true);
	}
	let filteredReferees = () => {
		let result = (filter !== 'All') ? refreeList?.filter(item => item.roleName === filter) : refreeList
		if (search) {
			return result?.filter(item => item.firstname.toLowerCase().startsWith(search.toLowerCase()))
		}
		return result
	};


	const getVideoTutorial = () => {
		ApiGetAuth(`dashboard/get-tutorial-videos`).then(
			(data) => {
				if (data?.data) {
					let temp = data.data.filter(item => item.type == 'members')
					setValue(temp);
				}
			}
		);
	}


	return (
		<>

			<nav aria-label="breadcrumb" className="breadcrumb_dashboard">
				<ol className="breadcrumb">
					<li className="breadcrumb-item">
						<NavLink to="#" role="button" tabIndex="0">{sportId == 1 ? "Basketball" : "Football"}</NavLink>
					</li>
					<li className="breadcrumb-item active" aria-current="page">
						<NavLink to="/organizer/dashboard">Members</NavLink>
					</li>
					<PlayModal url={value.length ? value[0].video_link : ''} data={value} />
				</ol>
			</nav>
			<div className="organizer_events">
				<DeletePopup isOpen={showDeleteModal} onClose={closeDeleteModal} values={refreeList} title={delTitle} setValues={setRefreeList} endpoint={`/users/referee/delete-user/${user_id}`} id={user_id} />
				<CustomModal showModal={showModal} onClose={closeModal} componentToRender={contentToRender} />
				<div className="d-flex justify-content-between align-items-center my-3 members_new_css" >
					<div className='search-teams  venue-top '>
						<div className="serach-properties d-flex">
							<div className='heading-view '>
								<div className="team-top-fil">

									<div className='serach-properties'>
										<div className="team-search ">
											<svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
												<path d="M9.19668 0.839844C9.83401 0.92695 10.4665 1.03732 11.0735 1.25833C13.1246 2.00522 14.6622 3.33914 15.6601 5.28522C16.1773 6.29398 16.4576 7.36441 16.5082 8.49868C16.5969 10.4937 16.0277 12.2775 14.8172 13.8611C14.6427 14.0894 14.6435 14.0878 14.8383 14.2901C16.2993 15.8072 17.7596 17.3248 19.2204 18.8418C19.4587 19.0891 19.6664 19.3485 19.671 19.7186C19.6764 20.1641 19.4914 20.502 19.0989 20.7057C18.7002 20.9126 18.3006 20.8823 17.9479 20.5996C17.7463 20.4381 17.5724 20.2407 17.3925 20.0535C16.0053 18.61 14.6178 17.1668 13.2366 15.7177C13.1045 15.5791 13.0115 15.5575 12.8476 15.6684C11.8623 16.3347 10.7716 16.7418 9.59785 16.9076C8.00208 17.1327 6.46204 16.9252 5.00693 16.2132C2.5915 15.0316 1.07391 13.1221 0.518813 10.4954C0.0981614 8.50517 0.422239 6.60399 1.45723 4.84455C2.5658 2.96014 4.18836 1.72605 6.28269 1.10901C6.73796 0.974831 7.20785 0.912612 7.67557 0.839844H9.19695H9.19668ZM8.42084 14.8336C11.6586 14.8425 14.347 12.2502 14.3684 8.94449C14.3895 5.68775 11.8212 2.98124 8.44627 2.97313C5.15707 2.96528 2.5209 5.56954 2.509 8.89065C2.49709 12.2345 5.19792 14.8244 8.42111 14.8336H8.42084Z" fill="#F05829" />
											</svg>
											<input type="text" className="form-control " id="exampleFormControlInput1" onChange={(e) => { setSearch(e.target.value) }} placeholder="Search" />
											<div className="s-icon"></div>
										</div>

										<div className='back-btn-dashboard'>
											<Link onClick={() => navigate(-1)}> <span className='back'>
												<svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
													<rect width="32" height="32" rx="6" fill="#F05829" />
													<path d="M10.947 17.2243C10.9863 17.3879 11.0873 17.4465 11.1619 17.5219C12.2375 18.6007 13.3139 19.6787 14.3936 20.7535C14.6911 21.0495 14.882 21.3831 14.8572 21.8179C14.8299 22.2903 14.6077 22.6384 14.193 22.8526C13.7776 23.0667 13.3597 23.0475 12.9642 22.7956C12.8391 22.7154 12.7268 22.6111 12.6209 22.5052C11.0039 20.8906 9.38692 19.2752 7.77231 17.6582C6.74484 16.6283 6.74243 15.3699 7.7659 14.344C9.39414 12.7125 11.024 11.0819 12.6562 9.45447C13.257 8.85611 14.0607 8.84969 14.5628 9.4288C15.0023 9.93572 14.9582 10.6672 14.4401 11.1894C13.3532 12.285 12.2592 13.3743 11.1684 14.4667C11.0914 14.5437 11.0184 14.6239 10.9317 14.7162C11.0585 14.806 11.1812 14.7715 11.2935 14.7715C15.4226 14.7739 19.5526 14.7739 23.6817 14.7723C24.1694 14.7723 24.5311 14.9865 24.7638 15.4052C24.9972 15.8263 24.9803 16.2554 24.7245 16.6604C24.467 17.0671 24.0852 17.2291 23.6023 17.2283C19.5133 17.2219 15.4234 17.2251 11.3344 17.2251H10.9462L10.947 17.2243Z" fill="white" />
												</svg>
												<span className='btn-back-to-view'>  Back</span>
											</span></Link>

										</div>

									</div>
								</div>
							</div>
							<div className='wrap-create-button'>
								<button className="btn btn-dark green orange-color" onClick={() => openModal('')}> Create Members</button>
							</div>
						</div>
					</div>

					<div className='mem-filter create-cards'>
						<Tabs
							defaultActiveKey="All"
							id="uncontrolled-tab-example"
							className="mb-3 "
							onSelect={(key) => setFilter(key)}
						>
							<Tab eventKey="All" title={`All (${refreeList?.length || 0})`} className='coach' > </Tab>
							<Tab eventKey="Coach" title={`Coach (${refreeList?.filter(item => item.roleName === 'Coach')?.length || 0})`} className='coach' ></Tab>
							<Tab eventKey="Referee" title={`Referee (${refreeList?.filter(item => item.roleName === 'Referee')?.length || 0})`}  ></Tab>
							<Tab eventKey="Team Mom" title={`Team Mom (${refreeList?.filter(item => item.roleName === "Team Mom")?.length || 0})`} ></Tab>
						</Tabs>
						<div className="team-search ">
							<div className="s-icon">
							</div> </div>
					</div>

				</div>
				<div className='row pb-5 mb-3 '>
					{
						refreeList === null ? <Membersloader content={"Members"} /> :
							<>
								{refreeList?.length ?
									<>
										{filteredReferees()?.map(item => {
											return <div className='col-xxl-3 col-xl-4 col-lg-5 col-md-6 col-sm-12'> <div className='l-card mem-l-card'>
												<div className='wrap mem-card'>
													<div className='l-title'>
														<Link to={`/organizer/${sportId}/referee`} state={{ id: item.id, values: item, sportId: sportId }} >
															<div className='card-content'>
																<h6>{item.title}</h6>
															</div>
														</Link>

														<div className="dropdown acts">
															<button className=" dropdown-toggle actions" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
																<img className='dots' src={optionico} />
															</button>
															<ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
																<li onClick={() => { openModal({ value: item }) }}><a className="dropdown-item"><img src={edit} />Edit</a></li>
																<li onClick={() => openDeleteModal(item.id, item.firstname)}><a className="dropdown-item"><img src={deletes} />Archive</a></li>
															</ul>
														</div>

													</div>

													<div className='  date-card p-0  members '>
														<p>
															<img alt="" src={item.profile_pic ? item?.profile_pic : generateImage(item.firstname)} width='100px' />
															<div className="type-mem">{item.roleName}</div>
														</p>


														<div className="member_card_section member-cards-view-sections">
															<p className="c-name">{item.firstname} </p>
															<p className="dob"><strong>Age :</strong> {item.dob?  calculate_age(moment(item.dob).format('YYYY-MM-DD')) : "N/A"}</p>

															<div className="c-details-fix">

																<div className='email'>
																	<span className=''></span>
																	<Link to={`mailto:${item.email}`}> {item.email}</Link>

																</div>

																<span className='mem-no w'>

																	<PhoneInput country={'us'} name="contact_number" value={item.contact_number} disabled />
																</span>
															</div>
														</div>
													</div>
												</div>
											</div>
											</div>
										})}

									</> : <>

										<div className='col-xxl-3 col-xl-4 col-lg-4 col-md-6 col=sm-12'>
											<div className='l-card create-league'>
												<div className='wrap'>
													<span> No Data Found</span>
												</div>
											</div>


										</div></>
								}
							</>
					}


				</div>
			</div>

		</>

	);
};

export default Referee;
import React, { useEffect, useState } from 'react';
import './MyForm.css'; // Import your CSS file
import { useLocation, useParams } from 'react-router';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { ApiGetAuth, Apipost } from '../../../helpers/apiCall';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify/index'
import Cardloader from '../../Mainloader.js/Cardloader';
import deletes from '../../../assets/images/delete.png'
import CustomModal from '../../../components/customModal';
import DeletePopup from '../../../components/DeletePopup';
import Backbutton from '../../../components/Backbutton';
import { Nav } from 'react-bootstrap';
import '../../../assets/css/payment.css';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import PlayModal from '../../../components/PlayVideoModal/playVideoModal';
import { Link, NavLink } from 'react-router-dom';
import NoData from '../../../components/NoData';
import AlertDismissible from '../../../components/AlertDismissible';
import { useSelector } from 'react-redux';
import { ImportTimeslots } from '../../../components/Calanderdragndrop';


export const daysOptions = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

const MyForm = ({showPopUP=false,venue_id='',day='', field_id=''}) => {
  const [books, setBooks] = useState(null);
  const location = useLocation()
  const query = new URLSearchParams(location.search);
  const [time_id, setTimeid] = useState([])
  const [loading1, setLoading1] = useState(false);
  const { id } = location?.state || { id: 0 }
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [venueList, setVenueList] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(null)
  const { sportId } = useParams()
  const [refresh, setRefresh] = useState(false)
  const [value, setValue] = useState([])
  const  [alertData, setAlertData] = useState({})
  useEffect(() => {
    ApiGetAuth(`venue/venue-field-list`).then(
      (data) => {
        let temp = [];
        data?.data?.filter((fil) => fil.sport_id == sportId).map(item => {
          temp.push(item)
        })
        setSelectedVenue(temp[0])
        if (id || query.get('v')) {
          setSelectedVenue(temp.filter(item => item.id ==( id || query.get('v')))[0])
        }
        setVenueList(temp);
      },
      (err) => {
        console.log(err);
      }
    )
  }, [sportId, refresh])
  const closeModal = () => {
    setShowModal(false);
  };
  const getVideoTutorial = () => {
    ApiGetAuth(`dashboard/get-tutorial-videos`).then(
      (data) => {
        if (data?.data) {
          let temp = data.data.filter(item => item.type == 'timeslot')
          setValue(temp);
        }
      }
    );
  }


  const CreateTimeSlots = ({ value = {}, type = 'Add' }) => {
    const validationSchema = Yup.object().shape({
      Day: Yup.string().required("Please Add  Date for slots "),
      venue_id: Yup.number().required("Please Select Venue"),
      venue_field_id: Yup.number().required("Please Select Venue Field"),
      duration: Yup.number().max(999, "More Than Maximum Timeslots at Once").min(0, "Negative Numbers Invalid Input").required("Please Enter Duration"),
      Time: Yup.string().required("Time is Required"),
      quantity: Yup.number().max(30, "More Than Maximum Timeslots at Once").min(0, "Negative Numbers Invalid Input").required("Quantity is Required")
    });
    const formik = useFormik({
      initialValues: {
        Day: day,
        venue_id: venue_id,
        venue_field_id:field_id,
        duration: '',
        Time: '',
        quantity: '',
        Gameid: sportId
      },
      validationSchema: validationSchema,
      onSubmit: (values) => {
        handleSubmit(values);
      },
    });

    const handleSubmit = (values) => {
      Apipost(
        type === 'Add' ? '/venue/add-time-slot' : '/venue/update-time-slot',
        values,
        'application/x-www-form-urlencoded',
        true
      ).then(
        (response) => {
          successMessageNodify(response?.data.message);
          setAlertData({message:`${formik.values.quantity} Slots has Been Added ` ,venue_id:formik.values.venue_id})
          setLoading1(true);
          closeModal();
          setRefresh((prev) => !prev);
        },
        (err) => {
          errorMessageNodify(err.response?.data.message);
          setLoading1(false);
        }
      );
    };
    return (
      <>
        <form onSubmit={formik.handleSubmit}>
          <div className="create-match-wrap season-popup">
            <div className="custom-pop-box  add-slot">
              <h4 className="text-left">{type} Time Slots </h4>
              <div className="row">
                <div className="col-sm-12">
                  <div className="create-form-inner add_time_slot_modal form_submit_basketball">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="input-right-icon mb-4">
                          <div className="d-flex">
                            <label className="form-label">Day</label>
                            <span className="imp_ast">*</span>
                          </div>
                          {/* <select
                            className="form-control"
                            name="Day"
                            value={formik.values.Day}
                            onChange={formik.handleChange}
                            disabled={day!==''}
                          >
                            <option label="Select a Day"  value=''/>
                            {daysOptions.map((day, index) => (
                              <option key={index} value={index}>
                                {day}
                              </option>
                            ))}
                          </select> */}
                          <input
                              maxLength={10}
                              type="date"
                              className="form-control"
                              name="Day"
                              value={formik.values.Day}
                              onChange={formik.handleChange}
                            />
                          {formik.touched.Day && formik.errors.Day && (
                            <div className="text-danger">{formik.errors.Day}</div>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group mb-4">
                          <div className="d-flex">
                            <label className="form-label">Venue</label>
                            <span className="imp_ast">*</span>
                          </div>
                          <div className="input-right-icon">
                            <select
                              className="form-control"
                              name="venue_id"
                              value={formik.values.venue_id}
                              onChange={formik.handleChange}
                              disabled={venue_id!==''}
                            >
                              <option value="">Select</option>
                              {venueList?.length ? (
                                venueList?.map((grade, i) => (
                                  <option key={i} value={grade.id}>
                                    {grade.name}
                                  </option>
                                ))
                              ) : (
                                <></>
                              )}
                            </select>
                            {formik.touched.venue_id && formik.errors.venue_id && (
                              <div className="text-danger">{formik.errors.venue_id}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-4">
                          <div className="d-flex">
                            <label className="form-label">Venue Field </label>
                            <span className="imp_ast">*</span>
                          </div>
                          <div className="input-right-icon">
                            <select
                              className="form-control"
                              name="venue_field_id"
                              value={formik.values.venue_field_id}
                              onChange={formik.handleChange}
                            >
                              <option value="">Select</option>
                              <option value={0}>All Fields</option>
                              {venueList?.length ? (
                                venueList
                                  .filter((e) => e.id == formik.values.venue_id)[0]?.fields.map(
                                    (grade, i) => (
                                      <option key={i} value={grade.id}>
                                        {grade.fieldname}
                                      </option>
                                    )
                                  )
                              ) : (
                                <></>
                              )}
                            </select>
                            {formik.touched.venue_field_id && formik.errors.venue_field_id && (
                              <div className="text-danger">{formik.errors.venue_field_id}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex">
                            <label className="form-label">Interval (Minutes)</label>
                            <span className="imp_ast">*</span>
                          </div>

                          <div className="input-right-icon">
                            <input
                              maxLength={10}
                              type="number"
                              className="form-control"
                              name="duration"
                              value={formik.values.duration}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.duration && formik.errors.duration && (
                              <div className="text-danger">{formik.errors.duration}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group">
                          <div className="d-flex">
                            <label className="form-label">Start Time</label>
                            <span className="imp_ast">*</span>
                          </div>
                          <div className="input-right-icon">
                            <input
                              type="time"
                              className="form-control"
                              name="Time"
                              placeholder="Baseball02"
                              value={formik.values.Time}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.Time && formik.errors.Time && (
                              <div className="text-danger">{formik.errors.Time}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group">
                          <div className="d-flex">
                            <label className="form-label">Quantity</label>
                            <span className="imp_ast">*</span>
                          </div>

                          <div className="input-right-icon">
                            <input
                              maxLength={10}
                              type="number"
                              className="form-control"
                              name="quantity"
                              value={formik.values.quantity}
                              onChange={formik.handleChange}
                            />
                            {formik.touched.quantity && formik.errors.quantity && (
                              <div className="text-danger">{formik.errors.quantity}</div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <button
                      className="btn btn-dark green add next-orange-btn add-slot-btns add_more_plan button_time_slot"
                      type="submit"
                    >
                      {formik.isSubmitting ? (
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                      ) : (
                        'Add'
                      )}
                    </button>
                    {
                      venue_id&& day >=0 &&  <AlertDismissible 
                        sportId={sportId} 
                        id={alertData.venue_id}
                        message={alertData.message} 
                        duration={15} 
                        visible={loading1} 
                      />
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </>
    );
  };

  const [contentToRender, setContentToRender] = useState(<CreateTimeSlots closeModal={closeModal} setRefresh={setRefresh} />)
  const [checked, setChecked] = useState(false)

  useEffect(() => {
    getVideoTutorial()
    ApiGetAuth(`venue/time-slots/${sportId}`).then((data) => {
      if (data?.data) {
        setBooks(data.data)
      }
    }, (err) => {
      console.log(err);
    })
  }, [refresh, sportId])

  const closeDeleteModal = () => {
    setTimeid([])
    setShowDeleteModal(false);
  };
  const openDeleteModal = (id) => {
    setTimeid([id])
    setShowDeleteModal(true);
  };

  const handleUpdate = (item) => {
    Apipost('/venue/update-time-slot', item, "application/x-www-form-urlencoded", true).then(
      (response) => {
        successMessageNodify(response?.data.message)
        // setRefresh((prev) => !prev)
        closeModal()
      },
      (err) => {
        errorMessageNodify(err.response?.data.message);
        return;
      }
    );

  }



  const updateState = (data, id, value, key,status_id) => {
    if(status_id == 5)return
    const newState = data.map(obj => {
      // 👇️ if id equals 2, update country property
      if (obj.id === id && obj.status_id !== 5) {
        if (key === 'isSelected') {
          if (obj.hasOwnProperty("isSelected")) {
            return { ...obj, [key]: !obj.isSelected }
          }
          return { ...obj, [key]: value };
        }
        return { ...obj, [key]: value };
      }
      // 👇️ otherwise return the object as is
      return obj;
    });
    setBooks(newState);
  };


  const openModal = (value) => {
    if (!value) {
      setContentToRender(<CreateTimeSlots />)
      setShowModal(true);
    }
    else {
      setContentToRender(<CreateTimeSlots value={value.value} type='Update' />)
    }
    setShowModal(true);
  }

  const handleClick = () => {
    let currentTimeSlots = books?.filter(item => item?.venue_id == selectedVenue?.id && item.status_id != 5).map(item => item.id);
    // Update the state without modifying other values
    setBooks(prevBooks => prevBooks.map(item => {
      if (currentTimeSlots.includes(item.id)) {
        return {
          ...item,
          isSelected: !checked
        };
      }
      return item; // Return unchanged item for other values
    }));

    setChecked(!checked);
  };

  const handleDelete = () => {
    setShowDeleteModal(true);
    const deleteId = books.filter(item => item.isSelected).map(item => item.id)
    setTimeid(deleteId)
  }
  const handleSelectedVenue = (item) => {
    setSelectedVenue(item);
    setChecked(false);
    setBooks(prevBooks => prevBooks.map(item => {
      return {
        ...item,
        isSelected: false
      };
    }));
  }

  const sports = useSelector(state=>state.SPORTSLIST)


  return (
   <>{showPopUP?<><CreateTimeSlots/></>: <>
    <nav aria-label="breadcrumb" className="breadcrumb_dashboard">
      <ol className="breadcrumb">
      <li className="breadcrumb-item">
						<NavLink to='/organizer/dashboard' role="button" tabIndex="0">Dashboard</NavLink>
					</li>
					<li className="breadcrumb-item">
                      <NavLink to={`/organizer/${sportId}/events/All-Games`} role="button" tabIndex="0">{sports?.filter(item=>item.id ==sportId)[0]?.sports_name}</NavLink>
                   </li>
					<li className="breadcrumb-item active" aria-current="page">
						<NavLink to="/organizer/dashboard">Timeslots</NavLink>
					</li>
        <PlayModal url={value.length ? value[0].video_link : ''} data={value} />
      </ol>
    </nav>
    <div className="organizer_events">
      <DeletePopup isOpen={showDeleteModal} onClose={closeDeleteModal} values={books} setValues={setBooks} endpoint={`/venue/delete-time-slot/${time_id}`} id={time_id} title={time_id.length > 1 ? `these ${time_id.length} Selected Timeslots` : "this Time Slot"} />
      <CustomModal showModal={showModal} onClose={closeModal} componentToRender={contentToRender} />
      <div className='d-flex justify-content-between my-3'>
        <h4>Time Slots</h4>

        <div> 
        <ImportTimeslots venueList={venueList} setRefresh={setRefresh} />
        <button className="btn btn-dark green orange-color" onClick={() => openModal('')}> Create Time Slots </button>
          {(books?.filter(item => item.isSelected))?.length > 1 ? <button type="button" className="btn btn-danger mx-2" onClick={handleDelete}>Delete Selected</button> : <></>}
          <button className="btn btn-dark green orange-color me-3 save-all" onClick={() => openModal('')}> Save Time Slots </button>
          <Backbutton /> 
          </div>
      </div>
      <div className='d-flex my-5'>

        <div className='side-navbar venue-side'>
          <p className='dash'><strong > Venues</strong></p>
          <div className='venue-sidenav p-2'>
            <Nav className='group-dashboard' variant="pills" defaultActiveKey="/">
              {venueList.length > 0 ? <>{venueList?.map(item => <div onClick={() => { handleSelectedVenue(item) }}     ><Nav.Item  >
                <Nav.Link style={item?.id == selectedVenue?.id ? { color: "#000", backgroundColor: "rgb(236 236 236", padding: "2px 3px !important" } : {}} className='p-0 mt-0 mb-1' > <div className="venu-menue"> <label className='venue-svg-icon' > <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path style={item?.id == selectedVenue?.id ? { fill: "#000" } : {}} d="M10.5 1.75C6.65 1.75 3.5 4.9 3.5 8.75C3.5 13.475 9.625 18.8125 9.8875 19.075C10.0625 19.1625 10.325 19.25 10.5 19.25C10.675 19.25 10.9375 19.1625 11.1125 19.075C11.375 18.8125 17.5 13.475 17.5 8.75C17.5 4.9 14.35 1.75 10.5 1.75ZM10.5 17.2375C8.6625 15.4875 5.25 11.725 5.25 8.75C5.25 5.8625 7.6125 3.5 10.5 3.5C13.3875 3.5 15.75 5.8625 15.75 8.75C15.75 11.6375 12.3375 15.4875 10.5 17.2375ZM10.5 5.25C8.575 5.25 7 6.825 7 8.75C7 10.675 8.575 12.25 10.5 12.25C12.425 12.25 14 10.675 14 8.75C14 6.825 12.425 5.25 10.5 5.25ZM10.5 10.5C9.5375 10.5 8.75 9.7125 8.75 8.75C8.75 7.7875 9.5375 7 10.5 7C11.4625 7 12.25 7.7875 12.25 8.75C12.25 9.7125 11.4625 10.5 10.5 10.5Z" fill="black" />
                </svg> </label><span>{item.name}</span></div> </Nav.Link>
                <hr />
              </Nav.Item>
              </div>
              )} </> : <><div className='no-games'>
                <NoData message='No Venues Created Yet!' helpText=' Please Create One to Create Time Slots of those venues' />
                <Link to={`/organizer/${sportId}/venues`} >Click here to create Venue </Link>
              </div>

              </>}
            </Nav>
          </div>
        </div>
        <div className='w-100 ms-4 venue-slots'>
          <div></div>
          {
           books?.filter(item => item?.venue_id == selectedVenue?.id)?.length >0  ?<>  <table className="table-match table-striped venue-tables">
            <thead>
              <tr className="team-head">
                <th> <input type='checkbox' onClick={handleClick} checked={checked} />  Field</th>
                <th>Date</th>
                <th>Time</th>
                <th>Duration</th>
                <th>Scheduled Day</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {
                books === null ? <Cardloader content={"Time Slots"} /> :
                  <>
                      {books?.filter(item => item?.venue_id == selectedVenue?.id).map(item => {
                        return <tr  className={item.isSelected == true ? "table-primary " : "" + (item.status_id == 5 ? 'table-danger' : "table-success")}>
                          <td onClick={() => { updateState(books, item.id, true, 'isSelected',item.status_id) }}>
                            {item.isSelected == true ? <input type='checkbox' checked  /> : <input type='checkbox' />}
                            <span className='mx-2'> {item.venue_field}</span>
                          </td>
                          <td> <input
                            className="form-control"
                            type='date'
                            name="scheduled_for"
                            value={item.scheduled_for}
                            disabled={item.status_id == 5}
                            onChange={(e) => updateState(books, item?.id, e.target.value, "scheduled_for",item.status_id)}
                          />
                         </td>
                          <td><input className="form-control" type="time" value={item?.Time}   disabled={item.status_id == 5} onChange={(e) => updateState(books, item?.id, e.target.value, "Time", item.status_id)} /></td>
                          <td className='d-flex align-items-center time-input'><input className="form-control" type="number" value={item?.duration}   disabled={item.status_id == 5} onChange={(e) => updateState(books, item?.id, e.target.value, "duration",item.status_id)} /> Minutes</td>
                          <td>{daysOptions[item.Day]}</td>
                          <td className='del-cio' >
                            <span onClick={() => item.status_id == 5 ? () => { } : openDeleteModal(item?.id)}><img src={deletes} /></span>
                            <span onClick={() => item.status_id == 5 ? () => { } : handleUpdate(item)}>
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <g clip-path="url(#clip0_21_11)">
                                  <path d="M4 7.5L7 10L11 5M7.5 14.5C6.58075 14.5 5.6705 14.3189 4.82122 13.9672C3.97194 13.6154 3.20026 13.0998 2.55025 12.4497C1.90024 11.7997 1.38463 11.0281 1.03284 10.1788C0.68106 9.32951 0.5 8.41925 0.5 7.5C0.5 6.58075 0.68106 5.6705 1.03284 4.82122C1.38463 3.97194 1.90024 3.20026 2.55025 2.55025C3.20026 1.90024 3.97194 1.38463 4.82122 1.03284C5.6705 0.68106 6.58075 0.5 7.5 0.5C9.35652 0.5 11.137 1.2375 12.4497 2.55025C13.7625 3.86301 14.5 5.64348 14.5 7.5C14.5 9.35652 13.7625 11.137 12.4497 12.4497C11.137 13.7625 9.35652 14.5 7.5 14.5Z" stroke="black" />
                                </g>
                                <defs>
                                  <clipPath id="clip0_21_11">
                                    <rect width="15" height="15" fill="white" />
                                  </clipPath>
                                </defs>
                              </svg>
                            </span>
                          </td>
                        </tr>
                      })}
                  </>
              }
            </tbody>
          </table> </>: <>{books === null ? <> <div className='No-games-slider d-flex justify-content-center align-items-center'><div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div> </div> </>:<div className='No-games'>
            <NoData />
          </div>}  </>
          }
        
        
        </div>
      </div>
    </div>
  </> }</>
  );
};

export default MyForm;

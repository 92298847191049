import React, { useState, useEffect } from "react";
import { Routes, Route, Navigate, useLocation, Outlet } from "react-router-dom";
import { LoginLayout } from '../layout/LoginLayout';
import { Layout } from '../layout/Layout';
import main from "./main.js";
import organizer from "./organizer.js";
import { useSelector } from "react-redux";
import Loader from "../components/PageLoader/Loader.js";
import { DragCalander } from "../components/Calanderdragndrop/index.js";

const PublicRoutes = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const queryData = queryParams.get('auth') || '';
  let isAuthenticated = useSelector(state => state.isAuthenticated) || false;

  if (queryData.length) {
    const decoded = atob(queryData).split('&');
    if (decoded[0] !== window.localStorage.getItem('id')) {
      window.localStorage.clear();
      isAuthenticated = false; // Mark user as unauthenticated if IDs don't match
    }
  }

  return (
    isAuthenticated ? <Navigate to="/organizer/dashboard" /> : <LoginLayout />
  );
};


const PrivateRoutes = () => {
  const isAuthenticated = useSelector(state => state.isAuthenticated);
  return (
    isAuthenticated ? <Layout /> : <Navigate to="/" />
  );
};

const PrivateRoutesWithoutLayout = () => {
  const isAuthenticated = useSelector(state => state.isAuthenticated);
  return (
    isAuthenticated ? <Outlet/>  : <Navigate to="/" />
  );
};




function RoutesComponent() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(false); // Set loading to false when component mounts
  }, []);

  useEffect(() => {
    // Function to handle window onload event
    const handleLoad = () => {
      setLoading(false); // Set loading to false when page finishes loading
    };

    // Listen for the window onload event
    window.addEventListener("load", handleLoad);

    // Clean up the event listener
    return () => window.removeEventListener("load", handleLoad);
  }, []);

  if (loading) {
    return <Loader />; // Replace with your loading indicator
  }

  return (
    <Routes>
      <Route path="/" element={<PublicRoutes />}>
        {main}
      </Route>
      <Route path="/organizer" element={<PrivateRoutes />}>
        {organizer}
      </Route>
      <Route path="/calander" element={<PrivateRoutesWithoutLayout />}>
      <Route path='/calander/:sportId/events/:type/:gameId/:seasonId/:groupId' element={<DragCalander/>} />
      </Route>
    </Routes>
  );
}



export default RoutesComponent;

import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './index.css'
import { ApiGetAuth, Apipost } from '../../../helpers/apiCall';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import plus from '../../../assets/images/plus.png'
import optionico from '../../../assets/images/options.png'
import edit from '../../../assets/images/edit.png'
import deletes from '../../../assets/images/delete.png'
import DeletePopup from '../../../components/DeletePopup';
import Cardloader from '../../Mainloader.js/Cardloader';
import Poploader from '../Event/Popuploader';
import moment from 'moment';
import BreadcrumbComponent from '../Breadcrumb/Breadcrumb';
import { errorMessageNodify, successMessageNodify } from '../../../helpers/notify';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import '../../../assets/css/payment.css';
import SeasonDashboard from './dashboard';
import withLoader from '../../../components/PageLoader';
import { MyVerticallyCenteredModal } from '../../../components/MyVerticallyCenteredModal';
import { useSelector } from 'react-redux';
import FileUpload from '../../../components/FileUpload';

export const EnabledStatus = ({ status }) => {
  return <><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <circle cx="10" cy="10" r="9.5" stroke="#484848" />
    <circle cx="10" cy="10" r="7" fill={status ? "#27AE60" : "#D0D0D0"} />
  </svg>  </>

}

export function formatDivisions(data) {
  return data.reduce((result, item) => {
      const division = item["Division "].trim();
      const team = item["Team "].trim();
      const color = item["Color"].trim(); // Assuming the color data is in the "Color" field

      // If the division is not already in the result, initialize it
      if (!result[division]) {
          result[division] = []; // Array to hold teams
      }
      if (!result[`${division}_color`]) {
          result[`${division}_color`] = color; // Add the color as a separate key
      }

      result[division].push(team);
      return result;
  }, {});
}



const SeasonsWithLoader = () => {
  const { sportId, gameId, type } = useParams()
  const [seasons, setSeasons] = useState(null);
  const [contentToRender, setContentToRender] = useState()
  const [showModal, setShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [title, setTitle] = useState('')
  const [season_id, setSeason_id] = useState('')
  const [search, setSearch] = useState('')
  const [tab, tabActive] = useState("dashboard");
  const [selectedSeason, setSelectedSeason] = useState({ id: null })
  const [modalShow, setModalShow] = useState(false);
  const [modelType, setModelType] = useState('')
  const [id, setId] = useState(0)
  const selector = useSelector(state => state.PAYMENTDETAIL)
  const [price, setPrice] = useState(0)
  const openModal = (e, value) => {
    e.preventDefault()
    setContentToRender(value)
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchSeasons();
  }, [gameId]);

  const fetchSeasons = async () => {
    try {
      const response = await ApiGetAuth(`season/List-season/${gameId}`);
      setSeasons(response.data);
      if (response.data.length > 0) {
        setSelectedSeason(response.data[0])
      }
    } catch (error) {
      console.error('Error fetching seasons:', error);
    }
  };




  useEffect(() => {
    if (!(seasons == null)) {
      if (seasons?.length) {
        setSelectedSeason(seasons[0])
      } else {
        setSelectedSeason({ id: 0 })
      }
    }
  }, [seasons])

  const closeDeleteModal = () => {
    setSeason_id(0)
    setShowDeleteModal(false);
  };
  const openDeleteModal = (id, title, modelType) => {
    setModelType(modelType)
    setSeason_id(id)
    setTitle(title)
    setShowDeleteModal(true);
  };
  const DynamicTable = ({ formik }) => {
    const handleAddRow = (e) => {
      e.preventDefault();


      // Create a new row with initial values
      const newRow = { plans: '', days: '', price: '' };

      // Update Formik values and set the new row
      formik.setFieldValue('rows', [...formik.values.rows, newRow]);
    };

    const handleRemoveRow = (index) => {
      // Copy the current rows
      const updatedRows = [...formik.values.rows];

      // Remove the row at the specified index
      updatedRows.splice(index, 1);

      // Update Formik values with the modified rows
      formik.setFieldValue('rows', updatedRows);
    };

    const handleInputChange = (index, key, value) => {
      // Copy the current rows
      const updatedRows = [...formik.values.rows];

      // Update the specific field in the row
      updatedRows[index][key] = value;

      // Update Formik values with the modified rows
      formik.setFieldValue('rows', updatedRows);

      // Formik validation for dynamic plans
      if (formik.touched.rows && formik.touched.rows[index]) {
        formik.setFieldTouched(`rows[${index}].${key}`, true, false);
      }
      if (formik.values.rows && formik.values.rows[index]) {
        formik.validateForm();
      }

    };


    return (
      <div>
        <table className="table table-striped table-hover season_payment_table">
          <thead>
            <tr>
              <th>Registration Fees</th>
              {/* <th>Days</th> */}
              <th>Charges</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody className="w-100">
            {formik.values.rows.map((row, index) => (
              <tr key={index}>
                <td>
                  <div className="form-group">
                    <input
                      maxLength={30}
                      className='w-100'
                      placeholder="Registration Fees"
                      name={`rows[${index}].plans`}
                      value={row.plans}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleInputChange(index, 'plans', e.target.value);
                      }}
                    />
                    {formik.touched.rows && formik.touched.rows[index] && formik.errors.rows && formik.errors.rows[index]?.plans && (
                      <div className='text-danger'>{formik.errors.rows[index].plans}</div>
                    )}
                  </div>

                </td>
                <td>
                  <div className="form-group">
                    <input
                      className='w-100'
                      placeholder="Price"
                      type="number"
                      min={0}
                      name={`rows[${index}].price`}
                      value={row.price}
                      onChange={(e) => {
                        formik.handleChange(e);
                        handleInputChange(index, 'price', e.target.value);
                      }}
                    />
                    {formik.touched.rows && formik.touched.rows[index] && formik.errors.rows && formik.errors.rows[index]?.price && (
                      <div className='text-danger'>{formik.errors.rows[index].price}</div>
                    )}
                  </div>

                </td>
                <td>
                  <span className="remove_button" onClick={() => handleRemoveRow(index)} disabled={formik.values.rows.length == 1 ? true : false}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="23" height="28" viewBox="0 0 23 28" fill="none">
                      <path d="M15.3612 0C15.7627 0.197132 15.9015 0.540287 15.9015 0.963755C15.9015 1.45293 15.8942 1.94941 15.9015 2.43859C15.9088 2.66493 15.8065 2.73794 15.5875 2.73794C12.667 2.73064 9.73927 2.73064 6.8188 2.73794C6.57786 2.73794 6.49754 2.63572 6.50485 2.40939C6.51215 1.94211 6.51215 1.48214 6.50485 1.01486C6.50485 0.576793 6.63627 0.211734 7.05974 0C9.82688 0 12.594 0 15.3612 0Z" fill="#F1372B" />
                      <path d="M11.2074 9.44041C14.2009 9.44041 17.2017 9.44041 20.1952 9.44041C20.604 9.44041 20.604 9.44041 20.5748 9.83467C20.458 11.4701 20.3485 13.0983 20.2317 14.7338C20.0929 16.5956 19.9469 18.4501 19.8082 20.3119C19.706 21.6845 19.6184 23.0498 19.5235 24.4224C19.465 25.2109 19.4212 25.9995 19.3482 26.788C19.2825 27.5692 18.8006 28 18.0121 28C13.4489 28 8.88564 28 4.32241 28C3.51198 28 3.05201 27.5838 2.98629 26.7661C2.88408 25.4665 2.80377 24.1742 2.71615 22.8746C2.59203 21.1588 2.46791 19.443 2.35109 17.7272C2.27078 16.5225 2.19777 15.3105 2.11746 14.1059C2.01524 12.6602 1.91302 11.2219 1.81081 9.77626C1.7889 9.46961 1.81081 9.43311 2.13206 9.43311C5.16204 9.44041 8.18473 9.44041 11.2074 9.44041ZM7.62254 18.2967C7.62254 20.0417 7.62254 21.794 7.62254 23.539C7.62254 23.6996 7.62254 23.8675 7.67365 24.0282C7.77586 24.3859 8.05331 24.5903 8.41837 24.583C8.78343 24.5757 9.05357 24.3494 9.12658 23.9843C9.15579 23.8237 9.17039 23.6558 9.17039 23.4952C9.17039 20.0563 9.17039 16.6175 9.17039 13.1786C9.17039 12.9961 9.15579 12.8135 9.12658 12.6383C9.06817 12.2879 8.79803 12.0615 8.44757 12.0396C8.08982 12.0177 7.79777 12.2149 7.68825 12.5434C7.63714 12.7113 7.62254 12.8793 7.62254 13.0545C7.62254 14.7995 7.62254 16.5517 7.62254 18.2967ZM14.7631 18.3332C14.7631 17.9609 14.7631 17.5885 14.7631 17.2162C14.7631 15.7486 14.7631 14.2884 14.7631 12.8209C14.7631 12.3463 14.4783 12.0469 14.0403 12.0323C13.6168 12.0177 13.3029 12.3171 13.259 12.777C13.2517 12.8647 13.2517 12.9596 13.2517 13.0472C13.2517 16.5517 13.2517 20.0563 13.2517 23.5609C13.2517 23.7142 13.2517 23.8675 13.2883 24.0208C13.4124 24.4443 13.8285 24.678 14.252 24.5465C14.5952 24.437 14.7631 24.1888 14.7631 23.7434C14.7631 21.94 14.7631 20.1366 14.7631 18.3332Z" fill="#F1372B" />
                      <path d="M11.2073 7.92173C7.62243 7.92173 4.03025 7.92173 0.445372 7.92173C0 7.92173 0 7.92174 0 7.46906C0 7.03099 0 6.59292 0 6.15485C0.00730117 5.02317 0.781226 4.24194 1.90561 4.24194C5.91395 4.24194 9.92229 4.24194 13.9233 4.24194C16.1064 4.24194 18.2967 4.24194 20.4798 4.24194C21.6626 4.24194 22.4292 5.00857 22.4292 6.19136C22.4292 6.67323 22.4219 7.15511 22.4292 7.63699C22.4365 7.83412 22.3562 7.92174 22.1591 7.91443C21.9035 7.90713 21.648 7.91443 21.3924 7.91443C17.9974 7.92174 14.6023 7.92173 11.2073 7.92173Z" fill="#F1372B" />
                    </svg>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <div className="">
          {formik.values.rows.length < 5 ? (
            <button className="add_more_plan" onClick={handleAddRow}>
              +Add More
            </button>
          ) : (
            <></>
          )}
        </div>
        <div className="card mt-3">
          <div class="card-header text-secondary ">
            <span className='text-danger'>Note :</span>If you have more then one price/fee , you may add it here .
            for example , March is $239 and April is $279
          </div>
        </div>
        <div className="d-flex paymentt">
          <label>Last Date of Payment :</label>
          <input
            type="date"
            name="finalDate"
            value={formik.values.finalDate}
            onChange={(e) => formik.setFieldValue('finalDate', e.target.value)}
            onBlur={() => formik.setFieldTouched("finalDate", true)}
          />
          {formik.errors.finalDate && formik.touched.finalDate && (
            <div className='text-danger'>{formik.errors.finalDate}</div>
          )}
        </div>

      </div>
    );
  };
  function CreateSeason({ values, isUpdate }) {
    const [loader, setLoader] = useState(false);
    const [copySeasonId, setCopySeasonId] = useState(0)
    const [divisions, setDivisions] = useState([])
    const [copyTeams, setCopyTeams] = useState(true);
    const [copyFromPrev , setCopyFromPrev] = useState(false);
    const [data , setData] = useState({})
    const [selectedDivisions, setSelectedDivisions] = useState([]);
    const [selectedImportDivisions, setSelectedImportDivisions] = useState([]);
    const max_points = useSelector(state => state.SPORTSLIST.filter(item => item.id == sportId)[0])
    const formik = useFormik({
      initialValues: isUpdate
        ? values
        : {
          season_name: '',
          max_points: max_points?.max_diff_point || 20,
          game_id: gameId,
          start_date: '',
          end_date: '',
          rows: [{ plans: '', price: '' }],
          instructions: '',
          finalDate: '',
        },
      validationSchema: Yup.object({
        season_name: Yup.string().required('Season Name is required').max(30, 'Season name cannot be longer than 30 characters'),
        max_points: Yup.number().required('Max points is required'),
        start_date: Yup.date().required('Start Date is required'),
        finalDate: Yup.date().max(Yup.ref('end_date'), 'Final Payment Date must be before End Date').required("Final payment date is Required"),
        end_date: Yup.date()
          .required('End Date is required')
          .min(Yup.ref('start_date'), 'End Date must be after or equal to the Start Date'),
        rows: Yup.array().of(
          Yup.object().shape({
            plans: Yup.string().required('Registration fee is required').min(3, 'Registration fee must be at least 3 characters')
              .max(40, 'Registration fee must be 40 characters or less'),

            price: Yup.number().required('Charges is required').min(0, 'Charges must be a non-negative number')
              .max(99999, 'Charges must be at most 99999'),
          })
        ),
      }),
      onSubmit: (values) => {
       if( formik.values.key != 'import' ){
        formik.setFieldValue('key', 'import')
        return 
       }
        let baseUrl = isUpdate
          ? `/season/update-season`
          : '/season/create-season';
        setLoader(true);
        values.start_date = new Date(values.start_date)
          .toISOString()
          .split('T')[0];
        values.end_date = new Date(values.end_date).toISOString().split('T')[0];
        values.enabled = values.enable ? 1 : 0;
        values.game_id = gameId;
        values.selectedDivisions = selectedDivisions;
        values.copyTeams = copyTeams;
        values.copySeasonId = copySeasonId;
        values.ImportData =data
        values.selectedImportDivisions = selectedImportDivisions
        Apipost(
          baseUrl,
          values,
          'application/x-www-form-urlencoded',
          true
        ).then(
          (response) => {
            if (isUpdate) {
              const newState = seasons.map((obj) => {
                if (obj.id === values.id) {
                  return { ...obj, ...values };
                }
                return obj;
              });
              setSeasons(newState);
            } else {
              fetchSeasons();
            }
            fetchSeasons();
            closeModal();
            successMessageNodify(response.data.message);
            setLoader(false);
          },
          (err) => {
            errorMessageNodify(err.response.data.message);
            setLoader(false);
          }
        );
      },
    });
    const handleDivisions = (id) => {
      ApiGetAuth(`season/get-divisions?id=${id}`).then(
        (success) => {
          console.log(success, "success")
          setDivisions(success.data)
          setSelectedDivisions(success.data.map((item) => item.id));
          setData({})
          return;
        }, (err) => {
          // setLoading(false);
          return;
        }
      )
    }

    useEffect(() => {
      if (isUpdate) {
        const startDate = values.start_date ? new Date(values.start_date).toISOString().split('T')[0] : '';
        const endDate = values.end_date ? new Date(values.end_date).toISOString().split('T')[0] : '';

        formik.setValues({
          ...formik.values,
          rows: values.rows,
          finalDate: values.rows[0]?.final_day_of_payment,
          start_date: startDate,
          end_date: endDate,
        });
      }

      if (copySeasonId > 0) {
        const season = seasons?.filter(item => item.id == copySeasonId)[0]
       
        if(isUpdate){
          // formik.setValues({
          //   ...season,
          //   rows: season.rows,
          //   finalDate: season.rows[0]?.final_day_of_payment
          // })
        }else{
          formik.setValues({
            ...season,
            rows: season.rows,
            finalDate: season.rows[0]?.final_day_of_payment,
            start_date: '',
            end_date: '',
          })
        }
        handleDivisions(copySeasonId)
      }
    }, [isUpdate, copySeasonId]);

    const handleDivisionSelection = (id, isChecked) => {
      if (isChecked) {
        // Add the ID to the selectedDivisions array
        setSelectedDivisions(prevSelected => [...prevSelected, id]);
      } else {
        // Remove the ID from the selectedDivisions array
        setSelectedDivisions(prevSelected => prevSelected.filter(divId => divId !== id));
      }
    };

    const handleFileUploaded = (jsonArray) => {
      setData(formatDivisions(jsonArray));
      setCopySeasonId(0)
      setDivisions([])
      setSelectedDivisions([])
    };
    console.log(data , "data")

    const BasicInfo = (
      <>
        Basic Info <span style={{ color: 'red' }}>*</span>
      </>
    );
    const titlePaymentDetails = <span className="payment_detail_basic">Payment Details <span className="red">*</span></span>

    return (
      <form className='season-popup newCreateLeague' onSubmit={formik.handleSubmit}>
        <span className="weight_bold"> {isUpdate ? 'Update' : 'Create'} Season</span>
        <Tabs
          id='controlled-tab-example'
          activeKey={formik.values.key}
          onSelect={(k) => formik.setFieldValue('key', k)}
          className='mb-3'
        >
          <Tab eventKey='home' title={BasicInfo} className='button_season'>
            <div className="form_submit_basketball">
              <div className='row mt-3'>
                <div className={copyFromPrev ? 'col-md-6': 'col-md-12'}  >
                  <div className="form-group">
                    <label htmlFor='season_name'> Season Name <span style={{ color: 'red' }}>*</span></label>
                    <input
                      maxLength={60}
                      type='text'
                      name='season_name'
                      placeholder='Enter season name'
                      value={formik.values.season_name}
                      onChange={formik.handleChange}
                    />
                    {formik.errors.season_name && formik.touched.season_name && (
                      <div className='text-danger'>{formik.errors.season_name}</div>
                    )}
                  </div>
                </div>
                {
                  copyFromPrev ?  <div className="form-group col-md-6" >
                    <label htmlFor='season_name'> Previous Season <span style={{ color: 'red' }}>*</span></label>
                    <select  value={copySeasonId} onChange={(e) => setCopySeasonId(e.target.value)} >
                      <option value={0}>Select Season</option>
                      {seasons?.map((item) => (
                        <option key={item.id} value={item.id}>
                          {item.season_name}
                        </option>
                      ))}
                    </select>
                  </div>:null
                }
               
                <div className='mt-1'>
                      <div>
                        <div>
                          <div className='text-end ' >
                            <input
                              type="checkbox"
                              checked={copyFromPrev}
                              onChange={(e) => setCopyFromPrev(e.target.checked)}
                              style={{
                                width: '20px',
                                height: '20px',
                                borderRadius: '4px',
                                border: '2px solid #ccc',
                                cursor: 'pointer'
                              }}
                            /> Copy Season From Previous Season
                          </div>
                        </div>
                      </div></div>
                {/* <div className='col-md-6'>
                    <div className="form-group">
                      <label htmlFor='season_name'>Max Differential Points <span style={{ color: 'red' }}> *</span>
                      <Tooltip content="A maximum differential points cap limits the point gap between winning and losing teams to prevent excessive discouragement. This approach helps maintain morale and encourages ongoing participation by ensuring no team faces an overly large deficit."/>
                      </label>
                      <input
                        maxLength={5}
                        type='number'
                        placeholder='Enter Max Points'
                        name='max_points'
                        min={0}
                        value={formik.values.max_points}
                        onChange={formik.handleChange}
                        className='custom-number-input'
                      />
                      {formik.errors.max_points &&
                        formik.touched.max_points && (
                          <div className='text-danger'>{formik.errors.max_points}</div>
                        )}
                    </div>    
                  </div> */}
              </div>

              <div className='row'>
                <div className='col-md-6'>
                  <div className='form-group mb-0'>
                    <label className='form-label'>Start Date <span style={{ color: 'red' }}>*</span></label>
                    <span className='imp_ast'></span>
                    <div className='input-right-icon'>
                      <input
                        className='form-control'
                        type='date'
                        name='start_date'
                        value={formik.values.start_date}
                        onChange={formik.handleChange}
                      />
                      {formik.errors.start_date &&
                        formik.touched.start_date && (
                          <div className='text-danger'>{formik.errors.start_date}</div>
                        )}
                    </div>
                  </div>
                </div>
                <div className='col-md-6'>
                  <div className='form-group mb-0'>
                    <label className='form-label'>End Date <span style={{ color: 'red' }}>*</span></label>
                    <span className='imp_ast'></span>
                    <div className='input-right-icon'>
                      <input
                        className='form-control'
                        type='date'
                        name='end_date'
                        value={formik.values.end_date}
                        onChange={formik.handleChange}
                        min={
                          formik.values.start_date
                            ? moment(formik.values.start_date).format('YYYY-MM-DD')
                            : new Date().toISOString().split('T')[0]
                        }
                      />
                      {formik.errors.end_date &&
                        formik.touched.end_date && (
                          <div className='text-danger'>{formik.errors.end_date}</div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              
              <button
                type='submit'
                className="button_submit"
                onMouseUp={() => {
                  if (formik.errors.season_name || formik.errors.start_date || formik.errors.end_date) {
                  }
                  else {
                    if (formik?.errors?.finalDate?.length) {
                      formik.setValues({
                        ...formik.values,
                        key: "payment"
                      });
                    }
                  }
                }}
              >
                <Poploader content={isUpdate ? 'Update' : 'Create'} loader={loader} />
              </button>
            </div>
          </Tab>
          <Tab eventKey='profile' title='Instructions'>
            <div className='row'>
              <div className='col-md-12'>
                <CKEditor

                  editor={ClassicEditor}
                  data={
                    formik.values.instructions
                      ? atob(formik.values.instructions)
                      : '<p></p>'
                  }
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                  }}
                  onChange={(event, editor) => {
                    const data = editor.getData();
                    formik.setFieldValue('instructions', btoa(data));
                  }}

                  onBlur={(event, editor) => {
                    console.log('Blur.', editor);
                  }}
                  onFocus={(event, editor) => {
                    console.log('Focus.', editor);
                  }}
                />
                {formik.touched.instructions && formik.errors.instructions && (
                  <div className="error">{formik.errors.instructions}</div>
                )}
              </div>
            </div>
            <button
              type='submit'
              className="button_submit">
              <img className='p-icon ms-0' src={plus} />{' '}
              <Poploader content={isUpdate ? 'Update' : 'Create'} loader={loader} />
            </button>
          </Tab>
          <Tab eventKey='payment' title={titlePaymentDetails}>
            <div className="form_submit_basketball">
              <DynamicTable formik={formik} />
            </div>
            <button
              type='submit'
              className="button_submit"
            >
              <Poploader content={isUpdate ? 'Update' : 'Create'} loader={loader} />
            </button>
          </Tab>

        {/* {  (copySeasonId == 0 && Object.keys(data).length == 0) ? null:  */}
        
          <Tab eventKey='import' title="Import">
         
            <div className='inport-division'>
          
                <div className='row'>
                {copyFromPrev ?  null: <div className='row' >
              <FileUpload  text='Import divisions and teams' onFileUploaded={handleFileUploaded} requiredKeys={['Division', 'Team']} sampleFileUrl="https://teamconnect-media.nyc3.cdn.digitaloceanspaces.com/sample-data/GIU_Season_Sample.csv" />
              </div> }
                 
                </div>
              <div className='division-check'>
                {copySeasonId > 0 && (
                  <div className="">
                    <label>Divisions</label>
                    <div className='row' style={{ listStyleType: 'none' }} >
                      {divisions.map((item) => (
                        <div className='col-md-4 py-2' key={item.id}>
                          <input
                            type="checkbox"
                            checked={selectedDivisions.includes(item.id)}
                            onChange={(e) => handleDivisionSelection(item.id, e.target.checked)}
                            className="form-check-input mx-1  custom-checkbox"
                          />
                          {item.group_name}
                        </div>
                      ))}
                    </div>
                  </div>
                )}
                {
                  <>
                  {
                    Object.keys(data).length > 0 ? <div>
                      <label>Imported Divisions</label>
                         <div className='row' style={{ listStyleType: 'none' }}>
                           {Object.keys(data).filter((divisionName) => !divisionName.endsWith('_color')).map((divisionName) => (
                             <div className='col-md-4 py-2' key={divisionName}>
                               <input
                                 type="checkbox"
                                 value={divisionName}
                                 checked={selectedImportDivisions.includes(divisionName)}
                                 onChange={(e) => {
                                   const isChecked = e.target.checked;
                                   setSelectedImportDivisions(prev =>
                                     isChecked
                                       ? [...prev, divisionName] // Add division
                                       : prev.filter(name => name !== divisionName) // Remove division
                                   );
                                 }}
                                 className="form-check-input mx-1 custom-checkbox"
                               />
                               {divisionName} ({data[divisionName].length} Teams)
                             </div>
                           ))}
                         </div>
                    </div>:null
                  }
                  </>
                }

              </div>
              {
                (copySeasonId > 0 && divisions.length > 0)? <>
                <div className='mt-5'>
                Do you want to copy Grade Teams Also ?
                <input
                  type="checkbox"
                  className='m-2 mt-3'
                  checked={copyTeams}
                  onChange={(e) => setCopyTeams(e.target.checked)}
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '4px',
                    border: '2px solid #ccc',
                    cursor: 'pointer'
                  }}
                />
              </div>
                </>:null
              }
              
              <button
                type='submit'
                className="button_submit"
              >
                <Poploader content={isUpdate ? 'Update' : 'Create'} loader={loader} />
              </button>

            </div>
          </Tab>
          {/* } */}
        </Tabs>
      </form>
    );
  }
  let filteredSeasons = () => {
    let result = seasons
    if (search) {
      return seasons?.filter(item => item.season_name.toLowerCase().startsWith(search.toLowerCase()))
    }
    return result
  };

  function CustomModal({ showModal, onClose, componentToRender }) {
    return (
      <Modal show={showModal} onHide={onClose} className="create_season_modal"
      dialogClassName="modal-dialog-centered modal-lg">
        <Modal.Body>
          <button type="button" className="btn-close" onClick={onClose}></button>
          {componentToRender}
        </Modal.Body>
      </Modal>
    );
  }

  const handlePublish = (id) => {
    ApiGetAuth(`season/publish-season?id=${id}&game_id=${gameId}`).then(
      (success) => {
        fetchSeasons()
        setModalShow(false)
        successMessageNodify('Season Published Successfully !')
        return;
      }, (err) => {
        // setLoading(false);
        return;
      }
    )
  }





  const handleShow = (id, price) => {
    setId(id)
    setModalShow(true)
    setPrice(price)
  }
  return (
    <>
      <BreadcrumbComponent />
      <MyVerticallyCenteredModal
        price={price}
        show={modalShow}
        handlePublish={() => handlePublish(id)}
        onHide={() => setModalShow(false)}
      />
      <div className="organizer_events">
        <DeletePopup isOpen={showDeleteModal} onClose={closeDeleteModal} values={seasons} setValues={setSeasons} endpoint={`/season/delete-season/${season_id}`} id={season_id} title={title} modelType={modelType} />
        <div>
          <CustomModal showModal={showModal} onClose={closeModal} componentToRender={contentToRender} />
          <div className='row d-flex flex-row'>
            <div className='grade-content team-list-side'>
              {tab == 'seasons' ? <>
                <div className='search-teams'>
                  <div className="serach-properties d-flex">
                    <div className='heading-view '>
                      <div className="team-top-fil">

                        <div className='serach-properties'>
                          <div className="team-search">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                              <path d="M9.19668 0.839844C9.83401 0.92695 10.4665 1.03732 11.0735 1.25833C13.1246 2.00522 14.6622 3.33914 15.6601 5.28522C16.1773 6.29398 16.4576 7.36441 16.5082 8.49868C16.5969 10.4937 16.0277 12.2775 14.8172 13.8611C14.6427 14.0894 14.6435 14.0878 14.8383 14.2901C16.2993 15.8072 17.7596 17.3248 19.2204 18.8418C19.4587 19.0891 19.6664 19.3485 19.671 19.7186C19.6764 20.1641 19.4914 20.502 19.0989 20.7057C18.7002 20.9126 18.3006 20.8823 17.9479 20.5996C17.7463 20.4381 17.5724 20.2407 17.3925 20.0535C16.0053 18.61 14.6178 17.1668 13.2366 15.7177C13.1045 15.5791 13.0115 15.5575 12.8476 15.6684C11.8623 16.3347 10.7716 16.7418 9.59785 16.9076C8.00208 17.1327 6.46204 16.9252 5.00693 16.2132C2.5915 15.0316 1.07391 13.1221 0.518813 10.4954C0.0981614 8.50517 0.422239 6.60399 1.45723 4.84455C2.5658 2.96014 4.18836 1.72605 6.28269 1.10901C6.73796 0.974831 7.20785 0.912612 7.67557 0.839844H9.19695H9.19668ZM8.42084 14.8336C11.6586 14.8425 14.347 12.2502 14.3684 8.94449C14.3895 5.68775 11.8212 2.98124 8.44627 2.97313C5.15707 2.96528 2.5209 5.56954 2.509 8.89065C2.49709 12.2345 5.19792 14.8244 8.42111 14.8336H8.42084Z" fill="#F05829" />
                            </svg>
                            <input type="text" className="form-control " id="exampleFormControlInput1" onChange={(e) => { setSearch(e.target.value) }} placeholder="Search Seasons.." />
                            <div className="s-icon"></div>
                          </div>
                          <div className='back-btn-dashboard'>
                            <Link href="#"> <span className='back' onClick={() => tabActive('dashboard')}>
                              <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="32" height="32" rx="6" fill="#F05829" />
                                <path d="M10.947 17.2243C10.9863 17.3879 11.0873 17.4465 11.1619 17.5219C12.2375 18.6007 13.3139 19.6787 14.3936 20.7535C14.6911 21.0495 14.882 21.3831 14.8572 21.8179C14.8299 22.2903 14.6077 22.6384 14.193 22.8526C13.7776 23.0667 13.3597 23.0475 12.9642 22.7956C12.8391 22.7154 12.7268 22.6111 12.6209 22.5052C11.0039 20.8906 9.38692 19.2752 7.77231 17.6582C6.74484 16.6283 6.74243 15.3699 7.7659 14.344C9.39414 12.7125 11.024 11.0819 12.6562 9.45447C13.257 8.85611 14.0607 8.84969 14.5628 9.4288C15.0023 9.93572 14.9582 10.6672 14.4401 11.1894C13.3532 12.285 12.2592 13.3743 11.1684 14.4667C11.0914 14.5437 11.0184 14.6239 10.9317 14.7162C11.0585 14.806 11.1812 14.7715 11.2935 14.7715C15.4226 14.7739 19.5526 14.7739 23.6817 14.7723C24.1694 14.7723 24.5311 14.9865 24.7638 15.4052C24.9972 15.8263 24.9803 16.2554 24.7245 16.6604C24.467 17.0671 24.0852 17.2291 23.6023 17.2283C19.5133 17.2219 15.4234 17.2251 11.3344 17.2251H10.9462L10.947 17.2243Z" fill="white" />
                              </svg>
                              <span className='btn-back-to-view'>  Back</span>
                            </span></Link>

                          </div>

                        </div>
                      </div>
                    </div>
                    <div className='wrap-create-button'>
                      <button className="btn btn-dark green orange-color" onClick={(e) => openModal(e, <CreateSeason isUpdate={false} />)}> Create Season </button>
                    </div>
                  </div>
                </div>

                <div className='row create-cards '>
                  {
                    seasons === null ? <Cardloader content={"Seasons"} /> :
                      <>
                        {seasons?.length ?
                          <>
                            {filteredSeasons()?.map(item => {
                              let total = 0
                              let temp = selector.rows.filter(items => items.season_id == item.id)
                              if (temp.length) {
                                let totalCharge = ((temp[0].teamCount || 0) * selector.charges.team_charge);
                                let taxCharges = Number(selector.charges.transaction_charges) + Number(selector.charges.tax);
                                total = Math.ceil(totalCharge + (totalCharge * (taxCharges / 100)));
                              }
                              return <div className='col-xxl-3 col-xl-4 col-lg-5 col-md-6 col=sm-12'>
                                <div className='l-card new_tournament_design'>
                                  <div className='wrap'>
                                    <Link to={`/organizer/${sportId}/events/${type}/${gameId}/${item.id}`} className="tournament_card_redesign">
                                      <div className='parties'>
                                        <div className='l-title'>
                                          <svg className="trophy_img" xmlns="http://www.w3.org/2000/svg" width="80" height="80" viewBox="0 0 80 80" fill="none">
                                            <circle cx="40" cy="40" r="40" fill="#F2F7FF" />
                                            <path d="M30.5262 35.7792C30.4144 35.747 27.7408 34.9548 23.3515 31.2192C18.8167 27.3592 18.6713 20.9849 18.6671 20.7157C18.6636 20.4969 18.7482 20.2858 18.9006 20.1285C19.053 19.9718 19.2621 19.8817 19.4809 19.8796L27.2633 19.788L27.2828 21.4359L20.3793 21.517C20.5751 23.2572 21.3533 27.355 24.4198 29.9642C28.4931 33.4314 30.9583 34.1879 30.9828 34.1956L30.5262 35.7792Z" fill="#FCAB28" />
                                            <path d="M50.3901 35.7792L49.937 34.1949C49.9573 34.1886 52.4225 33.4314 56.4965 29.965C59.5623 27.3557 60.3412 23.2586 60.537 21.5177L53.6335 21.4366L53.653 19.7887L61.4354 19.8803C61.6542 19.8831 61.8633 19.9726 62.0157 20.1292C62.1688 20.2858 62.2527 20.4976 62.2492 20.7165C62.245 20.9857 62.1003 27.3606 57.5642 31.22C53.1755 34.9549 50.5012 35.7477 50.3894 35.7799L50.3901 35.7792Z" fill="#FCAB28" />
                                            <path d="M26.9075 19.8797C26.9075 19.8797 25.9 19.5134 25.9 18.5066C25.9 17.4998 25.7168 17.0418 26.6327 16.1259C27.5486 15.21 34.4766 13.9592 40.0615 13.9592C45.6463 13.9592 53.2763 15.4855 54.1922 16.3091C55.1081 17.1334 54.7417 19.3309 54.5585 19.6965C54.3754 20.0629 53.6426 19.9713 53.6426 19.9713L26.9075 19.8797Z" fill="#FFCF3F" />
                                            <path d="M40.0003 17.041C36.5527 17.041 29.2877 17.499 26.6323 19.4217C26.6323 19.4217 26.6323 29.3099 29.3793 34.3459C32.1263 39.382 36.9785 43.5021 36.9785 43.5021H43.5709C43.5709 43.5021 48.4237 39.382 51.17 34.3459C53.917 29.3099 53.917 19.4217 53.917 19.4217C50.9869 17.499 43.4478 17.041 40.0003 17.041Z" fill="#FFD733" />
                                            <path d="M40.0003 17.041C36.5527 17.041 29.2877 17.499 26.6323 19.4217C26.6323 19.4217 26.6323 29.3099 29.3793 34.3459C30.6805 36.7315 32.4542 38.9107 33.9609 40.5447C35.0153 40.9921 36.2269 41.2739 37.6196 41.304C46.0431 41.4871 48.2406 34.3452 49.1565 31.7821C50.5988 27.7431 51.4196 21.401 51.7427 18.4575C48.2035 17.3382 42.7766 17.041 40.001 17.041H40.0003Z" fill="#FFE266" />
                                            <path d="M49.7052 55.8626C48.8809 53.3904 47.1414 53.7568 47.1414 53.7568C47.1414 53.7568 47.1386 52.9618 45.9207 52.8479C45.1663 52.6157 43.2219 51.869 42.5633 50.1855C41.739 48.0796 42.1053 45.4242 42.1053 45.4242L40.2742 45.1851L38.4431 45.4242C38.4431 45.4242 38.8095 48.0796 37.9852 50.1855C37.3266 51.869 35.3822 52.6157 34.6278 52.8479C33.4099 52.9618 33.4071 53.7568 33.4071 53.7568C33.4071 53.7568 31.6676 53.3904 30.8433 55.8626H49.7045H49.7052Z" fill="#FFCE45" />
                                            <path d="M33.4077 53.7568C34.5522 53.6826 35.6968 53.633 36.8413 53.6078C37.9858 53.5771 39.1303 53.5694 40.2749 53.5652C41.4194 53.5687 42.5639 53.5764 43.7084 53.6071C44.853 53.6323 45.9975 53.6819 47.142 53.7561C45.9975 53.8302 44.853 53.8805 43.7084 53.905C42.5639 53.935 41.4194 53.9434 40.2749 53.9469C39.1303 53.9434 37.9858 53.935 36.8413 53.9043C35.6968 53.8791 34.5522 53.8295 33.4077 53.7554V53.7568Z" fill="#FCAB28" />
                                            <path d="M43.5709 44.7837H37.0701C36.6653 44.7837 36.3374 44.4558 36.3374 44.051C36.3374 43.6461 36.6653 43.3182 37.0701 43.3182H43.5709C43.9757 43.3182 44.3036 43.6461 44.3036 44.051C44.3036 44.4558 43.9757 44.7837 43.5709 44.7837Z" fill="#FCAB28" />
                                            <path d="M43.2044 45.7079H37.4364C37.0777 45.7079 36.7861 45.4171 36.7861 45.0577C36.7861 44.6983 37.077 44.4075 37.4364 44.4075H43.2044C43.5631 44.4075 43.8546 44.6983 43.8546 45.0577C43.8546 45.4171 43.5638 45.7079 43.2044 45.7079Z" fill="#FCAB28" />
                                            <path d="M50.5854 55.771H30.6048C29.9279 55.771 29.3792 56.3197 29.3792 56.9966V65.4411C29.3792 66.118 29.9279 66.6667 30.6048 66.6667H50.5854C51.2623 66.6667 51.811 66.118 51.811 65.4411V56.9966C51.811 56.3197 51.2623 55.771 50.5854 55.771Z" fill="#6C554F" />
                                            <path d="M47.2147 57.6566H33.9754C33.6619 57.6566 33.4077 57.9108 33.4077 58.2243V62.8388C33.4077 63.1523 33.6619 63.4065 33.9754 63.4065H47.2147C47.5283 63.4065 47.7824 63.1523 47.7824 62.8388V58.2243C47.7824 57.9108 47.5283 57.6566 47.2147 57.6566Z" fill="#FCAB28" />
                                            <path d="M35.8008 57.6566H40.3544C40.4621 57.6566 40.5495 57.9111 40.5495 58.2243V62.8388C40.5495 63.152 40.4621 63.4065 40.3544 63.4065H35.8008C35.6931 63.4065 35.6057 63.152 35.6057 62.8388V58.2243C35.6057 57.9111 35.6931 57.6566 35.8008 57.6566Z" fill="#FFD733" />
                                            <path d="M36.9788 43.5014C37.5283 43.4623 38.0771 43.4434 38.6267 43.428C39.1762 43.4105 39.7251 43.4084 40.2746 43.4056C40.8241 43.4077 41.373 43.4105 41.9225 43.4273L42.7468 43.4539C43.0216 43.47 43.2964 43.4791 43.5711 43.5007C43.2964 43.5224 43.0216 43.5315 42.7468 43.5476L41.9225 43.5741C41.373 43.5916 40.8241 43.5937 40.2746 43.5958C39.7251 43.5937 39.1762 43.5909 38.6267 43.5734C38.0771 43.5581 37.5283 43.5399 36.9788 43.5V43.5014Z" fill="#FFE266" />
                                            <path d="M37.0093 44.6005C37.5686 44.5614 38.1286 44.5425 38.688 44.5271C39.2473 44.5097 39.8073 44.5069 40.3666 44.5048C40.926 44.5069 41.486 44.5097 42.0453 44.5264L42.8843 44.553C43.164 44.5691 43.4436 44.5782 43.7233 44.5998C43.4436 44.6215 43.164 44.6306 42.8843 44.6467L42.0453 44.6733C41.486 44.6907 40.926 44.6928 40.3666 44.6949C39.8073 44.6928 39.2473 44.69 38.688 44.6726C38.1286 44.6572 37.5686 44.639 37.0093 44.5991V44.6005Z" fill="#FFE266" />
                                            <path d="M38.5073 45.8213C39.0967 45.7464 39.6854 45.729 40.2748 45.7255C40.8642 45.7283 41.4529 45.745 42.0423 45.8213C41.4529 45.8968 40.8642 45.9142 40.2748 45.917C39.6854 45.9142 39.0967 45.8968 38.5073 45.8213Z" fill="#FFE266" />
                                            <path d="M31.2412 54.9775C32.7465 54.9033 34.2525 54.8537 35.7585 54.8285C37.2638 54.7978 38.7698 54.7901 40.2751 54.7859C41.7803 54.7894 43.2863 54.7971 44.7923 54.8278C46.2976 54.853 47.8036 54.9026 49.3096 54.9768C47.8036 55.0509 46.2983 55.1012 44.7923 55.1257C43.2863 55.1557 41.781 55.1641 40.2751 55.1676C38.7691 55.1641 37.2638 55.1557 35.7585 55.125C34.2525 55.0998 32.7472 55.0502 31.2412 54.9761V54.9775Z" fill="#FCAB28" />
                                            <g opacity="0.48">
                                              <path d="M35.3736 14.2031C33.8355 14.3527 32.3442 14.5604 31.0374 14.7967C30.8857 19.3182 30.8382 26.8733 31.9435 31.0501C33.5915 37.2761 36.5216 40.3895 36.5216 40.3895C36.5216 40.3895 34.6905 31.2333 34.8737 23.1761C34.9611 19.3175 35.1751 16.302 35.3736 14.2038V14.2031Z" fill="#FFFFFD" />
                                            </g>
                                            <path d="M53.917 19.4216C52.9026 18.8413 51.7783 18.5092 50.6492 18.2512C49.5179 17.9918 48.3692 17.8107 47.2163 17.6702C46.0627 17.5353 44.9049 17.4283 43.745 17.3591C42.5851 17.2871 41.4231 17.2395 40.2625 17.2346C39.1025 17.2234 37.9405 17.2633 36.7806 17.3276C35.62 17.3877 34.4622 17.4905 33.3086 17.6241C32.1564 17.7639 31.0063 17.9387 29.8764 18.2016C28.7494 18.463 27.6237 18.8014 26.6323 19.4216C27.1071 19.0853 27.6202 18.7902 28.1677 18.5826C28.3033 18.5281 28.4376 18.4686 28.5753 18.4176L28.9913 18.2764C29.2675 18.1771 29.5492 18.0974 29.831 18.0191C30.9608 17.7107 32.1137 17.4947 33.2708 17.3276C35.5879 16.9983 37.9245 16.8487 40.2659 16.8515C42.6053 16.878 44.9412 17.0417 47.2568 17.3745C48.414 17.5422 49.5655 17.7646 50.6953 18.0694C50.9792 18.14 51.2574 18.233 51.5357 18.3225C51.8161 18.405 52.0887 18.5141 52.3614 18.6204C52.6376 18.7168 52.8984 18.8532 53.1627 18.9776C53.2969 19.0364 53.4213 19.114 53.5451 19.1916L53.9177 19.4209L53.917 19.4216Z" fill="#FCAB28" />
                                            <g opacity="0.35">
                                              <path d="M31.5542 66.666H34.9472L43.4217 55.771H39.5225L31.5542 66.666Z" fill="white" />
                                            </g>
                                            <g opacity="0.35">
                                              <path d="M39.9322 66.666L48.3368 55.771H46.7435L38.2515 66.666H39.9322Z" fill="white" />
                                            </g>
                                            <path d="M38.6553 13.3928L39.8376 17.5962L37.3884 24.8192L33.6724 13.3928H38.6553Z" fill="#CD3414" />
                                            <path d="M37.9094 13.3928L39.3847 18.933L39.5755 18.3694L38.2506 13.3928H37.9094Z" fill="#FFCE45" />
                                            <path d="M35.3618 26.5929V24.1437C35.3618 23.8172 35.6268 23.5522 35.9533 23.5522H42.6254C42.9519 23.5522 43.2168 23.8172 43.2168 24.1437V26.6775H41.8654V25.4106C41.8654 25.0841 41.6004 24.8191 41.2739 24.8191H37.3886C37.0621 24.8191 36.7972 25.0841 36.7972 25.4106V26.2552L35.3611 26.5929H35.3618Z" fill="#FCAB28" />
                                            <path d="M37.7075 23.8788L37.8837 23.36L34.6479 13.3928H34.304L37.7075 23.8788Z" fill="#FFCE45" />
                                            <path d="M44.1444 37.1492C46.8491 34.4444 46.8491 30.0592 44.1444 27.3545C41.4397 24.6498 37.0545 24.6498 34.3497 27.3545C31.645 30.0592 31.645 34.4444 34.3497 37.1492C37.0545 39.8539 41.4397 39.8539 44.1444 37.1492Z" fill="#FCAB28" />
                                            <path d="M32.3215 32.2519C32.3215 32.6288 32.3523 32.9986 32.4103 33.3594C33.4157 33.9285 34.5777 34.2529 35.8152 34.2529C39.6403 34.2529 42.7411 31.1522 42.7411 27.327C42.7411 26.9502 42.7104 26.5803 42.6523 26.2196C41.6469 25.6505 40.4849 25.326 39.2474 25.326C35.4223 25.326 32.3215 28.4268 32.3215 32.2519Z" fill="#FFCE45" />
                                            <path d="M37.3884 24.8192H41.696L46.595 13.3334H41.1891L37.3884 24.8192Z" fill="#FE5629" />
                                            <path d="M39.2471 37.2712C36.4798 37.2712 34.2278 35.0199 34.2278 32.2519C34.2278 29.484 36.4791 27.2327 39.2471 27.2327C42.015 27.2327 44.2663 29.484 44.2663 32.2519C44.2663 35.0199 42.015 37.2712 39.2471 37.2712ZM39.2471 27.4019C36.5728 27.4019 34.397 29.5776 34.397 32.2519C34.397 34.9262 36.5728 37.102 39.2471 37.102C41.9213 37.102 44.0971 34.9262 44.0971 32.2519C44.0971 29.5776 41.9213 27.4019 39.2471 27.4019Z" fill="#FCAB28" />
                                            <path d="M33.8845 29.465C33.8845 29.465 33.3357 28.3247 34.518 27.7759C34.518 27.7759 34.6026 28.9582 33.8845 29.465Z" fill="#FFE266" />
                                            <path d="M33.9259 29.9293C33.9259 29.9293 33.8413 29.0847 35.1927 28.8735C35.1927 28.8735 34.9816 29.6762 33.9259 29.9293Z" fill="#FFE266" />
                                            <path d="M34.867 28.2303C34.867 28.2303 34.5671 27.0011 35.8381 26.7096C35.8381 26.7096 35.6752 27.8835 34.867 28.2303Z" fill="#FFE266" />
                                            <path d="M34.8123 28.6938C34.8123 28.6938 34.9052 27.8499 36.2707 27.9247C36.2707 27.9247 35.8974 28.6658 34.8123 28.6938Z" fill="#FFE266" />
                                            <path d="M36.0144 27.2439C36.0144 27.2439 35.9528 25.9798 37.2554 25.9351C37.2554 25.9351 36.8729 27.0572 36.0144 27.2439Z" fill="#FFE266" />
                                            <path d="M35.8728 27.6878C35.8728 27.6878 36.1238 26.8768 37.4508 27.2089C37.4508 27.2089 36.9439 27.8654 35.8728 27.6878Z" fill="#FFE266" />
                                            <path d="M37.302 26.4894C37.302 26.4894 37.618 25.2638 38.8758 25.6071C38.8758 25.6071 38.1774 26.5649 37.302 26.4894Z" fill="#FFE266" />
                                            <path d="M37.0349 26.8719C37.0349 26.8719 37.5152 26.1721 38.6842 26.8824C38.6842 26.8824 38.0053 27.3592 37.0349 26.8719Z" fill="#FFE266" />
                                            <path d="M38.7939 26.1873C38.7939 26.1873 39.4658 25.1148 40.5607 25.8231C40.5607 25.8231 39.605 26.525 38.7939 26.1873Z" fill="#FFE266" />
                                            <path d="M38.4236 26.4705C38.4236 26.4705 39.0934 25.9489 39.9918 26.9795C39.9918 26.9795 39.2004 27.2284 38.4236 26.4705Z" fill="#FFE266" />
                                            <path d="M40.3577 26.3104C40.3577 26.3104 41.3071 25.4742 42.1552 26.4642C42.1552 26.4642 41.0393 26.8648 40.3577 26.3104Z" fill="#FFE266" />
                                            <path d="M39.9214 26.4768C39.9214 26.4768 40.7121 26.1678 41.2799 27.4116C41.2799 27.4116 40.45 27.4249 39.9214 26.4768Z" fill="#FFE266" />
                                            <path d="M41.8118 26.9244C41.8118 26.9244 42.9143 26.3035 43.538 27.4487C43.538 27.4487 42.3634 27.6081 41.8118 26.9244Z" fill="#FFE266" />
                                            <path d="M41.3508 26.9964C41.3508 26.9964 42.1884 26.858 42.4856 28.1934C42.4856 28.1934 41.671 28.0339 41.3508 26.9964Z" fill="#FFE266" />
                                            <path d="M43.071 27.7632C43.071 27.7632 44.2715 27.3626 44.6665 28.605C44.6665 28.605 43.4829 28.5393 43.071 27.7632Z" fill="#FFE266" />
                                            <path d="M42.6047 27.7458C42.6047 27.7458 43.4535 27.7689 43.492 29.1358C43.492 29.1358 42.7229 28.8247 42.6047 27.7458Z" fill="#FFE266" />
                                            <path d="M44.145 28.7994C44.145 28.7994 45.4105 28.7728 45.4189 30.0767C45.4189 30.0767 44.3079 29.6628 44.145 28.7994Z" fill="#FFE266" />
                                            <path d="M43.7044 28.6449C43.7044 28.6449 44.5077 28.9183 44.1393 30.2355C44.1393 30.2355 43.4968 29.7104 43.7044 28.6449Z" fill="#FFE266" />
                                            <path d="M44.8389 30.1538C44.8389 30.1538 46.0526 30.5118 45.6667 31.757C45.6667 31.757 44.7333 31.0264 44.8389 30.1538Z" fill="#FFE266" />
                                            <path d="M44.4658 29.8734C44.4658 29.8734 45.1489 30.3775 44.3987 31.5213C44.3987 31.5213 43.9456 30.8264 44.4658 29.8734Z" fill="#FFE266" />
                                            <path d="M45.2503 31.6024C45.2503 31.6024 46.3242 32.2722 45.6188 33.3685C45.6188 33.3685 44.9147 32.4148 45.2503 31.6024Z" fill="#FFE266" />
                                            <path d="M44.9666 31.2327C44.9666 31.2327 45.4895 31.9011 44.4611 32.8023C44.4611 32.8023 44.2101 32.0115 44.9666 31.2327Z" fill="#FFE266" />
                                            <path d="M45.0795 33.1714C45.0795 33.1714 45.9905 34.0495 45.0725 34.9752C45.0725 34.9752 44.5823 33.8957 45.0795 33.1714Z" fill="#FFE266" />
                                            <path d="M44.8779 32.7505C44.8779 32.7505 45.2505 33.5133 44.0571 34.181C44.0571 34.181 43.976 33.3553 44.8779 32.7505Z" fill="#FFE266" />
                                            <path d="M44.6379 34.6194C44.6379 34.6194 45.3658 35.6541 44.289 36.3897C44.289 36.3897 44.0122 35.2367 44.6379 34.6194Z" fill="#FFE266" />
                                            <path d="M44.52 34.1676C44.52 34.1676 44.7417 34.987 43.4426 35.4163C43.4426 35.4163 43.5195 34.5899 44.52 34.1676Z" fill="#FFE266" />
                                            <path d="M43.9536 35.9448C43.9536 35.9448 44.3416 37.1495 43.0957 37.5319C43.0957 37.5319 43.1733 36.3489 43.9536 35.9448Z" fill="#FFE266" />
                                            <path d="M43.9745 35.4785C43.9745 35.4785 43.943 36.3266 42.5754 36.3518C42.5754 36.3518 42.8943 35.5855 43.9745 35.4785Z" fill="#FFE266" />
                                            <path d="M42.8547 36.9985C42.8547 36.9985 42.8603 38.264 41.5564 38.2514C41.5564 38.2514 41.9885 37.1475 42.8547 36.9985Z" fill="#FFE266" />
                                            <path d="M43.016 36.5608C43.016 36.5608 42.7294 37.36 41.4185 36.9698C41.4185 36.9698 41.954 36.3364 43.016 36.5608Z" fill="#FFE266" />
                                            <path d="M41.7524 37.8194C41.7524 37.8194 41.4678 39.0527 40.2017 38.7415C40.2017 38.7415 40.875 37.7662 41.7524 37.8194Z" fill="#FFE266" />
                                            <path d="M42.0097 37.4307C42.0097 37.4307 41.5476 38.1424 40.3611 37.4628C40.3611 37.4628 41.0274 36.9685 42.0097 37.4307Z" fill="#FFE266" />
                                            <path d="M40.2124 38.1682C40.2124 38.1682 39.6776 39.3155 38.5044 38.7478C38.5044 38.7478 39.3658 37.9332 40.2124 38.1682Z" fill="#FFE266" />
                                            <path d="M40.5455 37.841C40.5455 37.841 39.9456 38.4416 38.9263 37.5292C38.9263 37.5292 39.6807 37.1845 40.5455 37.841Z" fill="#FFE266" />
                                            <path d="M38.7006 38.2206C38.7006 38.2206 37.9581 39.2456 36.9136 38.466C36.9136 38.466 37.9141 37.8298 38.7006 38.2206Z" fill="#FFE266" />
                                            <path d="M39.0893 37.9626C39.0893 37.9626 38.3866 38.438 37.5581 37.3494C37.5581 37.3494 38.3642 37.1537 39.0893 37.9626Z" fill="#FFE266" />
                                            <path d="M37.2249 38.0039C37.2249 38.0039 36.2118 38.7625 35.4448 37.7082C35.4448 37.7082 36.5887 37.397 37.2249 38.0039Z" fill="#FFE266" />
                                            <path d="M37.6723 37.8731C37.6723 37.8731 36.8598 38.1193 36.3921 36.8335C36.3921 36.8335 37.2199 36.8859 37.6723 37.8731Z" fill="#FFE266" />
                                            <path d="M35.8708 37.3075C35.8708 37.3075 34.676 37.7242 34.2642 36.4874C34.2642 36.4874 35.4485 36.5371 35.8708 37.3075Z" fill="#FFE266" />
                                            <path d="M36.3375 37.318C36.3375 37.318 35.4887 37.3061 35.4314 35.9399C35.4314 35.9399 36.2047 36.2406 36.3375 37.318Z" fill="#FFE266" />
                                            <path d="M34.8033 36.4503C34.8033 36.4503 33.5435 36.5678 33.4407 35.2681C33.4407 35.2681 34.5782 35.6009 34.8033 36.4503Z" fill="#FFE266" />
                                            <path d="M35.2533 36.5727C35.2533 36.5727 34.4325 36.3574 34.7052 35.0171C34.7052 35.0171 35.384 35.4946 35.2533 36.5727Z" fill="#FFE266" />
                                            <path d="M33.9892 35.0983C33.9892 35.0983 32.7321 34.9514 32.902 33.6587C32.902 33.6587 33.9459 34.2208 33.9892 35.0983Z" fill="#FFE266" />
                                            <path d="M34.4039 35.3115C34.4039 35.3115 33.6453 34.9304 34.1906 33.6761C34.1906 33.6761 34.7549 34.2844 34.4039 35.3115Z" fill="#FFE266" />
                                            <path d="M33.4639 33.6797C33.4639 33.6797 32.2578 33.2972 32.6689 32.0604C32.6689 32.0604 33.5876 32.8099 33.4639 33.6797Z" fill="#FFE266" />
                                            <path d="M33.8305 33.967C33.8305 33.967 33.1579 33.4489 33.9312 32.3212C33.9312 32.3212 34.3703 33.0252 33.8305 33.967Z" fill="#FFE266" />
                                            <path d="M33.2054 32.21C33.2054 32.21 32.1671 31.4871 32.9264 30.4279C32.9264 30.4279 33.5815 31.4158 33.2054 32.21Z" fill="#FFE266" />
                                            <path d="M33.4714 32.5939C33.4714 32.5939 32.9827 31.8996 34.0559 31.0515C34.0559 31.0515 34.2663 31.8542 33.4714 32.5939Z" fill="#FFE266" />
                                            <path d="M33.4415 30.7061C33.4415 30.7061 32.6703 29.7028 33.7155 28.9232C33.7155 28.9232 34.0406 30.0635 33.4415 30.7061Z" fill="#FFE266" />
                                            <path d="M33.5775 31.1521C33.5775 31.1521 33.3216 30.3425 34.6011 29.8594C34.6011 29.8594 34.5592 30.6879 33.5775 31.1521Z" fill="#FFE266" />
                                            <path d="M37.8638 24.8192H38.2099L42.3286 13.3334H41.9825L37.8638 24.8192Z" fill="#FFCE45" />
                                            <path d="M40.8416 24.8192H41.1904L45.9993 13.3334H45.6497L40.8416 24.8192Z" fill="#FFCE45" />
                                          </svg>
                                          <Link to={`/organizer/${sportId}/events/${type}/${gameId}/${item.id}`} className="tournament_card_redesign">
                                            <div className='card-content '>
                                              <EnabledStatus status={item.enabled == 1} id={item.id} />
                                              <div> <h6>{item.season_name}</h6>
                                                <div className='season-date'>
                                                  <p><strong>Date:</strong> {moment(item.start_date).format('MM-DD-YYYY')} to {moment(item.end_date).format('MM-DD-YYYY')}</p>
                                                </div>
                                              </div>
                                            </div>
                                          </Link>
                                        </div>
                                        <div className='l-status justify-content-end'>
                                          <p className='d-flex mb-0 bg-transparent text-dark p-0 me-0'>
                                          </p>
                                        </div>

                                        <div className='groups-count'>
                                          <a className=''>
                                            <div className='group total-groups '>
                                              <p>Groups :</p>
                                              <strong className='justify-content-end'>{item.groupCount || 0}</strong>
                                            </div>
                                          </a>

                                          <a className=''>
                                            <div className='group total-groups'>
                                              <p>Teams :</p>
                                              <strong className='justify-content-end'>{item.teamCount || 0}</strong>
                                            </div>
                                          </a>
                                          <a className=''>
                                            <div className='group total-groups'>
                                              <p>Player :</p>
                                              <strong className='justify-content-end'>{item.playerCount || 0}</strong>
                                            </div>
                                          </a>

                                        </div>
                                      </div>
                                    </Link>
                                    <div className="dropdown acts">
                                      <button className=" dropdown-toggle actions" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                        <img className='dots' src={optionico} />
                                      </button>
                                      <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                        <a className="dropdown-item" onClick={(e) => openModal(e, <CreateSeason values={item} isUpdate={true} />)}><li><img src={edit} />Edit</li></a>
                                        <a className="dropdown-item"><li onClick={() => handleShow(item.id, total)}><img src={'https://cdn.icon-icons.com/icons2/1863/PNG/512/publish_118757.png'} /> Publish</li> </a>
                                        <a className="dropdown-item"> <li onClick={() => openDeleteModal(item.id, item.season_name, 'Archive')}><img src='https://cdn.icon-icons.com/icons2/1744/PNG/512/3643772-archive-archives-document-folder-open_113445.png' alt='Archive icon' />Archive</li></a>
                                        <a className="dropdown-item"> <li onClick={() => openDeleteModal(item.id, item.season_name, "Delete")}><img src={deletes} alt='Delete icon' />Delete</li></a>
                                      </ul>
                                    </div>


                                  </div>
                                </div>
                              </div>
                            })}
                          </> : <><div className='col-xxl-3 col-xl-3 col-lg-4 col-md-6 col=sm-12'>
                            <div className='l-card create-league'>
                              <div className='wrap'>
                                <span> No Data Found</span>
                              </div>
                            </div>

                          </div></>
                        }</>
                  }
                </div>

              </> : <>{
                tab == 'dashboard' ? <>
                  <SeasonDashboard
                    seasons={seasons}
                    updateSeason={() => <CreateSeason values={selectedSeason} isUpdate={true} />}
                    createSeason={() => <CreateSeason isUpdate={false} />}
                    openModal={openModal}
                    openDeleteSeasonModal={openDeleteModal}
                    tabActive={tabActive}
                    season={selectedSeason}
                    setSelectedSeason={setSelectedSeason}
                    handlePublish={handlePublish}
                  />
                </> : <></>
              }</>}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const Seasons = withLoader(SeasonsWithLoader);


export default Seasons;

import React, { useState, useEffect } from "react";
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import boy from '../../../assets/images/people.png'
import {
    errorMessageNodify,
    successMessageNodify,
} from "../../../helpers/notify";
import 'react-confirm-alert/src/react-confirm-alert.css';
import noimage from '../../../assets/images/no-image.png'
import { useParams } from "react-router";
import {EnrollSideNav, ApproveButton, ReminderButton, RejectButton} from '../../../components/Enrollment/enrollSideNav';
import { generateImage } from "../../../helpers/helperfunctions";
import NoData from '../../../components/NoData';
import Loader from "../../Mainloader.js/Loader";




const EnrollTeam = ({ groupid, getGroupList, AddedTeams, tabActive, price, setTeamId }) => {
    const [values, setValues] = useState({});
    const [teamList, setTeamList] = useState([]);
    const [coachDetail, setcoachDetail] = useState('');
    const [isChecked1, setIsChecked1] = useState(false);
    const [isChecked2, setIsChecked2] = useState(false);
    const [seleTeamList, setSelectedTeamList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [Enrollloading, setEnrollloading] = useState(false);
    const [section, setSection] = useState(1)
    const [enrollmentList, setEnrollmentList] = useState(null)
    const { sportId, gameId, seasonId, groupId } = useParams();
    const [show, setShow] = useState(false);
    const [refresh, setRefresh] = useState(false)
    useEffect(() => {
        ApiGetAuth(`games/enrollment-list/${gameId}/${seasonId}/${groupId}`).then(
            (reponse) => {
                if (reponse.status === true) {
                    setEnrollmentList(reponse.data)
                    return;
                } else {
                    errorMessageNodify(reponse.msg);
                    return;
                }
            },
            (err) => {
                errorMessageNodify(err.msg);
                setLoading(false);
                return;
            }
        );
        getGroupList();
    }, [refresh])

useEffect(()=>{
    if(!show){
        setSelectedTeamList([])
        setTeamList([]);
        setcoachDetail({});
        setIsChecked1(false)
    }
},[show])


    const handleSubmit = (e) => {
        e.preventDefault();
        const { email } = values;
        if (!email) return errorMessageNodify("Please enter coach email first!");
        setLoading(true);
    
        ApiGetAuth(`teams/get-teams-email/${email.trim()}`).then(
            (reponse) => {
                if (reponse.status === true) {
                    setTeamList(reponse?.data[0]['teams']);
                    setcoachDetail(reponse?.data[0]['users']);
                    setLoading(false);
                    return;
                } else {
                    errorMessageNodify(reponse.msg);
                    setLoading(false);
                    return;
                }
            },
            (err) => {
                errorMessageNodify(err.msg);
                setLoading(false);
                return;
            }
        );
    };

    const handleEnrollTeam = () => {
        let value = {};
        value.data = JSON.stringify(seleTeamList);
        value.groupid = groupid
        value.coachid = coachDetail.id
        setEnrollloading(true);
        Apipost("/games/enroll-team", value, "application/x-www-form-urlencoded", true).then(
            (success) => {
                setSection(1);
                setRefresh(prev => !prev)
                setEnrollloading(false);
                setSelectedTeamList([])
                setTeamList([]);
                setIsChecked1(false)
                setShow(false)
                setcoachDetail({});
                successMessageNodify(success.data.msg);
            },
            (err) => {
                errorMessageNodify(err.response?.data.message);
                setSelectedTeamList([]);
                setEnrollloading(false);
                return;
            }
        );

    };


    const handleChecked1 = e => {
        setIsChecked1(e.target.checked);
    };

    const handleChecked2 = e => {
        setIsChecked2(e.target.checked);
    };

    const handleTeamList = (e) => {
        const teamId = e.target.value;
        if (e.target.checked) {
            setSelectedTeamList((prev) => [...prev, teamId]);
        } else {
            const list = seleTeamList.filter((item) => item !== teamId);
            setSelectedTeamList(list);
        }
    };
    return (
        <> 
      

            <div className="enrollment_list_heading_with_button">
                <div className="d-flex justify-content-between align-items-center">
                    <h3 className="pop-title ">Enrollment  List</h3>
                    <div className="enroll-team my-3">
                        {['end'].map((placement, idx) => (
                            <EnrollSideNav key={idx} show={show} setShow={setShow} isChecked1={isChecked1} isChecked2={isChecked2} enrollmentList={enrollmentList} handleTeamList={handleTeamList} handleChecked1={handleChecked1} handleChecked2={handleChecked2} handleEnrollTeam={handleEnrollTeam} seleTeamList={seleTeamList} Enrollloading={Enrollloading}  placement={placement} setValues={setValues} handleSubmit={handleSubmit}  loading={loading} teamList={teamList} coachDetail={coachDetail}  AddedTeams={AddedTeams}/>
                        ))}
                    </div>
                </div>
            </div>
            <div className="custom-pop-box table-pop-up enroll-wrap">
                <div id="add-user-form">
                {enrollmentList == null ? <><Loader/></>:<> 
                {enrollmentList.length >0?<> <table className="table standing-table table-striped  score-past enrollment_table_page">
                        <thead>
                            <tr>
                                <th scope="col" className="enrollment_table-th">Teams</th>
                                <th scope="col" className="enrollment_table-th">Coach</th>
                                <th scope="col" className="approved-btns enrollment_table-th">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {enrollmentList?.map((item) => {
                                return <tr>
                                    <td>
                                        <div className="coach_image_detail">
                                            <img src={item.team_logo || generateImage(item.team_name)} width="50px" alt="" className="mx-2" />
                                            <div className="coach_detail">
                                                <p onClick={() => { tabActive('teamDetails'); setTeamId(item.team_id) }} style={{ cursor: "pointer" }}>{item.team_name} </p>
                                                <p className="player_font">Players : <span className="bold">{item.playerCount}</span></p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <div className="coach_image_detail">
                                            <img src={item.profile_pic || generateImage(item.firstname)} width="50px" alt="" className="mx-2" />
                                            <div className="coach_detail">
                                                <p>{item.firstname}</p>
                                                <p className="player_font">{item.email}</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td width="3%" className="approved-btns" > 
                                        {item.status == "Pending" ? <>
                                            {['end'].map((placement, idx) => (
                                                <ApproveButton key={idx} placement={placement} team_id={item.team_id}  email={item.email} enrollment_id={item.id} setRefresh={setRefresh} />
                                            ))}

                                            {['end'].map((placement, idx) => (
                                                <ReminderButton key={idx} placement={placement} team_id={item.team_id} email={item.email} enrollment_id={item.id}  setRefresh={setRefresh}/>
                                            ))}
                                            {['end'].map((placement, idx) => (
                                                <RejectButton key={idx} placement={placement} team_id={item.team_id}  email={item.email} enrollment_id={item.id}  setRefresh={setRefresh}/>
                                            ))}
                                            </> : <>
                                         <p className={`${item.status == "Successful"?"text-success":"text-danger"}`}> {item.status}</p>  
                                        </>

                                        }
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table></>:<><div className="No-game"> <NoData message='No Team`s Enrolled Yet! ' helpText='Enroll Team through clicking the button above Enroll Team !' /></div></>}
                </>}
                  
                    
                </div>
            </div>

         
         

        </>
    );
};

export default EnrollTeam;
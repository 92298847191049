import { useEffect, useState } from "react";
import { ApiGetAuth, Apipost } from "../../helpers/apiCall";
import { useLocation, useNavigate, useParams } from "react-router";
import moment from "moment";
import CustomModal from "../customModal";
import TabSlideNav from "../Teams/tabSlideNav";
import { Accordion, Button, Card, Offcanvas, Tab, Table, Tabs } from "react-bootstrap";
import { convertTo12HourFormat } from "../../helpers/helperfunctions";
import { errorMessageNodify, successMessageNodify } from "../../helpers/notify";
import DeletePopup from "../DeletePopup";
import FileUpload from '../../components/FileUpload';
import NoData from '../../components/NoData';
import { usePDF } from "react-to-pdf";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Form } from 'react-bootstrap';
import GIU_Logo from '../../assets/images/GIU_logo.png';
import EventSchedule from '../VenueTime';
// import PlayModal from '../../../components/PlayVideoModal/playVideoModal';
import { NavLink } from "react-router-dom";



function CommonOffCanvas({ show, handleClose, title, children, buttonText, onButtonClick, buttonIcon, padding }) {
  return (
    <>
      <Button variant="primary" onClick={onButtonClick} className=" mx-2">
        {buttonIcon && <span className="mr-3">{buttonIcon}</span>}
        {buttonText}
      </Button>

      <Offcanvas show={show} onHide={handleClose} placement="end" className="p-0 import-doctument-offcanvacs">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {children}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

const CommonReport = ({ successData, failedData }) => {
  const [openIndex, setOpenIndex] = useState(null); // State for controlling open accordion item
  const renderReportContent = (reportData, reportType) => {
    return (
      reportData?.length === 0 ? (
        <p className=" d-flex justify-content-center align-items-center " style={{ height: '300px' }}>No {reportType} imports.</p>
      ) : (
        reportData.map((item, index) => (
          <Card key={index}>
            <Card.Header>

              <div className="d-flex justify-content-between align-items-center">

                <div className="d-flex justify-content-center  align-items-center w-100">

                  <div>
                    <span className="text-center" >{item.Date}</span>
                    <div className="text-center">{item.Team1}</div>
                    <div className="text-center">vs</div>
                    <div className="text-center">{item.Team2}</div>
                  </div>
                </div>

                <button
                  className="btn btn-link"
                  onClick={() => setOpenIndex(openIndex === index ? null : index)}
                  aria-controls={`report-collapse-${index}`}
                  aria-expanded={openIndex === index}
                >
                  {openIndex === index ? (
                    <>
                      {/* Hide Details */}
                      <svg
                        className="svg-icon"
                        style={{
                          width: '16px',
                          height: '16px',
                          verticalAlign: 'middle',
                          fill: '#f1582a',
                          overflow: 'hidden',
                        }}
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M948.560332 281.179984c-13.765515-13.833053-36.127825-13.833053-49.89334 0L511.991302 668.591431 125.313565 281.179984c-13.763468-13.798261-36.093033-13.798261-49.856501 0-13.799284 13.798261-13.799284 36.161594 0 49.993624l410.857439 411.674037c7.067976 7.085372 16.402575 10.521634 25.675776 10.331299 9.274224 0.191358 18.608823-3.245927 25.677822-10.331299l410.891208-411.708829c6.863315-6.89913 10.331299-15.940041 10.331299-24.979928S955.423647 288.078091 948.560332 281.179984z" />
                      </svg>
                    </>
                  ) : (
                    <>
                      {/* Show Details */}
                      <svg
                        className="svg-icon"
                        style={{
                          width: '16px',
                          height: '16px',
                          verticalAlign: 'middle',
                          fill: '#f1582a',
                          overflow: 'hidden',
                        }}
                        viewBox="0 0 1024 1024"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M595.7 511.1L240.8 866l93.2 93.1 447.4-447.4-0.6-0.6 0.6-0.5L334 63.1l-93.2 93.2z" />
                      </svg>

                    </>
                  )}
                </button>
              </div>
            </Card.Header>

            <Accordion.Collapse in={openIndex === index} id={`report-collapse-${index}`}>
              <Card.Body>
                <div className="card-content-box d-flex justify-content-between align-item-center" >

                  <div>
                    <p><strong>Date:</strong> {item.Date}</p>
                    <p><strong>Time:</strong> {item.Time}</p>
                    <p><strong>Venue:</strong> {item.Venue}</p>
                    <p><strong>Field:</strong> {item.Field}</p>
                  </div>
                  <div>
                    <p><strong>Division:</strong> {item.Division}</p>
                    <p><strong>Team 1:</strong> {item.Team1}</p>
                    <p><strong>Team 2:</strong> {item.Team2}</p>
                  </div>
                </div>
                {item?.reason && <p><strong>Reason:</strong> {item.reason}</p>}
              </Card.Body>
            </Accordion.Collapse>
          </Card>
        ))
      )
    );
  };
  return (
    <>

      <div className="my-2 ">
        {(successData.length || failedData.length) > 0 ? (
          <div className="report-container">
            {/* Tabs for Report Type */}
            <div className=" border my-2 mt-5" style={{ height: '420px', overflow: 'auto' }}>
              <Tabs
                defaultActiveKey="success"
                id="report-tabs"
                className="mb-3  justify-content-around border-0 games-table-venues"
                onSelect={() => setOpenIndex(null)} // Close all accordion items when switching tabs
              >

                <Tab eventKey="success" title={'Success (' + successData.length + ')'} className=" border-0">
                  <Accordion>
                    {renderReportContent(successData, 'Success ')}
                  </Accordion>
                </Tab>

                <Tab eventKey="fail" title={'failed (' + failedData.length + ')'} className=" border-0">
                  <Accordion>
                    {renderReportContent(failedData, 'Failed')}
                  </Accordion>
                </Tab>
              </Tabs>
            </div>
          </div>
        ) : null}

      </div>

    </>
  );
};

function ImportTimeslots({ venueList, setRefresh }) {
  const [data, setData] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleFileUploaded = (jsonArray) => {
    setData(jsonArray);
  };

  const groupByVenueAndDate = (data) => {
    const result = {};
    data.forEach((entry) => {
      const key = `${entry.Venue}_${entry.Date}`;
      if (!result[key]) {
        result[key] = {
          Venue: entry.Venue,
          Date: entry.Date,
          Duration: entry.Duration,
          slots: [],
        };
      }
      result[key].slots.push({ Time: entry.Time, Field: entry.Field });
    });
    return Object.values(result);
  };

  const handleClose = () => {
    setShowOffcanvas(false);
    setData([]);
  };

  return (
    <CommonOffCanvas
      show={showOffcanvas}
      handleClose={handleClose}
      title="Venue Time Slots Schedule"
      buttonText="Import Time Slots"
      buttonIcon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
        </svg>
      }
      onButtonClick={() => setShowOffcanvas(true)}
    >
      <div >
        <FileUpload onFileUploaded={handleFileUploaded} requiredKeys={['Date', 'Venue', 'Field', 'Time', 'Duration']} text='Import TimeSlots ' sampleFileUrl='https://teamconnect-media.nyc3.cdn.digitaloceanspaces.com/sample-data/GIU_Venue_Timeslots_Sample.csv' />
        {data.length > 0 && (
          <EventSchedule eventData={groupByVenueAndDate(data)} venueList={venueList} setRefresh={setRefresh} />
        )}
      </div>
    </CommonOffCanvas>
  );
}

function ImportGamesData({ setRefresh }) {
  const [data, setData] = useState([]);
  const [showOffcanvas, setShowOffcanvas] = useState(false);

  const handleFileUploaded = (jsonArray) => {
    setData(jsonArray);
  };

  const handleClose = () => {
    setShowOffcanvas(false);
    setData([]);
  };

  const formatScheduleData = (data) => {
    const formattedData = {};
    data.forEach((match) => {
      const { Date, Division } = match;
      if (!formattedData[Date]) formattedData[Date] = {};
      if (!formattedData[Date][Division]) {
        formattedData[Date][Division] = { matches: [], count: 0 };
      }
      formattedData[Date][Division].matches.push(match);
      formattedData[Date][Division].count += 1;
    });
    return formattedData;
  };

  return (
    <CommonOffCanvas
      show={showOffcanvas}
      handleClose={handleClose}
      title="Games Schedule"
      buttonText="Import Games"
      buttonIcon={
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-upload" viewBox="0 0 16 16">
          <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5" />
          <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708z" />
        </svg>
      }
      onButtonClick={() => setShowOffcanvas(true)}
    >
      <FileUpload onFileUploaded={handleFileUploaded} requiredKeys={['Date', 'Venue', 'Field', 'Time', 'Division', 'Team1', 'Team2', 'Duration']} sampleFileUrl='https://teamconnect-media.nyc3.cdn.digitaloceanspaces.com/sample-data/GIU_Game_Sample.csv' />
      {data.length > 0 && (
        <MatchCountDisplay scheduleData={formatScheduleData(data)} setRefresh={setRefresh} />
      )}
    </CommonOffCanvas>
  );
}

const MatchCountDisplay = ({ scheduleData, setRefresh }) => {
  const { sportId, seasonId } = useParams();
  // State to track which dates are selected
  const [selectedDates, setSelectedDates] = useState({});
  const [loader, setLoader] = useState(false);
  const [data, setData] = useState({ successArr: [], failedArr: [] })
  const [onSelectedDatesChange, onSetSelectedDatesChange] = useState({})
  // Handler for checkbox change
  const handleCheckboxChange = (date) => {
    setSelectedDates((prevSelectedDates) => {
      const updatedSelectedDates = {
        ...prevSelectedDates,
        [date]: !prevSelectedDates[date], // Toggle the selected state of the date
      };

      // Call the callback function with the updated selected dates and their data
      const selectedData = Object.entries(scheduleData)
        .filter(([dateKey]) => updatedSelectedDates[dateKey])
        .reduce((acc, [dateKey, divisions]) => {
          acc[dateKey] = divisions;
          return acc;
        }, {});

      onSetSelectedDatesChange(selectedData); // Pass the selected dates' data to the parent component

      return updatedSelectedDates;
    });
  };



  const handleImport = () => {
    setLoader(true)
    Apipost('/games/import-games', { games: onSelectedDatesChange, seasonId, sportId }, 'application/x-www-form-urlencoded', true).then(
      (response) => {
        if (response.data.status) {
          successMessageNodify(response.data.message)
          setData({ successArr: response.data.successArr, failedArr: response.data.failedArr })
          setLoader(false)
          setRefresh(prev => !prev)
        } else {
          errorMessageNodify("")
          setLoader(false)
        }
      },
      (err) => {
        console.error(err);
      }
    );
  };

  return (
    <div className="container mt-5">
      <div className="games-slot-list" style={{ maxHeight: '562px', overflow: 'auto' }}>
        <ul className="list-group">
          {Object.entries(scheduleData).map(([date, divisions]) => {
            const totalMatches = Object.values(divisions).reduce(
              (total, division) => total + division.count,
              0
            );

            return (
              <li key={date} className="list-group-item" style={{ padding: '10px 18px;' }}>
                <div className='d-flex align-item-center justify-content-between'>
                  <div>
                    <input
                      type="checkbox"
                      checked={selectedDates[date] || false}
                      onChange={() => handleCheckboxChange(date)}
                      className="me-2"
                      style={{
                        width: "20px",
                        height: "20px",
                      }}
                    />
                    <span style={{ fontWeight: '600px' }}>{date}</span>:
                  </div>
                  <div> <span className="" style={{ color: '#f1582a' }}>{totalMatches}</span> Games</div>
                </div>


              </li>
            );
          })}
        </ul>
      </div>
      <CommonReport reportType="success" successData={data.successArr} failedData={data.failedArr} />

      <button className="btn btn-primary m-2 float-right" onClick={handleImport} disabled={!(Object.keys(onSelectedDatesChange).length > 0)}
      >{loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : "Import Games"}</button>
    </div>
  );
};

function DragCalander({ tabActive }) {
  const [venueList, setVenueList] = useState([]);
  const [timeSlots, setTimeSlots] = useState([]);
  const [dateval, setDateval] = useState(moment().startOf('day'));
  const [timeIntervals, setTimeIntervals] = useState([])
  const [teamlist, setTeamList] = useState([])
  const [matches, setmatches] = useState([])
  const [showStatus, setShowStatus] = useState(false)
  const [refresh, setRefresh] = useState(false)
  const [dates, setDates] = useState({});
  const [time_id, setTimeid] = useState('')
  const [modelType, setModelType] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const { sportId, seasonId, groupId } = useParams();
  const [show, setShow] = useState(false);
  const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' });
  const [divisions, setDivisions] = useState([]);
  const [currentGroupId, setCurrentGroupId] = useState(groupId)
  const [values, setValues] = useState({
    matchDate: '',
    team_one_id: '',
    team_two_id: '',
    venue_id: '',
    venue_field_id: '',
    venue_field_timeslot_id: '',
    refree_id: [],
    parking: '',
    food_truck: '',
    match_instruction: '',
  })
  const navigate = useNavigate();



  function getTextColorBasedOnBgColor(bgColor) {
    // Remove the '#' if present
    bgColor = bgColor.replace('#', '');

    // Convert the background color to RGB values
    const r = parseInt(bgColor.substring(0, 2), 16);
    const g = parseInt(bgColor.substring(2, 4), 16);
    const b = parseInt(bgColor.substring(4, 6), 16);

    // Calculate the luminance of the color
    const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;

    // Return black for light backgrounds, and white for dark backgrounds
    return luminance > 0.5 ? '#000000' : '#FFFFFF';
  }
  const location = useLocation();
  const { hash, pathname, search } = location;
  console.log(hash, "hash", pathname, "pathname", search, "search")

  const getGroupList = () => {
    ApiGetAuth(`teams/get-Teams-ByGroupId/${currentGroupId}`).then(
      (data) => {
        if (data?.data) {
          setTeamList(data.data.data);
        }
      }
    );
  }
  useEffect(() => {
    getGroupList()
  }, [currentGroupId])

  useEffect(() => {

    Apipost('/games/get-Calander-Schedule', { sports_id: sportId, season_id: seasonId, dayId: moment(dateval).day(), date: moment(dateval).format('YYYY-MM-DD') }, 'application/x-www-form-urlencoded', true).then(
      (response) => {
        const { venues, scheduleSlots, matches, venueTimeslots, divisions } = response.data.data;
        setVenueList(venues);
        setTimeSlots(venueTimeslots)
        setDivisions(divisions)
        setDates(scheduleSlots)
        setTimeIntervals(scheduleSlots.slots)
        setmatches(matches)
      },
      (err) => {
        console.error(err);
      }
    );
  }, [dateval, refresh, currentGroupId])


  let draggedIndex = null;
  let draggedValue = null;
  let draggedKey = null;

  const handleDragStart = (event, index, key, value) => {
    draggedIndex = index;
    draggedValue = value;
    draggedKey = key;
    event.dataTransfer.setData("text/plain", index);
  };


  const handleDragOver = (event, index) => {
    event.preventDefault();
    // Check if the dragged item is being dragged over a valid drop target
    if (index !== draggedIndex) {
      event.dataTransfer.dropEffect = "move"; // Change to "move" to indicate valid drop target
    }
  };

  const closeDeleteModal = () => {
    setTimeid(0)
    setShowDeleteModal(false);
  };
  const openDeleteModal = (id, modelType) => {
    setModelType(modelType)
    setTimeid(id)
    setShowDeleteModal(true);
    setRefresh(prev => !prev)
  };

  const handleDrop = (event, index, key, data) => {
    event.preventDefault();
    if (draggedKey === 'Team' && key == 'Team') return

    if (key === 'Game' && data.check.length > 0 && data.findMatch.length === 0 && draggedKey === 'Team') {
      const { check } = data;
      const { venue_field_id, venue_id, id } = check[0]
      setValues({ venue_id: venue_id, team_one_id: draggedIndex, matchDate: moment(dateval).format('YYYY-MM-DD'), venue_field_id: venue_field_id, venue_field_timeslot_id: id })
      handleShow()
    } else if (data.findMatch?.length > 0 || draggedValue?.length > 0) {
      const { findMatch, check } = data
      Apipost('/games/game-Swap', { gameData: { game1: findMatch, game2: draggedValue, emptySlot: [check[0]] } }, 'application/x-www-form-urlencoded', true).then(
        (response) => {
          if (response.data.status) {
            setRefresh(prev => !prev)
            successMessageNodify(response.data.msg)
          } else {
            errorMessageNodify(response.data.msg)
          }
        },
        (err) => {
          console.error(err);
        }
      );
    }
  };
  const handleHide = () => { setShowStatus(false) }
  const handleShow = () => {
    setShowStatus(true)
  }

  const handleOpen = (data) => {
    if (data.length) {
      const { group_id } = data[0]
      if (group_id == currentGroupId) {
        setValues({ ...data[0] })
        handleShow()
      }
    }
  }



  return (<>
    <DeletePopup isOpen={showDeleteModal} onClose={closeDeleteModal} values={matches} setValues={setmatches} endpoint={`/games/delete-match/${time_id}`} id={time_id} title=" this Game" modelType={modelType} />
    <CustomModal componentToRender={<TabSlideNav slide={false} createVisible={false} dates={{ start_date: dates.start_date, end_date: dates.end_date }} teams={teamlist} sportsid={sportId} groupid={currentGroupId} handleHide={handleHide} value={values} setRefresh={setRefresh} show={show} setShow={setShow} handleShow={handleShow} />} showModal={showStatus} onClose={handleHide} />
    <div className="" >
      <div className="calender-venue">



        <div className="d-flex flex-column" style={{ height: '100vh' }}>



          {/* Top Bar */}



          <nav className="navbar navbar-light bg-light border-bottom fixed-top" style={{ zIndex: '9999' }}>
            <div className="container-fluid">
              <div className="row  w-100 navupper">
                <div className="col-md-2 text-center">
                  {/* logo */}
                  <div className="giunavigation d-flex ">
                    <div className="giunav-logo">
                      <img src={GIU_Logo} alt="GIU_Logo" width='10' />
                    </div>
                  </div>
                  {/* logo */}
                </div>
                <div className="col-md-1 text-center py-2">
                  <span onClick={() => {
                    const newHash = hash.replace('calander', 'organizer');
                    const newPathname = pathname.replace('calander', 'organizer');
                    // Use the navigate function to go to the new path
                    navigate(newPathname + newHash, { replace: true });
                    console.log('Clicked!', newHash, newPathname)
                  }} style={{ cursor: "pointer" }}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#f1582a" class="bi bi-arrow-left-circle" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M1 8a7 7 0 1 0 14 0A7 7 0 0 0 1 8m15 0A8 8 0 1 1 0 8a8 8 0 0 1 16 0m-4.5-.5a.5.5 0 0 1 0 1H5.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L5.707 7.5z" />
                    </svg>
                  </span>
                </div>

                <div className="col-md-8 col-lg-8 col-xl-8 col-xxl-8 ">
                  {/* nav */}
                  <div className="d-flex justify-content-between  gamesoption py-2">
                    <div className="d-flex gap-2 align-items-center navmain">
                      <div>
                        <h5>
                          <strong>
                            {dates.gameName} / {dates.season_name}
                          </strong>
                        </h5>
                      </div>
                      <div className="date-content-calender">

                      </div>
                    </div>
                    <div className="dateelement">
                      <input
                        type="date"
                        className="form-control border-0 fs-5"
                        value={moment(dateval).format('YYYY-MM-DD')}
                        onChange={(e) => setDateval(e.target.value)}
                      />
                    </div>
                    <div className="d-flex  align-items-center">

                      <ImportTimeslots venueList={venueList} setRefresh={setRefresh} />
                      <ImportGamesData setRefresh={setRefresh} />
                    </div>
                  </div>
                  {/* nav */}
                </div>
                <div className="col-md-1 col-lg-1 col-xl-1 col-xxl-1 text-center py-2 ">
                  <span className="pdf-icon" onClick={() => toPDF()}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="35" height="35" fill="#d41c1f" class="bi bi-file-earmark-pdf" viewBox="0 0 16 16">
                      <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                      <path d="M4.603 14.087a.8.8 0 0 1-.438-.42c-.195-.388-.13-.776.08-1.102.198-.307.526-.568.897-.787a7.7 7.7 0 0 1 1.482-.645 20 20 0 0 0 1.062-2.227 7.3 7.3 0 0 1-.43-1.295c-.086-.4-.119-.796-.046-1.136.075-.354.274-.672.65-.823.192-.077.4-.12.602-.077a.7.7 0 0 1 .477.365c.088.164.12.356.127.538.007.188-.012.396-.047.614-.084.51-.27 1.134-.52 1.794a11 11 0 0 0 .98 1.686 5.8 5.8 0 0 1 1.334.05c.364.066.734.195.96.465.12.144.193.32.2.518.007.192-.047.382-.138.563a1.04 1.04 0 0 1-.354.416.86.86 0 0 1-.51.138c-.331-.014-.654-.196-.933-.417a5.7 5.7 0 0 1-.911-.95 11.7 11.7 0 0 0-1.997.406 11.3 11.3 0 0 1-1.02 1.51c-.292.35-.609.656-.927.787a.8.8 0 0 1-.58.029m1.379-1.901q-.25.115-.459.238c-.328.194-.541.383-.647.547-.094.145-.096.25-.04.361q.016.032.026.044l.035-.012c.137-.056.355-.235.635-.572a8 8 0 0 0 .45-.606m1.64-1.33a13 13 0 0 1 1.01-.193 12 12 0 0 1-.51-.858 21 21 0 0 1-.5 1.05zm2.446.45q.226.245.435.41c.24.19.407.253.498.256a.1.1 0 0 0 .07-.015.3.3 0 0 0 .094-.125.44.44 0 0 0 .059-.2.1.1 0 0 0-.026-.063c-.052-.062-.2-.152-.518-.209a4 4 0 0 0-.612-.053zM8.078 7.8a7 7 0 0 0 .2-.828q.046-.282.038-.465a.6.6 0 0 0-.032-.198.5.5 0 0 0-.145.04c-.087.035-.158.106-.196.283-.04.192-.03.469.046.822q.036.167.09.346z" />
                    </svg>
                  </span>
                </div>
              </div>
            </div>
          </nav>





          <div className="d-flex" style={{ marginTop: '94px', flex: 1 }}>
            {/* Sidebar */}
            <div className="bg-light border-end" style={{ width: '250px', height: 'calc(100vh - 78px)', position: 'fixed', top: '80px', zIndex: '5', padding: '19px 9px' }}>


              <div className=" teams-heading  mt-3 ">

                <div className="form_select" >
                  <label>Division/Groups</label>
                  <Form.Select aria-label="Default select example" value={currentGroupId} onChange={e => setCurrentGroupId(e.target.value)}>
                    {
                      divisions?.map(item => <option value={item.id}>{item.group_name}</option>)
                    }
                  </Form.Select>
                </div>

              </div>



              <ul className="list-group">
                {teamlist?.map((item, i) => (
                  <li
                    draggable={true}
                    onDragStart={(event) => handleDragStart(event, item.id, "Team")}
                    onDragOver={(event) => handleDragOver(event, i)}
                    onDrop={(event) => handleDrop(event, item.id, 'Team')}
                    className="list-group-item border border-primary teamname"
                    key={i}
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-grip-vertical" viewBox="0 0 16 16">
                      <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                    </svg> {item.team_name}
                  </li>
                ))}
              </ul>
            </div>

            {/* Main Content */}
            <div className="flex-grow-1" style={{ marginLeft: '281px', padding: '20px', height: 'calc(100vh -94px)', marginRight: '23px' }}>
              <div className="calender-main-content">

                <div className="venue-schedule-table">
                  <div className="table-responsive">
                    {/* Calander */}
                    <div >
                    <div style={{ maxHeight: '741px', overflowY: 'auto' }}>
                      <table className="table-match" style={{ width: '100%', tableLayout: 'fixed' }} ref={targetRef}>
                        <thead>
                          <tr className="team-head">
                            <th className="border border-secondary" style={{ background: '#eee', position: 'sticky', top: 0, zIndex: 9, width: '63px' }}>Time</th>
                            {venueList?.map((venue, i) => {
                              const check = (timeSlots.filter(item => venue.id == item.venue_id && item.Day === moment(dateval).day()));
                              console.log(venue)
                              return check.length > 0 ? <th
                                className="border border-secondary"
                                style={{
                                  textAlign: 'center',
                                  background: '#eee',
                                  position: 'sticky',
                                  top: 0,
                                  zIndex: 9
                                }}
                                key={i}
                              >
                                {venue.name}
                              </th> : null
                            })}
                          </tr>
                        </thead>
                        <tbody>
                          {timeIntervals?.length > 0 ? <>
                            {timeIntervals?.map((item, i) => {
                              return <tr>
                                <td className="border-end border-bottom border-1 border-secondary time-card" style={{ background: '#f5f2f2', padding: '0px', }}>{item?.Time}</td>
                                {
                                  venueList?.map((venue, index) => {
                                    const check2 = (timeSlots.filter(item => venue.id == item.venue_id && item.Day === moment(dateval).day()));
                                    const check = (timeSlots.filter(item => item?.Time === timeIntervals[i]?.Time && venue.id == item.venue_id && item.Day === moment(dateval).day() && item.status_id === 1));
                                    const findMatch = matches.filter(item => item.venue_id == venue.id && item.Time == timeIntervals[i]?.Time)
                                    const { team_one, team_two, group_name, fieldname, color_code, group_id, id } = findMatch[0] || {}
                                    {/* console.log(currentGroup , group_id ,    group_id == currentGroup , "Checkpoint 1" , findMatch[0] ) */ }
                                    const bgColor = color_code || (check.length > 0 ? '#cefad0' : "#D3D3D3");
                                    const textColor = getTextColorBasedOnBgColor(bgColor);
                                    return check2.length > 0 ? <td className="border-end border-bottom border-1 border-secondary " style={{ padding: '0px !important' }}
                                      draggable={(findMatch.length) > 0 ? true : false}
                                      onDragStart={(event) => handleDragStart(event, i, "Game", findMatch)}
                                      onDragOver={(event) => handleDragOver(event, i)}
                                      onDrop={(event) => handleDrop(event, i, 'Game', { venue, item: item.Time, check, findMatch, })}
                                    >
                                      <div className="">
                                        <div className={"card calender-cards-box"} >
                                          <div className='card-body text-center' style={{ backgroundColor: bgColor, color: textColor }}>
                                            <div >
                                              {
                                                group_id == currentGroupId ? <div className="d-flex  flex-row-reverse" style={{ cursor: 'pointer' }} onClick={() => openDeleteModal(id, 'Delete')}>
                                                  <span >
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill={textColor}  class="bi bi-trash" viewBox="0 0 16 16"><path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z" /><path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z" /></svg>
                                                  </span>
                                                </div> : <></>
                                              }
                                              <div className="mt-3" onClick={() => { handleOpen(findMatch) }} style={{ cursor: 'pointer' }} >
                                                {
                                                  <>
                                                    <h6 className="card-title-1 ">{convertTo12HourFormat(timeIntervals[i]?.Time) || '---'} </h6>
                                                    <h6 className="card-subtitle mb-2 "> {team_one || '---'}</h6>
                                                    <h6 className="card-subtitle mb-2 "> Vs </h6>
                                                    <h6 className="card-subtitle mb-2 ">{team_two || '---'}</h6>
                                                    <p className="card-subtitle mb-2 ">{group_name || '--- ---'}</p>
                                                    <p className="card-subtitle mb-2 ">{fieldname || '-------'}</p>
                                                  </>
                                                }
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                    </td> : null
                                  }
                                  )
                                }
                              </tr>
                            })}
                          </> : <>
                          </>
                          }
                        </tbody>
                      </table>
                      </div>
                      <>
                        {
                          timeIntervals.length == 0 ?
                            <div className="venu-nodata">
                              <NoData message='No Game Scheduled / No TimeSlots Added For the current Date! ' helpText='It looks like there are Game Schedule/No Time Slots Added at the moment. Please feel free to Timeslots.' />
                            </div> : null
                        }
                      </>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </>);
}

export { DragCalander, CommonOffCanvas, ImportTimeslots };
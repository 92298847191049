import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faLink, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import {
    dateBefore
} from "../helpers/commonFunction";
import { Apipost, ApiGetAuth } from "../helpers/apiCall";
import plus from '../assets/images/plus.png'
import { errorMessageNodify, successMessageNodify } from "../helpers/notify";
import { useFormik } from "formik";
import * as Yup from "yup";
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import { useDispatch } from "react-redux";
import { fetchCoaches } from "../redux/api";
import '../assets/css/payment.css';
import moment from "moment";

const validationSchema = Yup.object({
    firstname: Yup.string()
        .matches(/^[a-zA-Z\s]+$/, 'Invalid first name')
        .required('FullName is required')
        .min(3, 'FullName must be at least 3 characters long')
    .max(30, 'FullName cannot be longer than 30 characters'),
    // lastname: Yup.string()
    //     .matches(/^[a-zA-Z\s]+$/, 'Invalid last name')
    //     .required('Last Name is required')    .min(3, 'Last Name must be at least 3 characters long')
    //     .max(30, 'Last Name cannot be longer than 30 characters'),
    email: Yup.string()
        .matches(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/, 'Invalid email address')
        .required('Email is required'),
    phoneNumber: Yup.string().required("Phone Number is required"),
    // gender: Yup.string().required("Gender is required"),
    // dob: Yup.string().required("Date of Birth is required"),
    role: Yup.number().required("please select user type")
});
export const roles = [{ roleid: '', type: "Select" }, { roleid: 5, type: "Referee" }, { roleid: 3, type: "Coach" }, { roleid: 9, type: "Team Mom" } ,
     {roleid:12 , type:"Head Coach"} , {roleid:13 , type:"Assistant Coach"}]


const CommonForm = ({
    values,
    type = '',
    customCheck,
    pastYear,
    redirectPath,
    dropDown,
    apiRoute,
    setRefresh,
    closeModal,
    teamid = '',
    roleid = ''
}) => {
    const [image, setImage] = useState("");
    const [imgSource, setImgSource] = useState('');
    const { sportId, groupId } = useParams();
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('')
    const [existingUserDetails, setExistingUserDetails] = useState({})
    const dispatch = useDispatch()
    const formik = useFormik({
        initialValues: {
            role_id: "",
            firstname: "",
            lastname: "",
            email: "",
            phoneNumber: "",
            gender: "",
            dob: "",
            role: '',
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);
            let temp = { ...values }
            if (imgSource) temp = { ...temp, image: imgSource }
            if (teamid) temp = { ...temp, teamid }
            if (sportId) temp = { ...temp, sportId }


            if (values.role) Apipost(apiRoute, temp, "multipart/form-data", true).then(
                (response) => {
                    let result = response.data;
                    if (result.status === true) {
                        successMessageNodify(result.msg);
                        setLoading(false);
                        closeModal()
                        setRefresh(prev => !prev)
                        dispatch(fetchCoaches())
                        return;
                    } else {
                        errorMessageNodify(result.msg);
                        setLoading(false);
                        return;
                    }
                },
                (err) => {
                    errorMessageNodify(err.response?.data.msg);
                    setLoading(false);
                    return;
                }
            );
        }
    });

    useEffect(() => {
        if (roleid) {
            formik.setFieldValue('role', roleid)
        }
    }, [])



    const userFind = () => {
        Apipost('/users/find-user', { email }, 'application/x-www-form-urlencoded', true).then(
            (response) => {
                console.log(response , "response ")
                if (Object.keys(response.data.data).length) {
                    const { contact_number, dob, email, firstname, gender, lastname, profile_pic, role_id, id } = response.data.data
                    successMessageNodify("User Details Fetched Successfully !");
                    formik.setValues({ firstname,
                        lastname,
                        email,
                        phoneNumber: contact_number,
                        gender,
                        dob:moment(dob).format('YYYY-MM-DD'),
                        role: role_id,
                        id,
                        profile_pic})

                    setExistingUserDetails({
                        firstname,
                        lastname,
                        email,
                        phoneNumber: contact_number,
                        gender,
                        dob,
                        role: role_id,
                        id,
                        profile_pic
                    })
                } else {
                    setExistingUserDetails({})
                    errorMessageNodify("User Not Found !");
                }
            },
            (err) => {
                errorMessageNodify(err.response.data.message);
                console.log(err , "checking the err")
                return {}
            }
        );

    }


    const handleAddtoAccount = () => {
        Apipost('/users/add-to-account', { id: existingUserDetails.id, role: existingUserDetails.role,sportId:sportId }, 'application/x-www-form-urlencoded', true).then(
            (response) => {
                successMessageNodify(response.data.msg);
                closeModal()
            },
            (err) => {
                errorMessageNodify(err.response.data.msg);
                closeModal()
                return {}
            }
        );
        setRefresh(prev => !prev)
    }



    // const [Approver, setApprover] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const fromPage = location.state?.from?.pathname || redirectPath;

    // const [teamList, setTeamList] = useState([]);

    const hiddenFileInput = React.useRef(null);

    const handleClick = () => {
        hiddenFileInput.current.click();
    };
    const onImageChange = (event) => {
        if (event.target.files && event.target.files[0]) {
            setImgSource(event.target.files[0]);
            setImage(URL.createObjectURL(event.target.files[0]));
        }
    };

    // if (type !== 'referee') {
    //     useEffect(() => {
    //         ApiGetAuth(dropDown).then((data) => {
    //             setTeamList(data.data);
    //         }, (err) => {
    //             console.log(err);
    //         })
    //     }, []);
    // }

    return (
        <>
            <div className="season-popup">
                <div className="modal-title h4 mb-4 pt-1">Create Member</div>
                <div className="dashboard-auto-wrapper">
                        <div className="create-from form_submit_basketball createmember-team">
                            <div className="d-flex justify-content-between align-items-center">
                                <div className="profile-img-box ">
                                    <div className="cperson-img ">
                                        <img
                                            alt=""
                                            src={image ? image : require("../assets/images/user-100.png")}
                                            onClick={handleClick}
                                        />
                                    </div>
                                    <div className="cperson-detail col-md-10">
                                        <label className="profile-file-upload">
                                            <input
                                                type="file"
                                                ref={hiddenFileInput}
                                                onChange={onImageChange}
                                                accept="image/*"
                                                style={{ display: "none" }}
                                            />
                                            <label className="form-label" htmlFor="role">Select User Type:</label>
                                            <select name="role" id="role" className="form-control"
                                                disabled={roleid >0? true:false }
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur} value={formik.values.role} >
                                                {roles.map((item, i) => <option value={item.roleid} key={i}>{item.type}</option>)}
                                            </select>
                                            {formik.touched.role && formik.errors.role ? (
                                                <div className="invalid-feedback">{formik.errors.role}</div>
                                            ) : null}
                                        </label>
                                    </div>

                                </div>
                            </div>

                            <div className="create-form-inner member-create">
                                {/* <div className="row">
                                    <div className="col-sm-12">
                                        <div className="input-find find_input_button">
                                            <input placeholder=" Find Existing user" onChange={(e) => (setEmail(e.target.value))}  disabled={roleid >0? true:false }/>
                                            <button className="btn  btn-primary ms-1" onClick={userFind}>Find </button>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="form-group">
                                            <label className="form-label" htmlFor="firstname">Full Name</label>
                                            <input
                                            
                                                className=" w-100"
                                                type="text"
                                                id="firstname"
                                                name="firstname"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.firstname}
                                            />
                                            {formik.touched.firstname && formik.errors.firstname ? (
                                                <div className="ErrorHandling">{formik.errors.firstname}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    {/* <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="lastname" className="form-label">Last Name</label>
                                            <input
                                                maxLength={15}
                                                className="form-control"
                                                type="text"
                                                id="lastname"
                                                name="lastname"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.lastname}
                                            />
                                            {formik.touched.lastname && formik.errors.lastname ? (
                                                <div className="ErrorHandling">{formik.errors.lastname}</div>
                                            ) : null}
                                        </div>
                                    </div> */}
                                </div>
                                <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="email" className="form-label">Email</label>
                                            <input
                                                className="form-control"
                                                type="email"
                                                id="email"
                                                name="email"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.email}
                                            />

                                            {formik.touched.email && formik.errors.email ? (
                                                <div className="ErrorHandling">{formik.errors.email}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="phoneNumber" className="form-label">Phone Number</label>
                                            <PhoneInput
                                                country={'us'}
                                                input className="form-control"
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                onChange={phoneNumber => formik.setFieldValue("phoneNumber", phoneNumber)}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.phoneNumber}
                                            />
                                            {formik.touched.phoneNumber && formik.errors.phoneNumber ? (
                                                <div className="ErrorHandling">{formik.errors.phoneNumber}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="gender" className="form-label">Gender</label>
                                            <select
                                                className="form-control"
                                                id="gender"
                                                name="gender"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.gender}
                                            >
                                                <option value="">Select</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                            {formik.touched.gender && formik.errors.gender ? (
                                                <div className="ErrorHandling">{formik.errors.gender}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                    <div className="col-sm-6">
                                        <div className="form-group">
                                            <label htmlFor="dob" className="form-label">Date of Birth</label>
                                            <input
                                                className="form-control"
                                                max={dateBefore(pastYear || 3)}
                                                type="date"
                                                id="dob"
                                                name="dob"
                                                onChange={formik.handleChange}
                                                onBlur={formik.handleBlur}
                                                value={formik.values.dob}
                                            />
                                            {formik.touched.dob && formik.errors.dob ? (
                                                <div className="ErrorHandling">{formik.errors.dob}</div>
                                            ) : null}
                                        </div>
                                    </div>
                                </div> */}
                            </div>
                            <div className="d-flex  align-items-center divisions-schedule justify-content-start">
                                <div className="create-from-btn submission ">
                                {!Object.keys(existingUserDetails).length ?<button
                                        className="btn btn-primary savesubmit payment-btn mt-1"
                                        onClick={formik.handleSubmit}
                                        disabled={loading}
                                    >
                                        {
                                            loading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : 'Create'
                                        }
                                    </button>: <>
                                    <button
                                        className="btn btn-primary savesubmit payment-btn mt-1"
                                        onClick={handleAddtoAccount}
                                    >
                                        {
                                            // loading ? <FontAwesomeIcon icon={faCircleNotch} spin  /> : 
                                            'Add to Account'
                                        }
                                    </button>
                                    <button
                                        className="btn btn-danger savesubmit payment-btn mt-1"
                                        onClick={()=>{
                                            setExistingUserDetails({})
                                            formik.resetForm({
			                                        	values: {
                                                            firstname: "",
                                                            lastname: "",
                                                            email: "",
                                                            phoneNumber: "",
                                                            gender: "",
                                                            dob: "",
			                                        	}
			                                        });
                                        }}
                                    >

                                        Reset
                                      
                                    </button>
                                    </>
                                    }
                                </div>
                            </div>
                        </div> 

                </div>
            </div>
        </>
    );
};

export default CommonForm;





import React, { useState, useEffect } from "react";
import {useNavigate } from "react-router-dom";
import { Multiselect } from "multiselect-react-dropdown";
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import {
	errorMessageNodify,
	successMessageNodify,
} from "../../../helpers/notify";
import { useDispatch, useSelector } from "react-redux";

import Poploader from "../Event/Popuploader";

import '../../../assets/css/payment.css';
import withLoader from "../../../components/PageLoader";
import { fetchPaymentDetail } from "../../../redux/api";

const AddPlayerTeamLoader = ({ group_id, sportId, teamDetails, isUpdate, setShowModal ,setRefresh }) => {
	const [image, setImage] = useState("");
	const [ageGroupList, setAgeGroupList] = useState([]);
	const [selectedAgeGroup, setSelectedAgeGroup] = useState(0);
	const [teamName, setTeamName] = useState("");
	const [grade, setGrade] = useState(0);
	const [teamId, setTeamId] = useState(0)
	const [gradeOptions, setGradeOptions] = useState([]);
	const [coach, setCoach] = useState([]);
	const [coachList, setCoachList] = useState([]);
	const [selectedDocument, setSelectedDocument] = useState([]);
	const [selectedVenue, setSelectedVenue] = useState([]);
	const [documentList, setDocumentList] = useState([]);
	const [venues, setVenues] = useState([]);
	const [loading1, setLoading1] = useState(false);
	const selector = useSelector((state) => state);
	const [teamNameError, setTeamNameError] = useState("");
	const [ageGroupError, setAgeGroupError] = useState("");
	const [gradeError, setGradeError] = useState("");
    const dispatch = useDispatch()
	useEffect(() => {
		ApiGetAuth(`app/document-list`).then(
			(data) => {
				if (data?.data) {
					const res = [];
					data?.data?.map((item) => {
						const temp = {};
						temp["label"] = `${item["document_name"]} (${item["document_description"]})`;
						temp["cat"] = item.id;
						res.push(temp);
					});
					setDocumentList(res);
				}
			},
			(err) => {
				console.log(err);
			}
		);
		setGradeOptions(selector.GRADELIST);
		let res = [];
		ApiGetAuth(`users/user-list/all-users`).then((data) => {
			res = [];
			data?.data?.filter((fil) => {
				if(fil.sport_id != null &&  fil.sport_id.search(sportId.toString()) >= 0){
					return fil
				}
			}).map((item) => {
				const temp = {};
				if (item.roleName !== "Referee") {
					temp["label"] = `${item["firstname"]} (${item.roleName})`;
					temp["cat"] = item["id"];
					res.push(temp);
				}
			});
			setCoachList(res);
		})

		ApiGetAuth('venue/venue-field-list').then(
			(success) => {
				const venueList = success.data;
				res = [];
				venueList?.map((item) => {
					const temp = {};
					temp["label"] = `${item["name"]}`;
					temp["cat"] = item["id"];
					res.push(temp);
				});
				setVenues(res);
			},
			(error) => {
				console.log(error.message);
			}
		);

		setAgeGroupList(selector.AGEGROUPLIST);
	}, []);
	useEffect(() => {
		if (isUpdate) {
			const teamData = teamDetails // Replace with the actual response data structure
			if (teamData?.members?.length) {
				let res = [];
				teamData?.members?.map((item) => {
					const temp = {};
					if(item.roleName == "Coach" ||item.roleName == "Team Mom"  ){
						temp["isAdmin"] = false
						if(teamData.user_id == item.userid) temp["isAdmin"] = true
						temp["label"] = `${item["firstname"]} (${item.roleName})`;
						temp["cat"] = item["userid"];
						res.push(temp);
					}
				});
				setCoach(res);
			}
			if (teamData?.document_ids) {
				const docsId = eval(teamData.document_ids.replaceAll('"', ''))
				ApiGetAuth(`app/document-list`).then(
					(data) => {
						if (data?.data) {
							let res = [];
							docsId?.map((id) => {
								const item = data?.data?.filter(item => item.id == id)[0]
								if (item) {
									const temp = {};
									temp["label"] = `${item["document_name"]} (${item["document_description"]})`;
									temp["cat"] = item.id;
									res.push(temp);
								}
							});
							setSelectedDocument(res)
						}
					},
					(err) => {
						console.log(err);
					}
				);
			}
			if (teamData?.venues?.length) {
				let res = []
				teamData?.venues?.map((id) => {
					const item = selector.VENUES?.filter(item => item.id == id.venue_id)[0]
					const temp = {};
					if (item?.name?.length) {
						temp["label"] = `${item["name"]}`;
						temp["cat"] = item["id"];
					}
					res.push(temp);
				});
				setSelectedVenue(res);
			}
			setTeamName(teamData.team_name);
			setGrade(teamData.grade_id);
			setSelectedAgeGroup(teamData.age_group_id)
			setImage(""); // Assuming image is a URL or file object
			setTeamId(teamData.id)
		}
	}, [teamDetails]);

	const handleSubmit = (e) => {
		e.preventDefault();
		if (!teamName.trim()) {
			setTeamNameError("Team Name is required");
			
		  } else if (teamName.trim().length > 30) {
			setTeamNameError("Team Name must be 30 characters or less");
		  } else {
			setTeamNameError("");
		  }
		// If any of the required fields is empty, prevent form submission
		if (!teamName.trim() ||teamName.trim().length > 30) {
			return;
		}
		setLoading1(true)
		const document_ids = selectedDocument?.map(item => item.cat);
		const coaches = coach?.map(item => item.cat).join(',');
		const venues = selectedVenue?.map(item => item.cat).join(',');
		const values = {
			group_id: group_id ,
			selectedVenue: venues,
			document_ids: document_ids || [],
			sports_id: sportId,
			image,
			teamname: teamName,
			gradeid: grade || 0,
			coaches: coaches,
			agegroupid: selectedAgeGroup||0,
			teamId,
		};
		const apiUrl = isUpdate ? "/teams/update-team" : "/teams/add-team";

		Apipost(apiUrl, values, "multipart/form-data", true)

			.then((response) => {
				let result = response?.data;

				if (result.status === true) {
					setLoading1(false)
					successMessageNodify(result.msg);
					dispatch(fetchPaymentDetail())
					setRefresh(prev=>!prev)
					//setShowModal(false);
				} else {
					setLoading1(false)
					errorMessageNodify(result.msg);
				}
			})
			.catch((err) => {
				errorMessageNodify(err.response?.data.message);
			});
	};


	const handleCoach = (val) => {
		setCoach(val);
	};
	const hiddenFileInput = React.useRef(null);

	const handleClick = () => {
		hiddenFileInput.current.click();
	};

	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			setImage(event.target.files[0]);
		}
	};

	const handleOnchangePlayer = (val) => {
		setSelectedDocument(val);
	};

	const handleOnchangeVenue = (val) => {
		setSelectedVenue(val);
	};



	return (
		<>
			<div className="dashboard-auto-wrapper season-popup px-0" >

				{/* <button type="button" className="btn-close" ></button> */}
				<div className="create-from form_submit_basketball">
					<h3 className="pop-title text-center">{isUpdate ? "Update" : "Create"} Team</h3>
					<div className="d-flex  justify-content-center align-items-center mt-4">
						<div className="profile-img-box d-block">
							<div className="cperson-img w-100">
								<img
									alt=""
									src={
										image
											? URL.createObjectURL(image)
											: (teamDetails?.team_logo ? teamDetails?.team_logo : require("../../../assets/images/placeholder_background.png"))
									}
									onClick={handleClick}
								/>
							</div>
							<div className="cperson-detai team-image-profilel">
								<label className="profile-file-upload ">
									<input
										type="file"
										ref={hiddenFileInput}
										onChange={onImageChange}
										accept="image/*"
										style={{ display: "none" }}
									/>
								</label>
							</div>
						</div>
						<div className="col-md-10">
							<div className="form-group">
								<div className="input-right-icon">
									<label className="form-label">Team Name
										<span className="imp_ast">*</span>
									</label>
									<div className="input-right-icon">
										<input
											className="form-control"
											placeholder="Enter Team Name"
											value={teamName}
											onChange={(e) => {
												setTeamName(e.target.value);
												if (teamNameError) {
													setTeamNameError("");
												}
											}}
										/>
										{teamNameError && <div className="error-message">{teamNameError}</div>}

									</div>
								</div>	
							</div>
						</div>
					</div>
					<div className="create-form-inner pt-2 add-teams create-teamf">
						

						<div className="row">
							<div className="col-md-6">
								<div className="form-group">
									<div className="input-right-icon">
										<label className="form-label"> Age Group
											{/* <span className="imp_ast">*</span> */}
										</label>
										<select
											className="form-control"
											name="sports_id"
											value={selectedAgeGroup}
											onChange={(e) => {
												setSelectedAgeGroup(e.target.value)
												if (ageGroupError) {
													setAgeGroupError("");
												}
											}
											}
										>
											<option value =''>Select Age Group</option>
											{ageGroupList?.length ? (
												ageGroupList?.map((item, i) => (
													<option value={item.id}>{item.display_text_age}</option>
												))
											) : (
												<></>
											)}
										</select>
										{ageGroupError && <div className="error-message">{ageGroupError}</div>}
									</div>	
								</div>
							</div>
							<div className="col-md-6 pe-0">
								<div className="form-group">
									<div className="input-right-icon">
										<label className="form-label">Select Grades
											{/* <span className="imp_ast">*</span> */}
										</label>
										<select
											className="form-control"
											value={grade}
											onChange={(e) => {
												setGrade(e.target.value)
												if (gradeError) {
													setGradeError("");
												}
											}}
										>
											<option value=''>Select Grade</option>
											{gradeOptions?.map((item) => {
												return (
													<option value={item.id}>{item.grade_name} </option>
												);
											})}
										</select>
										{gradeError && <div className="error-message">{gradeError}</div>}
									</div>	
								</div>
							</div>
						</div>
						<div className="row">
							<div className="col-md-12">
								<div className="form-group">
									<div className="input-right-icon">
										<label className="form-label">Select Team Mom/Coach</label>
										<Multiselect
											placeholder="Select Team Mom/Coach"
											options={coachList}
											displayValue="label"
											onSelect={handleCoach}
											onRemove={(selectedList, removedItem) => {
												if (removedItem.isAdmin) {
                                                       // Manually add back the admin item if it was removed
                                                       setCoach((prevSelected) => {
                                                           // Ensure the admin item is not duplicated
                                                           if (!prevSelected.some(item => item.cat === removedItem.cat)) {
                                                               return [...prevSelected, removedItem];
                                                           }else if(prevSelected.length)return prevSelected;
                                                       });
                                                   } else {
                                                       setCoach(selectedList);
                                                   }
											}}
											selectedValues={coach} 
											avoidHighlightFirstOption={true}
                                            

											/>
									</div>		
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<div className="input-right-icon">
										<label className="form-label">Age/Grade Verification Documents</label>
										<Multiselect
											placeholder="Age/Grade Verification Documents"
											options={documentList}
											displayValue="label"
											onRemove={(selectedList, removedItem) => {
												setSelectedDocument(selectedList)
											}}
											onSelect={handleOnchangePlayer}
											selectedValues={selectedDocument} />
									</div>		
								</div>
							</div>
							<div className="col-md-12">
								<div className="form-group">
									<div className="input-right-icon">
										<label className="form-label">Select Home  field</label>
										<Multiselect
											placeholder="Select Home Ground"
											options={venues}
											displayValue="label"
											onRemove={(selectedList, removedItem) => {
												setSelectedVenue(selectedList)
											}}
											onSelect={handleOnchangeVenue}
											selectedValues={selectedVenue} />
									</div>		
								</div>
							</div>
						</div>	

						
					</div>
					<div className="d-flex  align-items-center divisions-schedule mb-0 justify-content-start">
						<div className="create-from-btn submission ps-3">
							<button
								className="btn btn-dark savesubmit next-orange-btn "
								disabled={loading1}
								onClick={handleSubmit}
							>
								<Poploader content={(isUpdate == false ? 'Create' : 'Update')} loader={loading1} />
								{/* {isUpdate == false?'Create':'Update' }  */}
							</button>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

const AddPlayerTeam = withLoader(AddPlayerTeamLoader)

export default AddPlayerTeam;

import React, { useState, useEffect } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal } from 'react-bootstrap';
import { ApiGetAuth, Apipost } from '../../helpers/apiCall';
import { useSelector } from 'react-redux';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { NavLink, useParams } from 'react-router-dom';
import { errorMessageNodify, successMessageNodify } from '../../helpers/notify';
import MyForm, { daysOptions } from '../../pages/Organizer/Timeslots/Index';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment/moment';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import Multiselect from 'multiselect-react-dropdown';
import { convertTo12HourFormat } from '../../helpers/helperfunctions';
import CustomModal from '../customModal';
import AddVenue from '../../pages/Organizer/Venue/add';
import AddPlayerTeam from '../../pages/Organizer/Team/AddPlayerTeam'

const TabSlideNav = ({slide=true,createVisible=true, sportsid, groupid, gameType, handleHide, setActive, value = {
	matchDate: '',
	team_one_id: '',
	team_two_id: '',
	venue_id: '',
	venue_field_id: '',
	venue_field_timeslot_id: '',
	refree_id: [],
	parking: '',
	food_truck: '',
	match_instruction: '',
}, dates, setRefresh, handleShow, show, setShow, ...props }) => {
	const handleClose = () => setShow(false);
	const [timeSlots, setTimeSlots] = useState([])
	const [venueList, setVenueList] = useState([]);
	const selector = useSelector((state) => state);
	const [RefreeList, setRefreeList] = useState([]);
	const [loader, setLoader] = useState(false)
	const { groupId, sportId } = useParams()
	const [showModal, setShowModal] = useState(false);
	const [teams , setTeams ] =useState([])
	const closeModal = () => {
		setShowModal(false);
	};
	const [contentToRender, setContentToRender] = useState(<AddVenue closeModal={closeModal} />)
	
	const validationSchema = Yup.object().shape({
		matchDate: Yup.string()
			.required('Select Date cannot be empty')
		,
		team_one_id: Yup.string()
			.required('Select Home Team cannot be empty')
		,
		team_two_id: Yup.string()
			.required('Select Vistor Team cannot be empty')
		,
		venue_id: Yup.string()
			.required('Select Venue cannot be empty')
		,
		venue_field_id: Yup.string()
			.required('Select Venue Field cannot be empty')
		,
		venue_field_timeslot_id: Yup.string()
			.required('Select Time Slot cannot be empty')
		,
		refree_id: Yup.array()

	});
	const formik = useFormik({
		initialValues: value,
		validationSchema,
		onSubmit: (values) => {
			console.log(values , "values")
			values.game_group_id = groupid
			values.score_one = 0
			values.score_two = 0
			if (!(gameType === 'Tournament')) values.round_id = 0
			const refrees = values.refree_id?.map(item => item.cat).join(',');
			values.referees = refrees
			let baseUrl = '/games/add-match'
			if (value.id > 0) baseUrl = '/games/update-Match'
			if (!!!value) {
				formik.setFieldValue('venue_field_timeslot_id', '')
				formik.setTouched({}, false);
			}
			setLoader(true)
			Apipost(baseUrl, values, "application/x-www-form-urlencoded", true).then(
				(response) => {
					successMessageNodify(response?.data.message)
					setLoader(false)
					setRefresh(prev => !prev)
					fetchTimeSlots()
					handleHide()
				},
				(err) => {
					errorMessageNodify(err.response?.data.message);
					setLoader(false)
					return;
				})


		}
	})


	const openModal = (value) => {
		if(value === 'venue'){
			setContentToRender(<AddVenue closeModal={closeModal} setRefresh={()=>{}} />)
			setShowModal(true);
		}else if(value ==='TimeSlots'){
			setContentToRender(<MyForm showPopUP="true" venue_id={formik.values.venue_id} day ={new Date(formik.values.matchDate).getDay()>=0 ? new Date(formik.values.matchDate).getUTCDay() :''  } field_id ={formik.values.venue_field_id}    />)
		}else{
			setContentToRender(<AddPlayerTeam group_id={groupid || groupId} sportId={sportId} isUpdate={false}    />)
		}
		setShowModal(true);
	}

const fetchTeamList = ()=>{
	ApiGetAuth(`teams/get-Teams-ByGroupId/${groupid || groupId}`).then(
		(data) => {
			if (data?.data) {
				setTeams(data.data.data);
			}
		}
	);
}
	
	const fetchTimeSlots = () => {
		ApiGetAuth(`venue/time-slots/${sportsid}?date=${formik.values.matchDate}`).then((data) => {
			if (data?.data) {
				setTimeSlots(data.data)
			}
		}, (err) => {
			console.log(err);
		})
	}
	const venueListFetch =()=>{
		ApiGetAuth(`venue/venue-field-list`).then(
			(data) => {
				let temp = [];
				data?.data?.filter((fil) => fil.sport_id == sportsid).map(item => {
					temp.push(item)
				})
				setVenueList(temp);
			},
			(err) => {
				console.log(err);
			}
		);
	}

	useEffect(()=>{
		fetchTimeSlots()
	},[formik.values.matchDate])
	

	useEffect(() => {
		let res = [], res1 = [];
		selector.COACHES.map((item) => {
			const temp = {};
			if (item.roleName == "Referee") {
				temp["label"] = `${item["firstname"]} ${item["lastname"]}`;
				temp["cat"] = item["id"];
				res.push(temp);
				if (!!value) {
					const temp1 = value.referee?.filter((items) => items.user_id == temp.cat)
					if (temp1?.length) res1.push(temp)
				}
			}
		});
		setRefreeList(res)
		venueListFetch()
		
		fetchTeamList()
		if (!!value) {
			formik.resetForm({ values: value });
			formik.setFieldValue('parking', value.parking == 1 ? true : false)
			formik.setFieldValue('food_truck', value.food_truck == 1 ? true : false)
			formik.setFieldValue('refree_id', res1)
		} else {
			formik.resetForm({
				values: {
					round_id: '',
					matchDate: '',
					team_one_id: '',
					team_two_id: '',
					Day: '',
					venue_id: '',
					venue_field_id: '',
					venue_field_timeslot_id: '',
					refree_id: [],
					parking: '',
					food_truck: '',
					match_instruction: '',
				}
			});
		}
	}, [show,groupid])

	useEffect(()=>{
		fetchTimeSlots()
		venueListFetch()
		fetchTeamList()
	},[showModal])



	useEffect(()=>{
		if(!value &&formik.values.venue_field_id ){
			formik.setFieldValue('venue_field_timeslot_id', '');
		}
	},[formik.values.matchDate])

	const handleRefree = (val) => {
		formik.setFieldValue('refree_id', val);
	};

console.log(value , "value" , !value.id > 0)

if(!slide){
	return (<>
<div className="custom-pop-box ">
								<div className="row">
									<div className="col-sm-12">
										<div className="create-form-inner create-match" style={{    height: "calc(85vh - 85px)"}} >
											<form onSubmit={formik.handleSubmit}>

												<div className="row">
													<div className='col-md-12'>
														<div className="form-group mb-4">
															<label className="form-label">Select Date<span className='text-danger'>*</span></label><span className="imp_ast"></span>
															<div className="input-right-icon">
																<input
																	type="date"
																	className="form-control"
																	name="matchDate"
																	placeholder="Baseball02"
																	min={moment(dates?.start_date).format('YYYY-MM-DD')}
																	max={moment(dates?.end_date).format('YYYY-MM-DD')}
																	value={formik.values.matchDate}
																	onChange={formik.handleChange}
																	{...formik.getFieldProps('matchDate')}
																/>
																{formik.touched.matchDate && formik.errors.matchDate && (
																	<span className="text-danger">{formik.errors.matchDate}</span>
																)}

															</div>
														</div>
													</div>

												</div>
												<div className="row">
												<div className="col-md-6">
													<div className="form-group mb-4">
															<label className="form-label">Home<span className='text-danger'>*</span></label><span className="imp_ast"></span>
															<NavLink to="#" className="float-right" onClick={()=>{openModal('Teams')}}>Create Team</NavLink>
															
															<div className="input-right-icon">
																<select
																	className="form-select"
																	aria-label="Default select example"
																	name="team_one_id"
																	value={formik.values.team_one_id}
																	onChange={formik.handleChange}
																	{...formik.getFieldProps('team_one_id')}
																>
																	<option value=''>Select Home Team</option>
																	<option  value={0}>BYE</option>
																	{teams?.length ? (
																		teams?.filter(item => item.id != formik.values.team_two_id)?.map((grade, i) => (
																			<option value={grade.id}>{grade.team_name}</option>
																		))
																	) : (
																		<></>
																	)}
																</select>
																{formik.touched.team_one_id && formik.errors.team_one_id && (
																	<span className="text-danger">{formik.errors.team_one_id}</span>
																)}
															</div>
														</div>
													</div>
													<div className="col-md-6">
															<div className="form-group mb-4">
															<label className="form-label">Away & Neutral<span className='text-danger'>*</span></label>
															<div className="input-right-icon">
																<select
																	className="form-select"
																	name="team_two_id"
																	value={formik.values.team_two_id}
																	onChange={formik.handleChange}
																	{...formik.getFieldProps('team_two_id')}
																>
																	<option value=''>Select Visitor Team</option>
																	<option value={0} >BYE</option>
																	{teams?.length ? (
																		teams?.filter((item) => item.id != formik.values.team_one_id).map((grade, i) => (
																			<option value={grade.id}>{grade.team_name}</option>
																		))
																	) : (
																		<></>
																	)}
																</select>
																{formik.touched.team_two_id && formik.errors.team_two_id && (
																	<span className="text-danger">{formik.errors.team_two_id}</span>
																)}

															</div>
														</div>
													</div>
													
												</div>

												<div className='row'>
													<div className=" col-md-6">
													<div className="form-group">
														<label className="form-label">Venue <span className='text-danger'>*</span></label>
														<NavLink onClick={()=>{openModal('venue')}} className="float-right">Add Venue</NavLink>
														<div className="input-right-icon">
															<select
																className="form-select"
																name="venue_id"
																value={formik.values.venue_id}
																onChange={formik.handleChange}
																{...formik.getFieldProps('venue_id')}
															>
																<option value="">Select Venue</option>
																{venueList?.length ? (
																	venueList.map((venue, i) => (
																		<option value={venue.id}>{venue.name}</option>
																	))
																) : (
																	<></>
																)}
															</select>
															{formik.touched.venue_id && formik.errors.venue_id && (
																<span className="text-danger">{formik.errors.venue_id}</span>
															)}
														</div>
													</div>
													</div>
													<div className=" col-md-6">
													<div className="form-group ">

														<label className="form-label">Field<span className='text-danger'>*</span></label><span className="imp_ast"></span>
														{/* <NavLink to={`/organizer/${sportId}/venues`} className="float-right">Add Field</NavLink> */}
														<div className="input-right-icon">
															<select
																className="form-select"
																name="venue_field_id"
																value={formik.values.venue_field_id}
																onChange={formik.handleChange}
																{...formik.getFieldProps('venue_field_id')}
															>
																<option value="">Select Venue Field</option>
																{venueList?.length ? (
																	(venueList?.filter((e) => e.id == formik?.values?.venue_id)[0]?.fields)?.map((grade, i) => (
																		<option value={grade.id}>{grade.fieldname}</option>
																	))
																) : (
																	<></>
																)}
															</select>
															{formik.touched.venue_field_id && formik.errors.venue_field_id && (
																<span className="text-danger">{formik.errors.venue_field_id}</span>
															)}
														</div>
														</div>

													</div>

												</div>

												<div className="row">
													
													<div className=" col-md-6">
													<div className="form-group">

														<label className="form-label">Time<span className="imp_ast">*</span></label>
														<NavLink onClick={()=>{openModal('TimeSlots')}} className="float-right">Add Time Slot</NavLink>
														<div className="input-right-icon">
															<select name="venue_field_timeslot_id" className="form-select" value={formik.values.venue_field_timeslot_id} onChange={formik.handleChange}
																{...formik.getFieldProps('venue_field_timeslot_id')}>
																<option value=''>Select TimeSlot</option>
															{timeSlots.filter(item=>(item.venue_id ==formik.values.venue_id && item.venue_field_id == formik.values.venue_field_id && (new Date(formik.values.matchDate).getUTCDay() == item.Day) && (item.status_id != 5 || item.id ==formik.values.venue_field_timeslot_id )))
                                                                    ?.map(item => <option value={item.id}>{convertTo12HourFormat(item.Time)}</option>)
															}
														</select>
														{formik.touched.venue_field_timeslot_id && formik.errors.venue_field_timeslot_id&& (
                                                <span className="text-danger">{formik.errors.venue_field_timeslot_id}</span>
                                            )}
														</div>
													</div>
													</div>
													<div className=" col-md-6">
													<div className="form-group">
														<label className="form-label">Add Refree
														{/* <span className='text-danger'>*</span> */}
														</label>
														<div className="input-right-icon">
															<Multiselect
																id="refree_id"
																options={RefreeList}
																displayValue="label"
																onSelect={handleRefree}
																onRemove={(selectedList, removedItem) => {
																	formik.setFieldValue("refree_id", selectedList)
																}}
																{...formik.getFieldProps('refree_id')}
																selectedValues={formik.values.refree_id} />

															{formik.touched.refree_id && formik.errors.refree_id && (
																<span className="text-danger">{formik.errors.refree_id}</span>
															)}
														</div>
													</div>
													</div>

												</div>
												<div className="row">
													{/* <div className="col-md-12">
														<div className="additional_button">
															<NavLink to="#">
																+ Additional Settings
															</NavLink>
														</div>
													</div> */}
													<div className="additional_setting_box" style={{ display: "block" }}>
														<div className="row">
															<div className="check-box-modal col-md-6">
																{/* <span className="custom-checkbox parking-check"> */}
																<span className="custom-checkbox parking-check">

																	<input className="form-check-input" name='parking' type="checkbox" checked={!!formik.values?.parking} id="selectAll"
																		onChange={formik.handleChange}
																		{...formik.getFieldProps('parking')}

																	/>
																	<label for="selectAll"> Parking Availability</label>
																</span>
																{formik.touched.parking && formik.errors.parking && (
																	<span className="text-danger">{formik.errors.parking}</span>
																)}
															</div>
															<div className="check-box-modal col-md-6">
																<span className="custom-checkbox parking-check foodcheck">

																	<input className="form-check-input" type="checkbox" id="selecttoo"
																		name='food_truck'
																		checked={formik.values?.food_truck == 1 ? true : false}
																		onChange={formik.handleChange}
																		{...formik.getFieldProps('food_truck')}

																	/>
																	<label for="selecttoo"> Food </label>
																</span>
																{formik.touched.food_truck && formik.errors.food_truck && (
																	<span className="text-danger">{formik.errors.food_truck}</span>
																)}
															</div>
														</div>

														<div className="form-group summary mb-4">
															<label className="form-label">Special notes</label><span Name="imp_ast"></span>
															<div className="input-right-icon">
																<textarea
																	rows="3"
																	type="text"
																	className="form-control"
																	name="match_instruction"
																	placeholder="Type Note"
																	onChange={formik.handleChange}
																	{...formik.getFieldProps('match_instruction')}
																	value={formik.values.match_instruction}

																/>
															</div>
															{formik.touched.match_instruction && formik.errors.match_instruction && (
																<span className="text-danger">{formik.errors.match_instruction}</span>
															)}
														</div>
													</div>
												</div>

												<div className="row">
													<div className='col-md-12'>
													<div className="schedule-1 w-100 create-match-btn d-flex justify-content-center align-item">
														<div className=" create-next submission create-match-btn w-100">
															<button className="btn btn-dark green add next-orange-btn create_buttn_teams" type='submit' disabled={loader}>
																{loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : (value.id >0 ? "Update Games" : "Create Games")}
															</button>
														</div>
													</div>
														
													</div>
													
												</div>

											</form></div>
									</div>
								</div>

							</div>
	</>)
}
	return (
		<>
		<CustomModal showModal={showModal} onClose={closeModal} componentToRender={contentToRender} />
			{createVisible? <button className="btn btn-primary py-2 me-3" onClick={() => handleShow("")}> Create Game</button>:<></>}
			<Offcanvas show={show} onHide={handleClose} {...props} className="sidenav_offcanvas craete-games-offcanvas">
				<Offcanvas.Body>
					<div className="create_game offcanvas_create_game modal show" style={{ display: 'block', position: 'initial' }}>
						<Modal.Dialog style={{ display: ( venueList?.length == 0) ? 'visible' : 'none' }}>
							<Modal.Body>
								<Modal.Title>
									Create Essential elements
									<span>Add the elements you need to make your game epic.</span>
								</Modal.Title>
								<div className="offcanvas_create_game_body">
									<div className={`item ${venueList.length > 0 ? 'active' : ''}`}>
										<svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="">
											<path d="M11.7332 18.9977C11.4552 19.0141 11.2316 18.9146 11.0447 18.7282C9.79412 17.4789 8.54172 16.2314 7.29665 14.9777C6.90382 14.5825 6.90504 14.0302 7.2771 13.6221C7.63632 13.2287 8.18799 13.1816 8.61625 13.5121C8.70606 13.5818 8.78792 13.663 8.86857 13.7437C9.75808 14.632 10.6506 15.5178 11.5334 16.4128C11.6776 16.5594 11.757 16.5674 11.9085 16.4153C14.2515 14.0614 16.6029 11.7148 18.9495 9.36392C19.2476 9.06518 19.583 8.91611 20.0009 9.04868C20.6931 9.26801 20.932 10.0714 20.4756 10.634C20.4133 10.711 20.3442 10.7819 20.274 10.8521C17.7099 13.4168 15.1459 15.9809 12.5812 18.5456C12.3417 18.785 12.1016 19.0227 11.7326 18.9983L11.7332 18.9977Z" fill="" />
											<path d="M0 13.9844C0 11.7751 0 9.55946 0 7.35008C0.0062236 3.84619 2.64503 0.728162 6.09913 0.118248C6.54101 0.0435652 6.98288 0 7.43721 0C11.2461 0.00622361 15.0549 0.0186708 18.87 0C19.9653 0 20.8802 0.360969 21.6706 1.09535C23.3883 2.69482 25.1122 4.29429 26.83 5.89998C27.639 6.65926 28.0622 7.5928 28.056 8.71927C28.0498 12.6775 28.0373 16.6357 28.056 20.5939C28.0685 24.0107 25.5354 27.3154 21.7204 27.9253C21.3968 27.9751 21.0669 28 20.737 28C16.2747 28 11.8124 28 7.35008 28C3.86486 28 0.728162 25.355 0.124472 21.9258C0.0373416 21.4714 0 21.0233 0 20.5628C0 18.3721 0 16.1751 0 13.9844ZM1.91065 14.4637C1.95421 16.337 1.82974 18.677 1.96666 21.0109C2.05379 22.4423 2.6886 23.631 3.72794 24.6019C4.84819 25.6475 6.21116 26.0831 7.72349 26.0831C11.6195 26.0831 15.5217 26.0831 19.4176 26.0831C19.9404 26.0831 20.4632 26.0769 20.9922 26.0458C23.2638 25.9026 25.3052 24.2409 25.9151 22.0378C26.0707 21.459 26.1391 20.874 26.1391 20.2827C26.1454 16.4988 26.1391 12.7148 26.1391 8.93087C26.1391 8.59065 25.9752 8.42054 25.6475 8.42054C24.521 8.42054 23.3945 8.42054 22.2618 8.42054C20.3761 8.41431 18.6833 6.72149 18.6708 4.83574C18.6708 3.98311 18.6584 3.13047 18.6708 2.27162C18.6708 1.98533 18.6024 1.89198 18.2974 1.89198C15.5964 1.90442 12.8891 1.89198 10.188 1.90442C9.13625 1.90442 8.09069 1.86086 7.0389 1.94799C5.35852 2.08491 4.02667 2.84419 3.01222 4.16982C2.22805 5.19671 1.90442 6.3792 1.8982 7.65503C1.89198 9.77106 1.8982 11.8871 1.8982 14.4637H1.91065ZM24.5832 6.4601C23.2638 5.23405 21.9444 4.00178 20.5877 2.73839C20.5877 3.42921 20.5566 4.04534 20.5939 4.6677C20.6624 5.73816 21.2412 6.36052 22.3054 6.49744C23.046 6.5908 23.7991 6.51611 24.5832 6.46633V6.4601Z" fill="" />
										</svg>
										<p>
											<NavLink  onClick={()=>{openModal('venue')}}>Create Venue</NavLink>
										</p>
									</div>
									<div className={`item ${timeSlots.length > 0 ? 'active' : ''}`}>
										<svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="">
											<path d="M11.7332 18.9977C11.4552 19.0141 11.2316 18.9146 11.0447 18.7282C9.79412 17.4789 8.54172 16.2314 7.29665 14.9777C6.90382 14.5825 6.90504 14.0302 7.2771 13.6221C7.63632 13.2287 8.18799 13.1816 8.61625 13.5121C8.70606 13.5818 8.78792 13.663 8.86857 13.7437C9.75808 14.632 10.6506 15.5178 11.5334 16.4128C11.6776 16.5594 11.757 16.5674 11.9085 16.4153C14.2515 14.0614 16.6029 11.7148 18.9495 9.36392C19.2476 9.06518 19.583 8.91611 20.0009 9.04868C20.6931 9.26801 20.932 10.0714 20.4756 10.634C20.4133 10.711 20.3442 10.7819 20.274 10.8521C17.7099 13.4168 15.1459 15.9809 12.5812 18.5456C12.3417 18.785 12.1016 19.0227 11.7326 18.9983L11.7332 18.9977Z" fill="" />
											<path d="M0 13.9844C0 11.7751 0 9.55946 0 7.35008C0.0062236 3.84619 2.64503 0.728162 6.09913 0.118248C6.54101 0.0435652 6.98288 0 7.43721 0C11.2461 0.00622361 15.0549 0.0186708 18.87 0C19.9653 0 20.8802 0.360969 21.6706 1.09535C23.3883 2.69482 25.1122 4.29429 26.83 5.89998C27.639 6.65926 28.0622 7.5928 28.056 8.71927C28.0498 12.6775 28.0373 16.6357 28.056 20.5939C28.0685 24.0107 25.5354 27.3154 21.7204 27.9253C21.3968 27.9751 21.0669 28 20.737 28C16.2747 28 11.8124 28 7.35008 28C3.86486 28 0.728162 25.355 0.124472 21.9258C0.0373416 21.4714 0 21.0233 0 20.5628C0 18.3721 0 16.1751 0 13.9844ZM1.91065 14.4637C1.95421 16.337 1.82974 18.677 1.96666 21.0109C2.05379 22.4423 2.6886 23.631 3.72794 24.6019C4.84819 25.6475 6.21116 26.0831 7.72349 26.0831C11.6195 26.0831 15.5217 26.0831 19.4176 26.0831C19.9404 26.0831 20.4632 26.0769 20.9922 26.0458C23.2638 25.9026 25.3052 24.2409 25.9151 22.0378C26.0707 21.459 26.1391 20.874 26.1391 20.2827C26.1454 16.4988 26.1391 12.7148 26.1391 8.93087C26.1391 8.59065 25.9752 8.42054 25.6475 8.42054C24.521 8.42054 23.3945 8.42054 22.2618 8.42054C20.3761 8.41431 18.6833 6.72149 18.6708 4.83574C18.6708 3.98311 18.6584 3.13047 18.6708 2.27162C18.6708 1.98533 18.6024 1.89198 18.2974 1.89198C15.5964 1.90442 12.8891 1.89198 10.188 1.90442C9.13625 1.90442 8.09069 1.86086 7.0389 1.94799C5.35852 2.08491 4.02667 2.84419 3.01222 4.16982C2.22805 5.19671 1.90442 6.3792 1.8982 7.65503C1.89198 9.77106 1.8982 11.8871 1.8982 14.4637H1.91065ZM24.5832 6.4601C23.2638 5.23405 21.9444 4.00178 20.5877 2.73839C20.5877 3.42921 20.5566 4.04534 20.5939 4.6677C20.6624 5.73816 21.2412 6.36052 22.3054 6.49744C23.046 6.5908 23.7991 6.51611 24.5832 6.46633V6.4601Z" fill="" />
										</svg>
										<p>
											<NavLink  onClick={()=>{openModal('TimeSlots')}}>Add time Slot</NavLink>
										</p>
									</div>

									{/* <div className={`item ${teams?.length > 1 ? 'active' : ''}`}>
										<svg xmlns="http://www.w3.org/2000/svg" width="29" height="28" viewBox="0 0 29 28" fill="">
											<path d="M11.7332 18.9977C11.4552 19.0141 11.2316 18.9146 11.0447 18.7282C9.79412 17.4789 8.54172 16.2314 7.29665 14.9777C6.90382 14.5825 6.90504 14.0302 7.2771 13.6221C7.63632 13.2287 8.18799 13.1816 8.61625 13.5121C8.70606 13.5818 8.78792 13.663 8.86857 13.7437C9.75808 14.632 10.6506 15.5178 11.5334 16.4128C11.6776 16.5594 11.757 16.5674 11.9085 16.4153C14.2515 14.0614 16.6029 11.7148 18.9495 9.36392C19.2476 9.06518 19.583 8.91611 20.0009 9.04868C20.6931 9.26801 20.932 10.0714 20.4756 10.634C20.4133 10.711 20.3442 10.7819 20.274 10.8521C17.7099 13.4168 15.1459 15.9809 12.5812 18.5456C12.3417 18.785 12.1016 19.0227 11.7326 18.9983L11.7332 18.9977Z" fill="" />
											<path d="M0 13.9844C0 11.7751 0 9.55946 0 7.35008C0.0062236 3.84619 2.64503 0.728162 6.09913 0.118248C6.54101 0.0435652 6.98288 0 7.43721 0C11.2461 0.00622361 15.0549 0.0186708 18.87 0C19.9653 0 20.8802 0.360969 21.6706 1.09535C23.3883 2.69482 25.1122 4.29429 26.83 5.89998C27.639 6.65926 28.0622 7.5928 28.056 8.71927C28.0498 12.6775 28.0373 16.6357 28.056 20.5939C28.0685 24.0107 25.5354 27.3154 21.7204 27.9253C21.3968 27.9751 21.0669 28 20.737 28C16.2747 28 11.8124 28 7.35008 28C3.86486 28 0.728162 25.355 0.124472 21.9258C0.0373416 21.4714 0 21.0233 0 20.5628C0 18.3721 0 16.1751 0 13.9844ZM1.91065 14.4637C1.95421 16.337 1.82974 18.677 1.96666 21.0109C2.05379 22.4423 2.6886 23.631 3.72794 24.6019C4.84819 25.6475 6.21116 26.0831 7.72349 26.0831C11.6195 26.0831 15.5217 26.0831 19.4176 26.0831C19.9404 26.0831 20.4632 26.0769 20.9922 26.0458C23.2638 25.9026 25.3052 24.2409 25.9151 22.0378C26.0707 21.459 26.1391 20.874 26.1391 20.2827C26.1454 16.4988 26.1391 12.7148 26.1391 8.93087C26.1391 8.59065 25.9752 8.42054 25.6475 8.42054C24.521 8.42054 23.3945 8.42054 22.2618 8.42054C20.3761 8.41431 18.6833 6.72149 18.6708 4.83574C18.6708 3.98311 18.6584 3.13047 18.6708 2.27162C18.6708 1.98533 18.6024 1.89198 18.2974 1.89198C15.5964 1.90442 12.8891 1.89198 10.188 1.90442C9.13625 1.90442 8.09069 1.86086 7.0389 1.94799C5.35852 2.08491 4.02667 2.84419 3.01222 4.16982C2.22805 5.19671 1.90442 6.3792 1.8982 7.65503C1.89198 9.77106 1.8982 11.8871 1.8982 14.4637H1.91065ZM24.5832 6.4601C23.2638 5.23405 21.9444 4.00178 20.5877 2.73839C20.5877 3.42921 20.5566 4.04534 20.5939 4.6677C20.6624 5.73816 21.2412 6.36052 22.3054 6.49744C23.046 6.5908 23.7991 6.51611 24.5832 6.46633V6.4601Z" fill="" />
										</svg>
										<p>
											<NavLink onClick={()=>{openModal('Teams')}}>Create Team</NavLink>
										</p>
									</div> */}
									{/* <div className="need_help">
										<h4>Need Help?</h4>
										<NavLink to="#">Watch Video Now</NavLink>
									</div> */}
								</div>
								<Button variant="primary" className="let_schedule_game" disabled={!!(teams?.length < 2 || venueList?.length == 0 || timeSlots?.length == 0 )} >Let's Schedule Games</Button>
							</Modal.Body>

						</Modal.Dialog>
						<div className="create-match-wrap season-popup">
							<Offcanvas.Header closeButton>
								<Offcanvas.Title>
									<h4 className='text-left'>{!!value ? "Update" : "Create"} Game</h4>
								</Offcanvas.Title>
							</Offcanvas.Header>
							<div className="custom-pop-box ">
								<div className="row">
									<div className="col-sm-12">
										<div className="create-form-inner create-match">
											<form onSubmit={formik.handleSubmit}>

												<div className="row">
													<div className='col-md-12'>
														<div className="form-group mb-4">
															<label className="form-label">Select Date<span className='text-danger'>*</span></label><span className="imp_ast"></span>
															<div className="input-right-icon">
																<input
																	type="date"
																	className="form-control"
																	name="matchDate"
																	placeholder="Baseball02"
																	min={moment(dates?.start_date).format('YYYY-MM-DD')}
																	max={moment(dates?.end_date).format('YYYY-MM-DD')}
																	value={formik.values.matchDate}
																	onChange={formik.handleChange}
																	{...formik.getFieldProps('matchDate')}
																/>
																{formik.touched.matchDate && formik.errors.matchDate && (
																	<span className="text-danger">{formik.errors.matchDate}</span>
																)}

															</div>
														</div>
													</div>

												</div>
												<div className="row">
													<div className="col-md-6">
															<div className="form-group mb-4">
															<label className="form-label">Away & Neutral<span className='text-danger'>*</span></label>
															<div className="input-right-icon">
																<select
																	className="form-select"
																	name="team_two_id"
																	value={formik.values.team_two_id}
																	onChange={formik.handleChange}
																	{...formik.getFieldProps('team_two_id')}
																>
																	<option value=''>Select Visitor Team</option>
																	<option value={0} >BYE</option>
																	{teams?.length ? (
																		teams?.filter((item) => item.id != formik.values.team_one_id).map((grade, i) => (
																			<option value={grade.id}>{grade.team_name}</option>
																		))
																	) : (
																		<></>
																	)}
																</select>
																{formik.touched.team_two_id && formik.errors.team_two_id && (
																	<span className="text-danger">{formik.errors.team_two_id}</span>
																)}

															</div>
														</div>
													</div>
													<div className="col-md-6">
													<div className="form-group mb-4">
															<label className="form-label">Home<span className='text-danger'>*</span></label><span className="imp_ast"></span>
															<NavLink to="#" className="float-right" onClick={()=>{openModal('Teams')}}>Create Team</NavLink>
															
															<div className="input-right-icon">
																<select
																	className="form-select"
																	aria-label="Default select example"
																	name="team_one_id"
																	value={formik.values.team_one_id}
																	onChange={formik.handleChange}
																	{...formik.getFieldProps('team_one_id')}
																>
																	<option value=''>Select Home Team</option>
																	<option  value={0}>BYE</option>
																	{teams?.length ? (
																		teams?.filter(item => item.id != formik.values.team_two_id)?.map((grade, i) => (
																			<option value={grade.id}>{grade.team_name}</option>
																		))
																	) : (
																		<></>
																	)}
																</select>
																{formik.touched.team_one_id && formik.errors.team_one_id && (
																	<span className="text-danger">{formik.errors.team_one_id}</span>
																)}
															</div>
														</div>
													</div>
												</div>

												<div className='row'>
													<div className=" col-md-6">
													<div className="form-group">
														<label className="form-label">Venue <span className='text-danger'>*</span></label>
														<NavLink onClick={()=>{openModal('venue')}} className="float-right">Add Venue</NavLink>
														<div className="input-right-icon">
															<select
																className="form-select"
																name="venue_id"
																value={formik.values.venue_id}
																onChange={formik.handleChange}
																{...formik.getFieldProps('venue_id')}
															>
																<option value="">Select Venue</option>
																{venueList?.length ? (
																	venueList.map((venue, i) => (
																		<option value={venue.id}>{venue.name}</option>
																	))
																) : (
																	<></>
																)}
															</select>
															{formik.touched.venue_id && formik.errors.venue_id && (
																<span className="text-danger">{formik.errors.venue_id}</span>
															)}
														</div>
													</div>
													</div>
													<div className=" col-md-6">
													<div className="form-group ">

														<label className="form-label">Field<span className='text-danger'>*</span></label><span className="imp_ast"></span>
														{/* <NavLink to={`/organizer/${sportId}/venues`} className="float-right">Add Field</NavLink> */}
														<div className="input-right-icon">
															<select
																className="form-select"
																name="venue_field_id"
																value={formik.values.venue_field_id}
																onChange={formik.handleChange}
																{...formik.getFieldProps('venue_field_id')}
															>
																<option value="">Select Venue Field</option>
																{venueList?.length ? (
																	(venueList?.filter((e) => e.id == formik?.values?.venue_id)[0]?.fields)?.map((grade, i) => (
																		<option value={grade.id}>{grade.fieldname}</option>
																	))
																) : (
																	<></>
																)}
															</select>
															{formik.touched.venue_field_id && formik.errors.venue_field_id && (
																<span className="text-danger">{formik.errors.venue_field_id}</span>
															)}
														</div>
														</div>
													</div>
												</div>

												<div className="row">
													<div className=" col-md-6">
													<div className="form-group">
														<label className="form-label">Time<span className="imp_ast">*</span></label>
														<NavLink onClick={()=>{openModal('TimeSlots')}} className="float-right">Add Time Slot</NavLink>
														<div className="input-right-icon">
															<select name="venue_field_timeslot_id" className="form-select" value={formik.values.venue_field_timeslot_id} onChange={formik.handleChange}
																{...formik.getFieldProps('venue_field_timeslot_id')}>
																<option value=''>Select TimeSlot</option>
															{timeSlots.filter(item=>(item.venue_id ==formik.values.venue_id && item.venue_field_id == formik.values.venue_field_id && (new Date(formik.values.matchDate).getUTCDay() == item.Day) && (item.status_id != 5 || item.id ==formik.values.venue_field_timeslot_id )))
                                                                    ?.map(item => <option value={item.id}>{convertTo12HourFormat(item.Time)}</option>)
															}
														</select>
														{formik.touched.venue_field_timeslot_id && formik.errors.venue_field_timeslot_id&& (
                                                <span className="text-danger">{formik.errors.venue_field_timeslot_id}</span>
                                            )}
														</div>
													</div>
													</div>
													<div className=" col-md-6">
													<div className="form-group">
														<label className="form-label">Add Refree
														{/* <span className='text-danger'>*</span> */}
														</label>
														<div className="input-right-icon">
															<Multiselect
																id="refree_id"
																options={RefreeList}
																displayValue="label"
																onSelect={handleRefree}
																onRemove={(selectedList, removedItem) => {
																	formik.setFieldValue("refree_id", selectedList)
																}}
																{...formik.getFieldProps('refree_id')}
																selectedValues={formik.values.refree_id} />

															{formik.touched.refree_id && formik.errors.refree_id && (
																<span className="text-danger">{formik.errors.refree_id}</span>
															)}
														</div>
													</div>
													</div>

												</div>
												<div className="row">
													{/* <div className="col-md-12">
														<div className="additional_button">
															<NavLink to="#">
																+ Additional Settings
															</NavLink>
														</div>
													</div> */}
													<div className="additional_setting_box" style={{ display: "block" }}>
														<div className="row">
															<div className="check-box-modal col-md-6">
																{/* <span className="custom-checkbox parking-check"> */}
																<span className="custom-checkbox parking-check">

																	<input className="form-check-input" name='parking' type="checkbox" checked={!!formik.values?.parking} id="selectAll"
																		onChange={formik.handleChange}
																		{...formik.getFieldProps('parking')}

																	/>
																	<label for="selectAll"> Parking Availability</label>
																</span>
																{formik.touched.parking && formik.errors.parking && (
																	<span className="text-danger">{formik.errors.parking}</span>
																)}
															</div>
															<div className="check-box-modal col-md-6">
																<span className="custom-checkbox parking-check foodcheck">

																	<input className="form-check-input" type="checkbox" id="selecttoo"
																		name='food_truck'
																		checked={formik.values?.food_truck == 1 ? true : false}
																		onChange={formik.handleChange}
																		{...formik.getFieldProps('food_truck')}

																	/>
																	<label for="selecttoo"> Food </label>
																</span>
																{formik.touched.food_truck && formik.errors.food_truck && (
																	<span className="text-danger">{formik.errors.food_truck}</span>
																)}
															</div>
														</div>

														<div className="form-group summary mb-4">
															<label className="form-label">Special notes</label><span Name="imp_ast"></span>
															<div className="input-right-icon">
																<textarea
																	rows="3"
																	type="text"
																	className="form-control"
																	name="match_instruction"
																	placeholder="Type Note"
																	onChange={formik.handleChange}
																	{...formik.getFieldProps('match_instruction')}
																	value={formik.values.match_instruction}

																/>
															</div>
															{formik.touched.match_instruction && formik.errors.match_instruction && (
																<span className="text-danger">{formik.errors.match_instruction}</span>
															)}
														</div>
													</div>
												</div>

												<div className="row">
													<div className='col-md-12'>
													<div className="schedule-1 w-100 create-match-btn d-flex justify-content-center align-item">
														<div className=" create-next submission create-match-btn w-100">
															<button className="btn btn-dark green add next-orange-btn create_buttn_teams" type='submit' disabled={loader}>
																{loader ? <FontAwesomeIcon icon={faCircleNotch} spin /> : (!!value ? "Update Games" : "Create Games")}
															</button>
														</div>
													</div>
														
													</div>
													
												</div>

											</form></div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</Offcanvas.Body>
			</Offcanvas>
		</>
	)
}

export default TabSlideNav;
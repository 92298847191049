import { useEffect, useState } from "react";
import { ApiGetAuth, Apipost } from "../../../helpers/apiCall";
import { errorMessageNodify, successMessageNodify } from "../../../helpers/notify";
import Cardloader from "../../Mainloader.js/Cardloader";
import { NavLink } from 'react-bootstrap';
import moment from "moment";
import DeletePopup from "../../../components/DeletePopup";
import NoData from '../../../components/NoData';
import { convertTo12HourFormat, generateImage } from '../../../helpers/helperfunctions'
function DraftMatches({ sportsid, dates, setMatchRefresh,endPoint, generated,updateinState=false, SetDraftMatches ,matchrefresh=false}) {
  const [venueList, setVenueList] = useState([]);
  const [timeSlots, setTimeSlots] = useState([])
  const [refresh, setRefresh] = useState(false)
  const [dragSelected, setDragSelected] = useState({ startIndex: 0, endIndex: 0, key: '' })
  const [data, setData] = useState(null);
  const [time_id, setTimeid] = useState('')
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [checked, setChecked] = useState(false)
  useEffect(() => {
    const { startIndex, startKey, endIndex, endKey } = dragSelected;
    if (startIndex === endIndex && startKey === endKey) return
    if (startKey && startKey.length > 0 && endKey && endKey.length > 0) {
      const startRow = data[startIndex];
      const endRow = data[endIndex];
      let swapData = [startRow, endRow];
      if (swapData.length) Apipost("/games/swap-teams", { swapData }, "application/x-www-form-urlencoded", true).then(
        (success) => {
          successMessageNodify(success.data.msg);
        },
        (err) => {
          errorMessageNodify(err.response?.data.msg);
          setRefresh(prev=>!prev)
          return;
        }
      );
    }
  }, [dragSelected])
  const getDraft = ()=>{
    
    ApiGetAuth(`${endPoint}`).then(
      (data) => {
        console.log('triggered! ' ,data?.data )
        setData(data?.data)
       if(updateinState){
        SetDraftMatches(data?.data)
       }
      },
      (err) => {
        console.log(err);
      }
    );
  }
  useEffect(() => {
    ApiGetAuth(`venue/venue-field-list`).then(
      (data) => {
        let temp = [];
        data?.data?.filter((fil) => fil.sport_id == sportsid).map(item => {
          temp.push(item)
        })
        setVenueList(temp);
      },
      (err) => {
        console.log(err);
      }
    );
    ApiGetAuth(`venue/time-slots/${sportsid}`).then((data) => {
      if (data?.data) {
        setTimeSlots(data.data)
      }
    }, (err) => {
      console.log(err);
    })
  setTimeout(() => {
    getDraft()
  }, 1000);
    setChecked(false)
  }, [refresh, generated,matchrefresh])
 
  console.log(refresh , data , "prev !")

  let draggedIndex = null;
  let draggedKey = null
  const handleDragStart = (event, index, key) => {
    draggedIndex = index;
    draggedKey = key
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDragOver = (event, index) => {
    event.preventDefault();
    // Check if the dragged item is being dragged over a valid drop target
    if (index !== draggedIndex) {
      event.dataTransfer.dropEffect = "move"; // Change to "move" to indicate valid drop target
    }
  };

  const handleDrop = (event, index, key) => {
    event.preventDefault();

    const {score_one , score_two} = data[draggedIndex];
    const {score_one:droppedScoreone , score_two:droppedScoretwo} = data[index];
    if( score_one || score_two || droppedScoreone || droppedScoretwo){
      errorMessageNodify("Cannot Swap the Teams for the Game which has been Completed!");
      return 
    }else{
      if ((draggedKey == "Team1" || draggedKey == "Team2") && (key == "Team1" || key == "Team2")) {
        const newData = [...data];
        const draggedKey1 = newData[draggedIndex][draggedKey];
        newData[draggedIndex][draggedKey] = newData[index][key];
        newData[index][key] = draggedKey1;
        setData(newData);
        if(updateinState){
          SetDraftMatches(newData)
         }
        setDragSelected({ startIndex: draggedIndex, startKey: draggedKey, endIndex: index, endKey: key })
      }
    }
   
  };

  const updateState = (data, id, value, key, isGameCompleted = false) => {
    // Find the index of the item to be updated
    const index = data.findIndex((obj) => obj.id === id);
    if (index === -1 || isGameCompleted) return;
  
    // Only modify the specific item that needs to be updated
    const updatedItem = { ...data[index] };
  
    // Update the correct field based on the key
    if (key === 'matchDate') {
      updatedItem[key] = value;
      updatedItem.venue_id = '';
      updatedItem.venue_field_id = '';
      updatedItem.venue_field_timeslot_id = '';
    } else if (key === 'venue_id') {
      updatedItem[key] = value;
      updatedItem.venue_field_id = '';
      updatedItem.venue_field_timeslot_id = '';
    } else if (key === 'venue_field_id') {
      updatedItem[key] = value;
      updatedItem.venue_field_timeslot_id = '';
    } else if (key === 'isSelected') {
      updatedItem[key] = !updatedItem.isSelected;
    } else {
      updatedItem[key] = value;
    }
  
    // Update the data array by only changing the specific item
    const updatedData = [
      ...data.slice(0, index),
      updatedItem,
      ...data.slice(index + 1)
    ];
  
    // Set the new data state only if the game is not completed
    setData(updatedData);
  };
  



  const handleUpdate = (values) => {
    let baseUrl = '/games/update-Match'
    const { venue_field_id, matchDate, venue_id, venue_field_timeslot_id } = values
    if (venue_field_id && matchDate && venue_id  && venue_field_timeslot_id) {
      Apipost(baseUrl, values, "application/x-www-form-urlencoded", true).then(
        (response) => {
          setRefresh(!refresh)
          setMatchRefresh(prev => !prev)
          successMessageNodify(response?.data.message)
        },
        (err) => {
          errorMessageNodify(err.response?.data.message);
          return;
        })
    } else {
      errorMessageNodify("Please Update All The Details To Add!");
      return
    }

  }
  const closeDeleteModal = () => {
    setTimeid(0)
    setShowDeleteModal(false);
    setMatchRefresh(prev => !prev)
  };
  const openDeleteModal = (id) => {
    setTimeid([id])
    setShowDeleteModal(true);
  };

  const handleClick = () => {
    const temp = data.map(item => ({
      ...item,
      isSelected: !checked
    }));
    setData(temp);
    setChecked(!checked)
  }
  const handleDelete = () => {
    setShowDeleteModal(true);
    const deleteId = data.filter(item => item.isSelected).map(item => item.id)
    setTimeid(deleteId)
  }

  return (<>
    <div className=" match-outer">

      {(data?.filter(item => item.isSelected))?.length > 1 ? <button type="button" className="btn btn-danger" onClick={handleDelete}>Delete Selected</button> : <></>}
      <DeletePopup isOpen={showDeleteModal} onClose={closeDeleteModal} values={data} setValues={setData} endpoint={`/games/delete-matches?matchId=${time_id}`} id={time_id} title={time_id.length > 1 ? `these ${time_id.length} Selected Games` : "this Game"} />
      {data == null ? <Cardloader /> :
        <> {data.length > 0 ? <> <div class="table-responsive"><table className="table-match table-striped">
          <thead style={{ position: "sticky", top: "0", zIndex: '9' }}>
            <tr className="team-head">
              <th><input type='checkbox' onClick={handleClick} checked={checked} /></th>
              <th>AWAY</th>
              <th className="text-center"> GAMES</th>
              <th>HOME</th>
              <th>DATE</th>
              <th> VENUE</th>
              <th> FIELD </th>
              <th> TIME</th>
              <th> ACTIONS</th>
            </tr>
          </thead>
          <tbody>
            {data?.map((item, i) => {
              return <tr key={i}  className={item.isSelected == true ? "table-primary" : ""}>
               
                <td onClick={() => { updateState(data, item.id, true, 'isSelected', item.score_one||item.score_two) }} >
                  {item.isSelected == true ? <input type='checkbox' checked /> : <input type='checkbox' disabled={(item.score_one||item.score_two) ? true : false} />}
                </td>

                <td
                  draggable={(item.score_one||item.score_two)? false: (item.team_one_id == null ?false:true)}
                  onDragStart={(event) => handleDragStart(event, i, "Team1")}
                  onDragOver={(event) => handleDragOver(event, i)}
                  onDrop={(event) => handleDrop(event, i, 'Team1')}
                ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                     <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                   </svg>
                  <img className="img-game-logo" src={item.Team1?.team_logo || generateImage(item?.Team1?.team_name)} alt='' width={"25px"} />
                 <span className="text-with"> {item?.Team1?.team_name}</span>
                </td>
                <td className="text-center vicsion"><strong>Vs</strong></td>
               
                <td
                  draggable={(item.score_one||item.score_two)? false:  (item.team_two_id == null ?false:true)}
                  onDragStart={(event) => handleDragStart(event, i, "Team2")}
                  onDragOver={(event) => handleDragOver(event, i)}
                  onDrop={(event) => handleDrop(event, i, 'Team2')}
                ><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-grip-vertical" viewBox="0 0 16 16">
                    <path d="M7 2a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 5a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0M7 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m-3 3a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0"/>
                  </svg>
                  <img src={item.Team2?.team_logo || generateImage(item?.Team2?.team_name)} alt='' width={"25px"} />
                 <span className="text-with"> {item?.Team2?.team_name}</span> 
                </td>
                
                {/* <td
                  draggable="false"
                  onDragStart={(event) => handleDragStart(event, i)}
                  onDragOver={(event) => handleDragOver(event, i)}
                  onDrop={(event) => handleDrop(event, i, 'Day')}

                >
                  <select name="Day" className="form-select" disabled={(item.score_one||item.score_two)? true:false} value={item.Day} onChange={(e) => { updateState(data, item.id, e.target.value, 'Day') }}>
                    <option value=''>Select Day</option>
                    {daysOptions?.map((item, ind) =>
                      <option className={availableDays.includes(item) ? 'text-success' : 'text-danger'} value={ind}>{item}</option>
                    )
                    }
                  </select>
                </td> */}
                
                <td
                  draggable="false"
                  onDragStart={(event) => handleDragStart(event, i)}
                  onDragOver={(event) => handleDragOver(event, i)}
                  onDrop={(event) => handleDrop(event, i, 'Day')}
                  width={"13%"}
                >
                  <input
                    type="date"
                    className="form-control"
                    name="matchDate"
                    disabled={(item.score_one||item.score_two)? true:false}
                    min={moment(dates?.start_date).format('YYYY-MM-DD')}
                    max={moment(dates?.end_date).format('YYYY-MM-DD')}
                    placeholder="Baseball02"
                    value={item.matchDate == null? '': item.matchDate}
                    onChange={(e) => { updateState(data, item.id, e.target.value, 'matchDate') }}
                  />
                </td>

                <td
                  draggable="false"
                  onDragStart={(event) => handleDragStart(event, i)}
                  onDragOver={(event) => handleDragOver(event, i)}
                  onDrop={(event) => handleDrop(event, i, 'Day')}
                >
                  <select
                    className="form-select"
                    disabled={(item.score_one||item.score_two)? true:false}
                    name="venue_id"
                    value={item.venue_id == null? '': item.venue_id}
                    onChange={(e) => { updateState(data, item.id, e.target.value, 'venue_id') }}
                  >
                    <option value="0">Select Venue</option>
                    {venueList?.length ? (
                      venueList?.map((venue, i) => (
                        <option value={venue.id}>{venue.name}</option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </td>
               
                <td
                  draggable="false"
                  onDragStart={(event) => handleDragStart(event, i)}
                  onDragOver={(event) => handleDragOver(event, i)}
                  onDrop={(event) => handleDrop(event, i, 'Day')}
                >
                  <select
                   disabled={(item.score_one||item.score_two)? true:false}
                    className="form-select"
                    name="venue_field_id"
                    value={item.venue_field_id}
                    onChange={(e) => { updateState(data, item.id, e.target.value, 'venue_field_id') }}
                  >
                    <option value="0">Select Venue Field</option>
                    {venueList?.length ? (
                      (venueList?.filter((e) => e.id == item?.venue_id)[0]?.fields)?.map((grade, i) => (
                        <option value={grade.id}>{grade.fieldname}</option>
                      ))
                    ) : (
                      <></>
                    )}
                  </select>
                </td>
                <td
                  draggable="false"
                  onDragStart={(event) => handleDragStart(event, i)}
                  onDragOver={(event) => handleDragOver(event, i)}
                  onDrop={(event) => handleDrop(event, i, 'venue_field_timeslot')}
                >
                  <select name="venue_field_timeslot_id" className="form-select" value={item.venue_field_timeslot_id} onChange={(e) => { updateState(data, item.id, e.target.value, 'venue_field_timeslot_id') }}    disabled={(item.score_one||item.score_two)? true:false}>
                    <option value=''>Select TimeSlot</option>
                    {timeSlots.filter(items => (items.venue_id == item.venue_id && items.venue_field_id == item.venue_field_id && ((item.matchDate) == items.scheduled_for) && (items.status_id != 5 || item.venue_field_timeslot_id == items.id)))?.map(item => <option value={item.id}>{convertTo12HourFormat(item.Time)}</option>)
                    }
                  </select>
                </td>
                <td>
                  <>
                    {/* <button className="btn btn-primary update-matches my-2" > Save Changes </button> */}
                    <div className="edt_delete" >
                      <NavLink  disabled={(item.score_one||item.score_two)?true:false }  onClick={() => handleUpdate(item)} >
                        <div className="refers-box">
                          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M16.6198 14.9444C16.2477 15.032 15.8902 15.1159 15.5333 15.2004C15.2829 15.26 15.0336 15.3262 14.7819 15.3803C14.2408 15.4971 13.7605 15.1925 13.6231 14.6526C13.4821 14.0957 13.7526 13.5808 14.2992 13.4458C15.6817 13.1041 17.0672 12.7765 18.4546 12.4548C18.9318 12.3442 19.4614 12.6695 19.5823 13.1552C19.9283 14.5444 20.2651 15.936 20.5867 17.3307C20.715 17.8869 20.3733 18.3405 19.7787 18.4718C19.2522 18.5879 18.7738 18.2906 18.6376 17.7538C18.5221 17.2984 18.4163 16.8413 18.3056 16.3847C18.2922 16.3287 18.3074 16.2479 18.2339 16.2333C18.1761 16.2217 18.1536 16.2929 18.1202 16.3318C16.6258 18.087 14.7649 19.2609 12.51 19.743C9.18869 20.4525 6.20422 19.6877 3.61007 17.5082C1.71567 15.9166 0.588515 13.8598 0.163553 11.4237C0.111876 11.1289 0.0298018 10.8316 0.0912056 10.5246C0.187263 10.0437 0.591555 9.69714 1.06455 9.69532C1.52599 9.6935 1.95216 10.0273 2.03728 10.496C2.13394 11.0243 2.19048 11.5611 2.35646 12.0749C3.33223 15.0891 5.34335 17.0626 8.42752 17.7641C11.5713 18.4791 14.2809 17.5574 16.4781 15.1809C16.5328 15.122 16.5991 15.066 16.6185 14.945L16.6198 14.9444Z" fill="#27AE60" />
                            <path d="M2.34344 3.73617C2.85535 3.1197 3.38913 2.59564 3.98493 2.1348C5.40816 1.03562 7.01621 0.329777 8.78841 0.101185C12.5128 -0.379102 15.6352 0.859309 18.1406 3.62673C19.5134 5.14298 20.2466 6.97172 20.5056 8.99378C20.5943 9.68503 20.2922 10.1276 19.6739 10.2255C19.0763 10.3203 18.596 9.94828 18.5291 9.34215C18.4124 8.2843 18.1321 7.27205 17.6178 6.33883C16.2857 3.91916 14.2631 2.42784 11.5236 2.03206C8.55617 1.60345 6.06476 2.57983 4.05789 4.80192C4.01351 4.85116 3.95757 4.89554 3.94845 4.9916C4.47738 4.86332 4.99779 4.73747 5.5182 4.61162C5.70241 4.56724 5.88662 4.51678 6.07813 4.53259C6.56875 4.57332 6.90495 4.9308 6.96514 5.46885C7.01743 5.93576 6.75296 6.34856 6.27207 6.4817C5.69572 6.6416 5.11087 6.76927 4.52905 6.9091C3.76303 7.0927 2.99822 7.27874 2.23036 7.45444C1.63578 7.59062 1.1482 7.30427 0.998642 6.70969C0.663657 5.3734 0.339616 4.03346 0.0259093 2.69169C-0.099938 2.15304 0.246598 1.62169 0.763362 1.50861C1.33241 1.38398 1.84188 1.7068 1.98353 2.2874C2.08202 2.69169 2.17382 3.09842 2.27049 3.50332C2.28569 3.56715 2.31001 3.62916 2.34344 3.73617Z" fill="#27AE60" />
                          </svg>
                        </div>
                      </NavLink>
                      <NavLink disabled={(item.score_one||item.score_two)?true:false } onClick={() => openDeleteModal(item?.id)}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
                          <path d="M11.002 0C11.2889 0.140808 11.388 0.385919 11.388 0.688396C11.388 1.03781 11.3828 1.39244 11.388 1.74185C11.3932 1.90352 11.3202 1.95567 11.1637 1.95567C9.07767 1.95046 6.9864 1.95046 4.90035 1.95567C4.72825 1.95567 4.67089 1.88266 4.6761 1.72099C4.68132 1.38722 4.68132 1.05867 4.6761 0.724902C4.6761 0.411995 4.76998 0.151239 5.07245 0C7.04899 0 9.02552 0 11.002 0Z" fill="#F1372B" />
                          <path d="M8.03508 6.74313C10.1733 6.74313 12.3167 6.74313 14.4549 6.74313C14.7469 6.74313 14.7469 6.74313 14.7261 7.02475C14.6426 8.19293 14.5644 9.35591 14.481 10.5241C14.3819 11.854 14.2776 13.1786 14.1785 14.5085C14.1055 15.4889 14.0429 16.4641 13.9751 17.4446C13.9334 18.0078 13.9021 18.571 13.8499 19.1343C13.803 19.6923 13.4588 20 12.8956 20C9.63613 20 6.37667 20 3.11722 20C2.53834 20 2.20979 19.7027 2.16285 19.1186C2.08984 18.1903 2.03247 17.2672 1.96989 16.339C1.88124 15.1134 1.79258 13.8878 1.70914 12.6623C1.65177 11.8018 1.59962 10.9361 1.54225 10.0756C1.46924 9.043 1.39623 8.01562 1.32322 6.98303C1.30757 6.76399 1.32322 6.73792 1.55268 6.73792C3.71696 6.74313 5.87602 6.74313 8.03508 6.74313ZM5.47446 13.0691C5.47446 14.3155 5.47446 15.5671 5.47446 16.8135C5.47446 16.9283 5.47446 17.0482 5.51096 17.1629C5.58397 17.4185 5.78215 17.5645 6.0429 17.5593C6.30366 17.5541 6.49662 17.3924 6.54877 17.1317C6.56963 17.0169 6.58006 16.897 6.58006 16.7822C6.58006 14.3259 6.58006 11.8696 6.58006 9.41327C6.58006 9.2829 6.56963 9.15252 6.54877 9.02735C6.50705 8.77703 6.31409 8.61536 6.06377 8.59971C5.80822 8.58407 5.59962 8.72488 5.52139 8.95956C5.48489 9.07951 5.47446 9.19945 5.47446 9.32462C5.47446 10.571 5.47446 11.8227 5.47446 13.0691ZM10.5748 13.0952C10.5748 12.8292 10.5748 12.5632 10.5748 12.2972C10.5748 11.249 10.5748 10.206 10.5748 9.15773C10.5748 8.81875 10.3715 8.60493 10.0585 8.5945C9.75607 8.58407 9.53182 8.79789 9.50053 9.12644C9.49532 9.18902 9.49532 9.25682 9.49532 9.3194C9.49532 11.8227 9.49532 14.3259 9.49532 16.8292C9.49532 16.9387 9.49532 17.0482 9.52139 17.1577C9.61005 17.4602 9.90731 17.6271 10.2098 17.5332C10.4549 17.455 10.5748 17.2777 10.5748 16.9596C10.5748 15.6714 10.5748 14.3833 10.5748 13.0952Z" fill="#F1372B" />
                          <path d="M8.035 5.65845C5.47437 5.65845 2.90853 5.65845 0.347908 5.65845C0.0297852 5.65845 0.0297852 5.65845 0.0297852 5.33511C0.0297852 5.02221 0.0297852 4.7093 0.0297852 4.39639C0.0350003 3.58805 0.587803 3.03003 1.39093 3.03003C4.25403 3.03003 7.11714 3.03003 9.97503 3.03003C11.5343 3.03003 13.0989 3.03003 14.6582 3.03003C15.5031 3.03003 16.0506 3.57762 16.0506 4.42247C16.0506 4.76667 16.0454 5.11086 16.0506 5.45506C16.0559 5.59587 15.9985 5.65845 15.8577 5.65324C15.6752 5.64802 15.4926 5.65324 15.3101 5.65324C12.8851 5.65845 10.46 5.65845 8.035 5.65845Z" fill="#F1372B" />
                        </svg>
                      </NavLink>
                    </div>
                  </>
                </td>
              </tr>
            }
            )}
          </tbody>
        </table> </div></> : <>
          <div className="No-game"> <NoData message='No Draft Games For Today ! ' helpText='As soon as you create new games it would populate on the current Day.' /></div>
        </>}</>
      }

    </div>
  </>);
}

export default DraftMatches;